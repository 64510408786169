export const isAuthorized = (user, authRoles) => {
  const hqId = user && user.lastLoggedInHq
  const hqRoles =
    (user &&
      user.roles.filter((r) => r.headquarterId == hqId).map((r) => r.name)) ||
    []

  return hqRoles.some((userRole) =>
    authRoles.map((r) => r).some((roleName) => roleName === userRole),
  )
}

export const isAuthorizedWtihAll = (user, authRoles) => {
  const hqId = user && user.lastLoggedInHq
  const hqRoles =
    (user &&
      user.roles.filter((r) => r.headquarterId == hqId).map((r) => r.name)) ||
    []

  return authRoles.every((authRole) => hqRoles.includes(authRole))
}
