import { connect } from 'react-redux'

import { EditMenuConceptModal } from '@components/chef/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { chef, editMenuConcept, editMenuConceptModal, user } = state
  const { show } = editMenuConceptModal
  const errors = state.errors.menuConceptModal || {}
  const { mainMenuItems, sideMenuItems, otherMenuItems } = chef || {}

  return {
    locale: user.locale,
    chefId: editMenuConcept.chefId,
    errors,
    mainMenuItems,
    sideMenuItems,
    otherMenuItems,
    menuConcept: editMenuConcept,
    show,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, SettingsService, UIService } = services
  const { filterRemainingItems, pStateToReduxConcept } = presenters
  const {
    pCalculateConceptMargin,
    pGetMainMenuItems,
    pGetSideMenuItems,
    pGetOtherMenuItems,
    pMenuItemMargin,
    pRequestUpdateMenuConcept,
    pRequestSettings,
    pResponseSettings,
    pResponseConcepts,
    pResponseMenuItems,
    pSerializeLoadMenuItemsParams,
    pRequestUpdateConceptImages,
  } = presenters.Api
  const {
    DelayedUpdateConcept,
    DeleteMenuConcept,
    LoadChefMenuItems,
    LoadSettings,
    RetryOnTimeout,
    SaveMenuConcept,
    GetConceptAvgPricePerPerson,
    GetConceptEstPricePerPerson,
  } = coordinators

  const { close } = UIService.EditMenuConcept
  const clearErrors = UIService.Errors.clear
  const { displayWarningMessage, displayFailureMessage } =
    UIService.FlashMessage
  const delayedUpdateConcept = DelayedUpdateConcept({
    ChefService,
    UIService,
    pStateToReduxConcept,
  })
  const deleteMenuConcept = DeleteMenuConcept({
    ChefService,
    RestService,
    UIService,
    pResponseConcepts,
    HandleError,
  })
  const loadChefMenuItems = LoadChefMenuItems({
    ChefService,
    RestService,
    pResponseMenuItems,
    pSerializeLoadMenuItemsParams,
  })
  const loadConceptsSettings = LoadSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    RetryOnTimeout,
    HandleError,
  })('concepts')
  const saveMenuConcept = SaveMenuConcept({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateMenuConcept,
    pResponseConcepts,
    pRequestUpdateConceptImages,
    HandleError,
  })
  const getAvgPricePerPerson = GetConceptAvgPricePerPerson({
    RestService,
    HandleError,
  })
  const getConceptEstPricePerPerson = GetConceptEstPricePerPerson({
    RestService,
    HandleError,
  })

  return {
    clearErrors,
    close,
    delayedUpdateConcept,
    deleteMenuConcept,
    filterRemainingItems,
    loadChefMenuItems,
    loadConceptsSettings,
    pCalculateConceptMargin,
    pGetMainMenuItems,
    pGetSideMenuItems,
    pGetOtherMenuItems,
    pMenuItemMargin,
    saveMenuConcept,
    displayWarningMessage,
    displayFailureMessage,
    getAvgPricePerPerson,
    getConceptEstPricePerPerson,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditMenuConceptModal)
