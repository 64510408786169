import { camelCaseify } from '~/utils'
import axios from 'axios'

export const LoadCateringMenuItems =
  ({ RestService, pResponseOrderSearchMenuItems }) =>
  async (chefId) => {
    const params = {
      is_approved: true,
      parent_items: true,
      include_all: true,
      serializer: 'order_search',
      chef_ids: [chefId],
      market_types: ['Office', 'Home'],
    }

    const menuItems = await RestService.get('/api/admin/menu_items', params, {
      timeout: 30000,
    })

    return pResponseOrderSearchMenuItems(menuItems)
  }

export const LoadCateringMenuItem =
  ({ RestService, pResponseChefMenuItem }) =>
  async (menuItemId) => {
    const params = {
      serializer: 'order_form',
    }

    const menuItem = await RestService.get(
      `/api/admin/menu_items/${menuItemId}`,
      params,
      {
        timeout: 30000,
      },
    )

    return pResponseChefMenuItem(camelCaseify(menuItem))
  }

export const AsyncLoadServiceItems =
  ({ RestService, pResponseServiceItems }) =>
  async (chefId) => {
    const params = {
      is_approved: true,
      parent_items: true,
      include_all: true,
      chef_ids: [chefId],
    }
    const serviceItems = await RestService.get(
      '/api/admin/service_items',
      params,
      { timeout: 20000 },
    )

    return pResponseServiceItems(serviceItems)
  }

export const AsyncLoadVirtualItems =
  ({ RestService, pResponseVirtualItems }) =>
  async (chefId) => {
    const params = {
      is_approved: true,
      parent_items: true,
      include_all: true,
      chef_ids: [chefId],
    }
    const serviceItems = await RestService.get(
      '/api/admin/virtual_items',
      params,
      { timeout: 20000 },
    )

    return pResponseVirtualItems(serviceItems)
  }

export const AsyncLoadVirtualKits =
  ({ RestService, pResponseVirtualKits }) =>
  async (chefId) => {
    const params = {
      is_approved: true,
      include_all: true,
      chef_ids: [chefId],
    }
    const virtualKits = await RestService.get(
      '/api/admin/virtual_kits',
      params,
      { timeout: 20000 },
    )

    return pResponseVirtualKits(virtualKits)
  }

export const AsyncLoadSnackPacks =
  ({ RestService, pResponseSnackPacks }) =>
  async (chefId) => {
    const params = {
      is_approved: true,
      include_all: true,
      chef_ids: [chefId],
    }
    const snackPacks = await RestService.get('/api/admin/snack_packs', params, {
      timeout: 20000,
    })

    return pResponseSnackPacks(snackPacks)
  }

/* Will load all chefs without filtering on local dev if python cache server not running */
export const AsyncLoadChefs =
  ({ OrderService, RestService, pResponseChefs }) =>
  ({ clientSetUpTime, headquarterId, hasCateringMenuItems }) =>
  async ({ search }) => {
    if (clientSetUpTime) {
      const params = {
        date: clientSetUpTime.toString(),
        hqId: headquarterId,
        day: clientSetUpTime.isoWeekday(),
        search,
      }

      const cParams = camelCaseify(params)
      const availChefs = await RestService.get(
        '/chefs/schedules/avail',
        cParams,
      )
      const orderChefs =
        (OrderService.getState().order &&
          OrderService.getState().order.chefs) ||
        []

      const totalAvailableChefs = [...availChefs, ...orderChefs]

      const totalAvailableChefsSlices = []
      const sliceLimit = 50
      for (let i = 0; i < totalAvailableChefs.length; i += sliceLimit) {
        totalAvailableChefsSlices.push(
          totalAvailableChefs.slice(i, i + sliceLimit),
        )
      }

      const data = await axios.all(
        totalAvailableChefsSlices.map((chefs) => {
          const getChefsParams = {
            ids: [...chefs].map((chef) => chef.id).join(','),
            results_per_page: 100,
          }
          if (hasCateringMenuItems) {
            getChefsParams['with_catering_menu_items'] = true
          }

          return RestService.get('/api/admin/chefs', getChefsParams, {
            timeout: 60000,
          })
        }),
      )

      const chefs = data.reduce((a, b) => a.concat(b), [])

      return pResponseChefs(chefs)
    } else {
      return []
    }
  }

export const AsyncLoadConcepts =
  ({ RestService, pRequestLoadConcepts, pResponseAllChefConcepts }) =>
  async (chefId, orderType = undefined) => {
    const request = pRequestLoadConcepts(orderType)

    const concepts = await RestService.get(
      `/api/admin/chefs/${chefId}/load_menu_concepts`,
      request,
      { timeout: 60000 },
    )

    return pResponseAllChefConcepts(concepts)
  }

export const AsyncLoadDietaryPreferences =
  ({
    RestService,
    SettingsService,
    pMapOptionAttributes,
    pRequestSettings,
    pResponseSettings,
    LoadSettings,
    RetryOnTimeout,
    HandleError,
  }) =>
  async () => {
    const { allDietaryPreferences } = await LoadSettings({
      RestService,
      SettingsService,
      pRequestSettings,
      pResponseSettings,
      RetryOnTimeout,
      HandleError,
    })('menu_items')()

    return pMapOptionAttributes(allDietaryPreferences)
  }

export const AsyncLoadOrderSettings =
  ({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    pOrderSettings,
    LoadSettings,
    RetryOnTimeout,
    HandleError,
  }) =>
  async () => {
    const ordersSettings = await LoadSettings({
      RestService,
      SettingsService,
      pRequestSettings,
      pResponseSettings,
      RetryOnTimeout,
      HandleError,
    })('orders')()

    return pOrderSettings(ordersSettings)
  }

export const AsyncLoadTaxRates =
  ({
    RestService,
    SettingsService,
    pResponseGeneric,
    HandleError,
    RetryOnTimeout,
  }) =>
  async (zipcode, state = null, currency = 'USD') => {
    let { taxRates } = SettingsService.getState().settings

    const req = () =>
      RestService.get('/api/admin/orders/get_tax_rates', {
        zipcode,
        state,
        currency,
      })
    try {
      taxRates = await RetryOnTimeout(req)
    } catch (error) {
      HandleError({ error, namespace: 'editOrderModal' })

      return {}
    }

    if (taxRates) {
      taxRates = pResponseGeneric(taxRates)
      SettingsService.setSettings({
        settingsKey: 'taxRates',
        settingsValue: taxRates,
      })

      return taxRates
    }

    return {}
  }

export const GetItemCountsByType =
  ({ RestService }) =>
  async (chefId, type) => {
    const params = {
      is_approved: true,
      parent_items: true,
      include_all: true,
      chef_ids: [chefId],
      count: true,
    }
    const { count } = await RestService.get(`/api/admin/${type}`, params, {
      timeout: 20000,
    })

    return count
  }
