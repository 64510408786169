import { useCallback, useReducer, useRef, useEffect } from 'react'
import Moment from 'moment-timezone'

export enum InvoiceStatus {
  Unpaid = 'Unpaid',
  Paid = 'Paid',
  Resolved = 'Resolved',
}

export const AllInvoiceStatuses = [
  InvoiceStatus.Unpaid,
  InvoiceStatus.Paid,
  InvoiceStatus.Resolved,
]

// Types preserved from the original code
export interface Order {
  id: string
  date: string
  orderNumber: string
  unpaidAmount: number
  isPaid: boolean
  'dgraph.type': string[]
}

export interface Client {
  id: string
  name: string
  pin: number
}

export interface OrderPayment {
  id: string
  amount: number
  isPaid: boolean
  paymentFor?: Order
}

export interface Invoice {
  id: string
  invoiceNumber: string
  createdAt: string
  date: string
  serviceDateStart?: Moment.Moment
  serviceDateEnd?: Moment.Moment
  effectiveDate: string
  dueDate: string
  buyer?: Client
  payments?: OrderPayment[]
  totalDue: string
  totalPaid: string
  isAutoInv: boolean
  isResolved: boolean
  isPaid: boolean
  isInvalid: boolean
  overBatchedOrders: string[]
  orderNumbers: string[]
  numOrders: number
  status: InvoiceStatus
  isAllOrdersPaid: boolean
}

export interface SearchInvoicesParams {
  from?: Moment.Moment
  to?: Moment.Moment
  isPaid?: boolean
  isResolved?: boolean
  clientId?: string
  search?: string
  orderSearch?: string
  offset?: number
  limit?: number
}

export interface UseInvoiceSearchProps {
  searchInvoices: (params: SearchInvoicesParams) => Promise<Invoice[]>
  invoicesPerPage: number
  clientId?: string
}

export interface UseInvoiceSearchResult {
  pageInvoices: Invoice[]
  search: string
  statuses: InvoiceStatus[]
  sortBy: [keyof Invoice, boolean, (a: any) => any]
  pageNum: number
  totalFilteredInvoices: number
  excludeZeroDue: boolean
  excludeAllPaidOrders: boolean
  loadingInvoices: boolean
  serviceDateStartFrom?: Moment.Moment
  serviceDateStartTo?: Moment.Moment
  serviceDateEndFrom?: Moment.Moment
  serviceDateEndTo?: Moment.Moment
  setSearch: (search: string) => void
  setStatuses: (statuses: InvoiceStatus[]) => void
  setSortBy: (sortBy: [keyof Invoice, boolean, (a: any) => any]) => void
  executeSearchInvoices: () => Promise<void>
  resetSearch: () => void
  sortInvoices: (attribute: keyof Invoice, transform?: (a: any) => any) => void
  sortByIcon: (attribute: string) => string
  setPageNum: (pageNum: number) => void
  setExcludeZeroDue: (excludeZeroDue: boolean) => void
  setExcludeAllPaidOrders: (excludeAllPaidOrders: boolean) => void
  onUpdateInvoiceInState: (invoice: Invoice) => void
  setServiceDateStartFrom: (date: Moment.Moment | undefined) => void
  setServiceDateStartTo: (date: Moment.Moment | undefined) => void
  setServiceDateEndFrom: (date: Moment.Moment | undefined) => void
  setServiceDateEndTo: (date: Moment.Moment | undefined) => void
}

// Action types for our reducer
type InvoiceAction =
  | { type: 'RESET_SEARCH' }
  | { type: 'SET_SEARCH'; payload: string }
  | { type: 'SET_SERVICE_DATE_START_FROM'; payload: Moment.Moment | undefined }
  | { type: 'SET_SERVICE_DATE_START_TO'; payload: Moment.Moment | undefined }
  | { type: 'SET_SERVICE_DATE_END_FROM'; payload: Moment.Moment | undefined }
  | { type: 'SET_SERVICE_DATE_END_TO'; payload: Moment.Moment | undefined }
  | { type: 'SET_STATUSES'; payload: InvoiceStatus[] }
  | { type: 'SET_PAGE_NUM'; payload: number }
  | { type: 'SET_SORT_BY'; payload: [keyof Invoice, boolean, (a: any) => any] }
  | { type: 'SET_EXCLUDE_ZERO_DUE'; payload: boolean }
  | { type: 'SET_EXCLUDE_ALL_PAID_ORDERS'; payload: boolean }
  | { type: 'SET_INVOICES'; payload: Invoice[] }
  | { type: 'ADD_INVOICES'; payload: Invoice[] }
  | { type: 'ADD_LOADED_STATUSES'; payload: InvoiceStatus[] }
  | { type: 'FILTER_INVOICES' }
  | { type: 'SET_LOADING_INVOICES'; payload: boolean }

// State interface for our reducer
interface InvoiceState {
  invoices: Invoice[]
  filteredInvoices: Invoice[]
  pageInvoices: Invoice[]
  serviceDateStartFrom: Moment.Moment | undefined
  serviceDateStartTo: Moment.Moment | undefined
  serviceDateEndFrom: Moment.Moment | undefined
  serviceDateEndTo: Moment.Moment | undefined
  search: string
  statuses: InvoiceStatus[]
  loadedInvoiceStatuses: InvoiceStatus[]
  sortBy: [attribute: keyof Invoice, asc: boolean, transform: (a: any) => any]
  pageNum: number
  excludeZeroDue: boolean
  excludeAllPaidOrders: boolean
  loadingInvoices: boolean
}

const limit = 10

// Initial state for our reducer
const initialState: InvoiceState = {
  invoices: [],
  filteredInvoices: [],
  pageInvoices: [],
  serviceDateStartFrom: undefined,
  serviceDateStartTo: undefined,
  serviceDateEndFrom: undefined,
  serviceDateEndTo: undefined,
  search: '',
  statuses: [InvoiceStatus.Unpaid],
  loadedInvoiceStatuses: [],
  sortBy: ['createdAt', false, (crAt) => Moment(crAt).unix()],
  pageNum: 1,
  excludeZeroDue: true,
  excludeAllPaidOrders: true,
  loadingInvoices: false,
}

const sortInvoicesHelper = (
  invoices: Invoice[],
  sortBy: [keyof Invoice, boolean, (a: any) => any],
) => {
  const [attribute, asc, transform] = sortBy
  const actualTransform = transform || ((a: any) => a)

  return [...invoices].sort((a, b) => {
    return asc
      ? actualTransform(a[attribute]) - actualTransform(b[attribute])
      : actualTransform(b[attribute]) - actualTransform(a[attribute])
  })
}

// Helper function to filter invoices based on state. All filtering is done on the FE.
const filterInvoicesHelper = (
  invoices: Invoice[],
  state: InvoiceState,
  invoicesPerPage: number,
): { filteredInvoices: Invoice[]; pageInvoices: Invoice[] } => {
  const {
    search,
    statuses,
    excludeZeroDue,
    excludeAllPaidOrders,
    pageNum,
    serviceDateEndFrom,
    serviceDateEndTo,
    serviceDateStartFrom,
    serviceDateStartTo,
  } = state

  const filteredInvoices = invoices.filter((invoice) => {
    const isStatusMatch = statuses.includes(invoice.status)
    const isZeroDueMatch = excludeZeroDue
      ? parseFloat(invoice.totalDue) !== 0
      : true
    const isAllPaidOrdersMatch = excludeAllPaidOrders
      ? !invoice.isAllOrdersPaid
      : true
    const serviceDateStartFromMatch = serviceDateStartFrom
      ? invoice.serviceDateStart &&
        serviceDateStartFrom.isBefore(invoice.serviceDateStart)
      : true
    const serviceDateStartToMatch = serviceDateStartTo
      ? invoice.serviceDateStart &&
        serviceDateStartTo.isAfter(invoice.serviceDateStart)
      : true
    const serviceDateEndFromMatch = serviceDateEndFrom
      ? invoice.serviceDateEnd &&
        serviceDateEndFrom.isBefore(invoice.serviceDateEnd)
      : true
    const serviceDateEndToMatch = serviceDateEndTo
      ? invoice.serviceDateEnd &&
        serviceDateEndTo.isAfter(invoice.serviceDateEnd)
      : true

    let isSearchMatch = true
    if (search) {
      const lowerSearch = search.toLowerCase()
      const invoiceNumMatch = invoice.invoiceNumber
        .toLowerCase()
        .includes(lowerSearch)
      const orderMatch = invoice.payments?.some(
        (pmt) =>
          pmt.paymentFor?.orderNumber.toLowerCase().includes(lowerSearch),
      )
      isSearchMatch = Boolean(invoiceNumMatch || orderMatch)
    }

    return (
      isStatusMatch &&
      isZeroDueMatch &&
      isAllPaidOrdersMatch &&
      isSearchMatch &&
      serviceDateStartFromMatch &&
      serviceDateStartToMatch &&
      serviceDateEndFromMatch &&
      serviceDateEndToMatch
    )
  })

  const pageInvoices = filteredInvoices.slice(
    (pageNum - 1) * invoicesPerPage,
    pageNum * invoicesPerPage,
  )

  return { filteredInvoices, pageInvoices }
}

// Our reducer function
const invoiceReducer = (
  state: InvoiceState,
  action: InvoiceAction,
  invoicesPerPage: number,
): InvoiceState => {
  switch (action.type) {
    case 'RESET_SEARCH':
      return {
        ...initialState,
      }
    case 'SET_SEARCH':
      return {
        ...state,
        search: action.payload,
      }
    case 'SET_SERVICE_DATE_START_FROM':
      return {
        ...state,
        serviceDateStartFrom: action.payload,
      }
    case 'SET_SERVICE_DATE_START_TO':
      return {
        ...state,
        serviceDateStartTo: action.payload,
      }
    case 'SET_SERVICE_DATE_END_FROM':
      return {
        ...state,
        serviceDateEndFrom: action.payload,
      }
    case 'SET_SERVICE_DATE_END_TO':
      return {
        ...state,
        serviceDateEndTo: action.payload,
      }
    case 'SET_STATUSES':
      return {
        ...state,
        statuses: action.payload,
      }
    case 'SET_PAGE_NUM':
      return {
        ...state,
        pageNum: action.payload,
      }
    case 'SET_SORT_BY':
      return {
        ...state,
        sortBy: action.payload,
      }
    case 'SET_EXCLUDE_ZERO_DUE':
      return {
        ...state,
        excludeZeroDue: action.payload,
      }
    case 'SET_EXCLUDE_ALL_PAID_ORDERS':
      return {
        ...state,
        excludeAllPaidOrders: action.payload,
      }
    case 'SET_INVOICES': {
      const invoices = action.payload
      const { filteredInvoices, pageInvoices } = filterInvoicesHelper(
        invoices,
        state,
        invoicesPerPage,
      )

      return {
        ...state,
        invoices,
        filteredInvoices,
        pageInvoices,
      }
    }
    case 'ADD_INVOICES': {
      const newInvoiceIds = action.payload.reduce(
        (acc, invoice) => {
          if (invoice.id) {
            acc[invoice.id] = true
          }

          return acc
        },
        {} as Record<string, boolean>,
      )

      // prevent duplicates from being added. Invoices from a previously unloaded status can
      // still be in state when invoices are edited
      let nextInvoices = state.invoices.filter((inv) => !newInvoiceIds[inv.id])
      nextInvoices.push(...action.payload)

      nextInvoices = sortInvoicesHelper(nextInvoices, state.sortBy)

      const { filteredInvoices, pageInvoices } = filterInvoicesHelper(
        nextInvoices,
        state,
        invoicesPerPage,
      )

      return {
        ...state,
        invoices: nextInvoices,
        filteredInvoices,
        pageInvoices,
      }
    }
    case 'ADD_LOADED_STATUSES': {
      const nextLoadedInvoiceStatuses = [
        ...state.loadedInvoiceStatuses.filter(
          (s) => !action.payload.includes(s),
        ),
        ...action.payload,
      ]

      return {
        ...state,
        loadedInvoiceStatuses: nextLoadedInvoiceStatuses,
      }
    }
    case 'FILTER_INVOICES': {
      const { filteredInvoices, pageInvoices } = filterInvoicesHelper(
        state.invoices,
        state,
        invoicesPerPage,
      )

      return {
        ...state,
        filteredInvoices,
        pageInvoices,
      }
    }
    case 'SET_LOADING_INVOICES': {
      return {
        ...state,
        loadingInvoices: action.payload,
      }
    }
    default:
      return state
  }
}

export const useInvoiceSearch = ({
  searchInvoices,
  clientId,
  invoicesPerPage,
}: UseInvoiceSearchProps): UseInvoiceSearchResult => {
  // Create a stable wrapper around our reducer to include invoicesPerPage
  const reducerFn = useCallback(
    (state: InvoiceState, action: InvoiceAction) =>
      invoiceReducer(state, action, invoicesPerPage),
    [invoicesPerPage],
  )

  // Use reducer for state management
  const [state, dispatch] = useReducer(reducerFn, initialState)

  // Destructure state for easier access
  const {
    pageInvoices,
    serviceDateStartFrom,
    serviceDateStartTo,
    serviceDateEndFrom,
    serviceDateEndTo,
    search,
    statuses,
    sortBy,
    pageNum,
    filteredInvoices,
    invoices,
    loadingInvoices,
    excludeZeroDue,
    excludeAllPaidOrders,
    loadedInvoiceStatuses,
  } = state

  // Track if the client ID or page number changes
  const previousClientIdRef = useRef<string | undefined>(undefined)
  const previousPageNumRef = useRef<number | undefined>(undefined)

  // In order to minimize fetches to the API we track which invoice statuses have been loaded and
  // keep all fetched invoices for this client in state and only fetch when filtering for new statuses
  const loadInvoices = useCallback(
    async (filterStatuses: InvoiceStatus[]) => {
      // Build search params by status
      const params: SearchInvoicesParams = {
        clientId,
        offset: 0,
        limit,
      }

      let addedInvoices: Invoice[] = []

      // Resolved and paid statuses are mutually exclusive so when both filter types are active
      // we need to fetch all invoices for this client
      if (
        filterStatuses.includes(InvoiceStatus.Resolved) &&
        (filterStatuses.includes(InvoiceStatus.Unpaid) ||
          filterStatuses.includes(InvoiceStatus.Paid))
      ) {
        // no param properties needed when all statuses are fetched
      } else if (filterStatuses.includes(InvoiceStatus.Resolved)) {
        // should only reach this point if resolved is the only filter
        params.isResolved = true
      } else {
        // should only reach this point if filter does not have resolved
        params.isResolved = false
        if (
          filterStatuses.includes(InvoiceStatus.Paid) &&
          filterStatuses.includes(InvoiceStatus.Unpaid)
        ) {
          // need to fetch all paid statuses so dont include isPaid attribute
        } else if (filterStatuses.includes(InvoiceStatus.Unpaid)) {
          params.isPaid = false
        } else if (filterStatuses.includes(InvoiceStatus.Paid)) {
          params.isPaid = true
        }
      }

      dispatch({ type: 'SET_LOADING_INVOICES', payload: true })

      let fin = false
      // Append invoices of this status to the existing invoices
      while (!fin) {
        const invResp = await searchInvoices(params)
        addedInvoices = addedInvoices.concat(invResp)
        params.offset = (params.offset || 0) + limit
        if (invResp.length !== limit) {
          fin = true
        }
      }

      dispatch({ type: 'SET_LOADING_INVOICES', payload: false })
      dispatch({ type: 'ADD_INVOICES', payload: addedInvoices })
      dispatch({ type: 'ADD_LOADED_STATUSES', payload: filterStatuses })
    },
    [searchInvoices, clientId],
  )

  // Determines when new statuses are filtered and when to load additional invoices.
  // Coordinates loading and filtering of invoices based on current loaded statuses.
  const executeSearchInvoices = useCallback(async () => {
    if (!clientId) {
      return
    }

    const newStatuses = statuses.filter(
      (s) => !loadedInvoiceStatuses.includes(s),
    )

    if (newStatuses.length > 0) {
      await loadInvoices(newStatuses)
    }
    dispatch({ type: 'FILTER_INVOICES' })
  }, [clientId, statuses, loadedInvoiceStatuses, loadInvoices])

  // Reset search
  const resetSearch = useCallback(() => {
    dispatch({ type: 'RESET_SEARCH' })
  }, [])

  // Sort invoices
  const sortInvoices = useCallback(
    (attribute: keyof Invoice, transform?: (a: any) => any) => {
      const asc = attribute !== sortBy[0] || !sortBy[1]
      const actualTransform = transform || ((a: any) => a)
      const nextSortBy: [keyof Invoice, boolean, (a: any) => any] = [
        attribute,
        asc,
        actualTransform,
      ]
      dispatch({ type: 'SET_SORT_BY', payload: nextSortBy })
      const sortedInvoices = sortInvoicesHelper(invoices, nextSortBy)
      dispatch({ type: 'SET_INVOICES', payload: sortedInvoices })
    },
    [sortBy, invoices],
  )

  // Get sort icon
  const sortByIcon = useCallback(
    (attribute: string) => {
      const [sortAttribute, isAsc] = sortBy
      if (sortAttribute === attribute) {
        return isAsc ? '▲' : '▼'
      }

      return ''
    },
    [sortBy],
  )

  // Effects to trigger search invoices
  useEffect(() => {
    const clientIdChanged = clientId !== previousClientIdRef.current
    const pageNumChanged = pageNum !== previousPageNumRef.current
    if (clientIdChanged || pageNumChanged) {
      if (clientIdChanged) {
        resetSearch()
      }
      if (clientId) {
        executeSearchInvoices()
      }
      previousClientIdRef.current = clientId
      previousPageNumRef.current = pageNum
    }
  }, [clientId, pageNum, resetSearch, executeSearchInvoices])

  // Create setters that dispatch actions
  // Setters are wrapped in useCallback to maintain stable function identity and prevent unnecessary re-renders

  const setServiceDateStartFrom = useCallback(
    (serviceDateStartFrom: Moment.Moment | undefined) => {
      dispatch({
        type: 'SET_SERVICE_DATE_START_FROM',
        payload: serviceDateStartFrom,
      })
    },
    [],
  )

  const setServiceDateStartTo = useCallback(
    (serviceDateStartTo: Moment.Moment | undefined) => {
      dispatch({
        type: 'SET_SERVICE_DATE_START_TO',
        payload: serviceDateStartTo,
      })
    },
    [],
  )

  const setServiceDateEndFrom = useCallback(
    (serviceDateEndFrom: Moment.Moment | undefined) => {
      dispatch({
        type: 'SET_SERVICE_DATE_END_FROM',
        payload: serviceDateEndFrom,
      })
    },
    [],
  )

  const setServiceDateEndTo = useCallback(
    (serviceDateEndTo: Moment.Moment | undefined) => {
      dispatch({ type: 'SET_SERVICE_DATE_END_TO', payload: serviceDateEndTo })
    },
    [],
  )

  const setSearch = useCallback((search: string) => {
    dispatch({ type: 'SET_SEARCH', payload: search })
  }, [])

  const setStatuses = useCallback((statuses: InvoiceStatus[]) => {
    dispatch({ type: 'SET_STATUSES', payload: statuses })
  }, [])

  const setPageNum = useCallback((pageNum: number) => {
    dispatch({ type: 'SET_PAGE_NUM', payload: pageNum })
  }, [])

  const setSortBy = useCallback(
    (sortBy: [keyof Invoice, boolean, (a: any) => any]) => {
      dispatch({ type: 'SET_SORT_BY', payload: sortBy })
    },
    [],
  )

  const setExcludeZeroDue = useCallback((excludeZeroDue: boolean) => {
    dispatch({ type: 'SET_EXCLUDE_ZERO_DUE', payload: excludeZeroDue })
  }, [])

  const setExcludeAllPaidOrders = useCallback(
    (excludeAllPaidOrders: boolean) => {
      dispatch({
        type: 'SET_EXCLUDE_ALL_PAID_ORDERS',
        payload: excludeAllPaidOrders,
      })
    },
    [],
  )

  const onUpdateInvoiceInState = useCallback((invoice: Invoice) => {
    dispatch({ type: 'ADD_INVOICES', payload: [invoice] })
  }, [])

  return {
    pageInvoices,
    search,
    statuses,
    sortBy,
    serviceDateStartFrom,
    serviceDateStartTo,
    serviceDateEndFrom,
    serviceDateEndTo,
    pageNum,
    excludeZeroDue,
    excludeAllPaidOrders,
    totalFilteredInvoices: filteredInvoices.length,
    executeSearchInvoices,
    resetSearch,
    sortInvoices,
    sortByIcon,
    loadingInvoices,
    setServiceDateStartFrom,
    setServiceDateStartTo,
    setServiceDateEndFrom,
    setServiceDateEndTo,
    setSearch,
    setStatuses,
    setSortBy,
    setPageNum,
    setExcludeZeroDue,
    setExcludeAllPaidOrders,
    onUpdateInvoiceInState,
  }
}
