import React, { useState } from 'react'
import { parse as qsParse } from 'qs'
import { ParsedQs } from 'qs'

import { InvoicesModal } from '@containers/invoice'
import FindInvoice from './FindInvoice'
import ClientARManagement from './ClientARManagement'
import { Invoice, SearchInvoicesParams } from './UseInvoiceSearch'
import { Order, GetUnpaidOrdersParams } from './UseUnpaidOrders'

// Types
enum PageMode {
  ManageClientAR = 'ManageClientAR',
  FindBatchInvoice = 'FindBatchInvoice',
}

interface Client {
  id: string
  name: string
  pin: string
}

interface AccountingClient extends Client {
  autoBatchActive: boolean
  autoBatchPeriod: number
  netPaymentDays: number
}

interface InvoicePageProps {
  headquarter?: number
  invoiceUrl: string
  invoicePDFUrl: string
  location: {
    pathname: string
    search: string
  }
  confirmationModal: (options: { text: string }) => Promise<boolean>
  getAccountingClient: (accountId: string) => Promise<AccountingClient | null>
  getInvoice: (id: string) => Promise<Invoice | null>
  getInvoiceHistory: (id: string, invoiceNumber: string) => void
  getUnpaidOrders: (params: GetUnpaidOrdersParams) => Promise<Order[]>
  searchClients: (params: any) => Promise<Client[]> // TODO: Define proper search params type
  searchInvoices: (params: SearchInvoicesParams) => Promise<Invoice[]>
  pResponseInvoicesDashboard: (params: any) => Invoice[]
}

export const parseUrlForKey = (queryString: string, key: string): string => {
  const parsed = qsParse(queryString, { ignoreQueryPrefix: true }) as ParsedQs

  return (parsed[key] as string) || ''
}

export const parseQueryStringForOrderIds = (
  queryString: string,
): string[] | null => {
  const orderIdsString = parseUrlForKey(queryString, 'order_ids')
  let orderIds = null
  if (orderIdsString && typeof orderIdsString === 'string') {
    orderIds = orderIdsString.split(',')
  }

  return orderIds
}

export const parseQueryStringForInvoiceId = (
  queryString: string,
): string | null => {
  const invoiceId = parseUrlForKey(queryString, 'invoice_id')

  return invoiceId || null
}

export const parseQueryStringForArClientId = (
  queryString: string,
): string | null => {
  const arClientId = parseUrlForKey(queryString, 'ar_client_id')

  return arClientId || null
}

const InvoicesPageV2: React.FC<InvoicePageProps> = ({
  invoiceUrl,
  invoicePDFUrl,
  getAccountingClient,
  getInvoice,
  getInvoiceHistory,
  getUnpaidOrders,
  searchClients,
  searchInvoices,
  confirmationModal,
  pResponseInvoicesDashboard,
  location,
}) => {
  // Break down state into individual hooks
  const [pageMode, setPageMode] = useState<PageMode | undefined>(undefined)
  const [arClientId, setArClientId] = useState<string | undefined>(undefined) // TODO - refactor ar client id implementation
  const [preloadOrders, setPreloadOrders] = useState<string[] | null>(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | undefined>(
    undefined,
  )
  const [updatedInvoice, setUpdatedInvoice] = useState<Invoice | undefined>(
    undefined,
  )

  // Initialize based on URL query parameters
  React.useEffect(() => {
    if (location && location.search) {
      // Handle invoice id in url
      const invoiceId = parseQueryStringForInvoiceId(location.search)
      if (invoiceId) {
        getInvoice(invoiceId).then((invoice) => {
          if (invoice) {
            setSelectedInvoice(invoice)
            setShowEditModal(true)
          }
        })
      }

      // Handle ar client and order ids in url
      const orderIds = parseQueryStringForOrderIds(location.search)
      const urlArClientId = parseQueryStringForArClientId(location.search)
      if (urlArClientId) {
        if (orderIds && orderIds.length > 0) {
          setPreloadOrders(orderIds)
        }
        setArClientId(urlArClientId)
        setShowEditModal(true)
      }
    }
  }, [location, getInvoice])

  // Handlers

  const editInvoice = (id: string) => async () => {
    const invoice = await getInvoice(id)
    if (invoice) {
      setSelectedInvoice(invoice)
      setShowEditModal(true)
    }
  }

  const hideEditModal = () => {
    setShowEditModal(false)
    setSelectedInvoice(undefined)
    setPreloadOrders(null)
  }

  const onViewPDF = (invoice: Invoice, isSummary: boolean) => async () => {
    const pdfUrl = isSummary
      ? `${invoicePDFUrl}/${invoice.id}/batch_invoice_pdf_summary`
      : `${invoicePDFUrl}/${invoice.id}/batch_invoice_pdf`
    if (invoice.isResolved) {
      const confirmed = await confirmationModal({
        text: 'This Invoice has been resolved. This PDF should not be sent to the client.',
      })
      if (!confirmed) {
        return
      }
    }
    window.open(pdfUrl)
  }

  const updateInvoices = (inv: Invoice) => {
    setSelectedInvoice(inv)
    setShowEditModal(false)
    const nextInvs = pResponseInvoicesDashboard({ invoices: [inv] })
    setUpdatedInvoice(nextInvs[0])
  }

  return (
    <div className="w-full max-w-screen p-8">
      {showEditModal && (
        <InvoicesModal
          arClientId={arClientId}
          invoice={selectedInvoice}
          preloadOrders={preloadOrders}
          hideModal={hideEditModal}
          updateInvoices={updateInvoices}
        />
      )}

      <div className="m-4">
        <label className="block text-xs uppercase font-semibold text-gray-700">
          What do you want to do?
        </label>
        <div className="relative">
          <select
            className="border border-black border-solid rounded px-3 py-3 w-[200px]"
            value=""
            onChange={(e) => {
              const { value } = e.target
              if (value === 'documentation') {
                window.open(
                  'https://docs.google.com/document/d/1o4RMj3dBNMgvUnmMkX7mmG6FnVcimY9H65F3GfhMnJI/edit?usp=sharing',
                  '_blank',
                  'noopener noreferrer',
                )
              } else if (value === 'newInvoice') {
                setShowEditModal(true)
                setSelectedInvoice(undefined)
              } else if (value === 'findInvoice') {
                setPageMode(PageMode.FindBatchInvoice)
              } else if (value === 'manageClient') {
                setPageMode(PageMode.ManageClientAR)
              }
              // Reset the select to empty value after selection
              e.target.value = ''
            }}
          >
            <option value="" disabled>
              Select action...
            </option>
            <option value="documentation">
              View AR & Batch Invoicing Documentation
            </option>
            <option value="newInvoice">Create New Invoice</option>
            <option value="findInvoice">Find Batch Invoice</option>
            <option value="manageClient">Manage Client AR</option>
          </select>
        </div>
      </div>

      {pageMode === PageMode.FindBatchInvoice && (
        <FindInvoice
          invoiceUrl={invoiceUrl}
          searchInvoices={searchInvoices}
          getInvoiceHistory={getInvoiceHistory}
          onViewPDF={onViewPDF}
          onEditInvoice={editInvoice}
        />
      )}

      {pageMode === PageMode.ManageClientAR && (
        <ClientARManagement
          invoiceUrl={invoiceUrl}
          getAccountingClient={getAccountingClient}
          getInvoiceHistory={getInvoiceHistory}
          getUnpaidOrders={getUnpaidOrders}
          searchClients={searchClients}
          searchInvoices={searchInvoices}
          onViewPDF={onViewPDF}
          onEditInvoice={editInvoice}
          updatedInvoice={updatedInvoice}
        />
      )}
    </div>
  )
}

export default InvoicesPageV2
