import React, { useEffect, useState } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { FaCircleXmark } from 'react-icons/fa6'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import XSpacing from '@components/common/XSpacing'
import YSpacing from '@components/common/YSpacing'
import DividerLine from '@components/common/DividerLine'
import LoadingIndicator from '@components/common/LoadingIndicator'
import {
  Button,
  Checkbox,
  Dropdown,
  Input,
  MultiSelectDropDown,
} from '@components/common/form'
import { EditMenuItemModal } from '@containers/chef/edit'
import FlexContainer from '@components/common/FlexContainer'
import { ChefAuditItem } from '@types'

const marketTypes = ['Office', 'Group Order']
const auditTypes = [
  { label: 'Menu Item Description Verified', value: 'description_verified' },
  { label: 'Allergen Tags Verified', value: 'allergens_verified' },
  { label: 'Serving Details Verified', value: 'serving_details_verified' },
  {
    label: 'Real Image Uploaded Verified',
    value: 'image_verified',
  },
]

type searchItemRequest = {
  chefId: string
  page: number
  resultsPerPage: number
  search?: string
  marketType?: string
  mealType?: string
  goMealType?: string
  selectedAuditTypes?: string[]
  sort?: string
}

interface MenuItemAuditProps {
  chefId: string
  showEditItemModal: boolean
  editChildMenuItem: (id: string, parentMenuItemId: string | null) => void
  editMenuItem: (id: string) => void
  loadMenuItemSettings: () => Promise<{
    allMealTypes: string[]
    allGroupOrderMealTypes: string[]
  }>
  loadChefItemsForAudit: (r: searchItemRequest) => Promise<ChefAuditItem[]>
}

const MenuItemAudits = ({
  chefId,
  editChildMenuItem,
  editMenuItem,
  loadMenuItemSettings,
  loadChefItemsForAudit,
  showEditItemModal,
}: MenuItemAuditProps) => {
  const [dishTypes, setDishTypes] = useState<string[]>([])
  const [goMealType, setGoMealType] = useState('')
  const [goMealTypes, setGoMealTypes] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [marketType, setMarketType] = useState('')
  const [menuItems, setMenuItems] = useState<ChefAuditItem[]>([])
  const [mealType, setMealType] = useState('')
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [showChildItems, setShowChildItems] = useState(false)
  const [selectedAuditTypes, setSelectedAuditTypes] = useState<string[]>([])
  const resultsPerPage = 20

  useEffect(() => {
    ;(async () => {
      const { allMealTypes, allGroupOrderMealTypes } =
        await loadMenuItemSettings()
      setDishTypes(allMealTypes)
      setGoMealTypes(allGroupOrderMealTypes)
    })()
  }, [loadMenuItemSettings])

  const handleSearch = async () => {
    setIsLoading(true)
    const items = await loadChefItemsForAudit({
      chefId,
      page,
      resultsPerPage,
      search,
      marketType,
      mealType,
      goMealType,
      selectedAuditTypes,
      sort: 'name',
    })
    setMenuItems(items)
    setIsLoading(false)
  }

  return (
    <div>
      {showEditItemModal && <EditMenuItemModal loadMenuItems={handleSearch} />}
      <div className="search-panel">
        <FlexContainer>
          <Input
            label="Filter Menu Items"
            onChange={(e) => setSearch(e.target.value)}
            width="250px"
            placeholder={'Filter Menu Items'}
          />
          <XSpacing width="20px" />
          <Dropdown
            label="Filter Market Type"
            width="200px"
            value={marketType}
            onChange={(e) => setMarketType(e.target.value)}
          >
            <option value="">No Filter</option>
            {marketTypes.map((market) => (
              <option key={market} value={market}>
                {market}
              </option>
            ))}
          </Dropdown>
          <XSpacing width="20px" />
          <Dropdown
            label="Filter Dish Type"
            width="200px"
            value={mealType}
            onChange={(e) => setMealType(e.target.value)}
          >
            <option value="">No Filter</option>
            {dishTypes.map((meal) => (
              <option key={meal} value={meal}>
                {meal}
              </option>
            ))}
          </Dropdown>
          <XSpacing width="20px" />
          <Dropdown
            label="Filter Group Order Meal Type"
            width="230px"
            value={goMealType}
            onChange={(e) => setGoMealType(e.target.value)}
          >
            <option value="">No Filter</option>
            {goMealTypes.map((mealType) => (
              <option key={mealType} value={mealType}>
                {mealType}
              </option>
            ))}
          </Dropdown>
          <XSpacing width="20px" />
          <MultiSelectDropDown
            checkedOptions={selectedAuditTypes}
            includeSelectAll={true}
            label="Filter By Audit Type"
            options={auditTypes}
            onChange={(checkedOptions) => setSelectedAuditTypes(checkedOptions)}
            width="230px"
          />
        </FlexContainer>
        <FlexContainer alignItems="center" justifyContent="space-between">
          <Button
            label="Search"
            backgroundColor={'#0288d1'}
            onClick={handleSearch}
          />
          <FlexContainer
            width="10%"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Checkbox
              label="Show Child Items"
              value={showChildItems}
              onChange={() => setShowChildItems(!showChildItems)}
            />
          </FlexContainer>
        </FlexContainer>
        <YSpacing height="5px" />
        <DividerLine height="2px" />
        <FlexContainer
          alignItems="center"
          justifyContent="space-between"
          padding="10px 0px"
        >
          <div className="results-info">
            <h1>Showing {resultsPerPage} Per Page</h1>
          </div>
          <div className="pagination-controls">
            <button
              className="pagination-button"
              onClick={() => setPage(page - 1)}
              disabled={page <= 0}
            >
              <BiLeftArrow />
            </button>
            <XSpacing width="5px" />
            <p className="pagination-text">Page {page + 1}</p>
            <XSpacing width="5px" />
            <button
              onClick={() => setPage(page + 1)}
              className="pagination-button"
              disabled={menuItems.length < resultsPerPage}
            >
              <BiRightArrow />
            </button>
          </div>
        </FlexContainer>
        <div className="notes-container">
          <h1>NOTES ABOUT THIS TAB</h1>
          <ul>
            <li>
              <strong>
                Press the search button any time a filter is updated to refresh
                the menu items
              </strong>
            </li>
            <li>
              <strong>
                Any update made to a menu item will require a refresh of menu
                items to see updated data
              </strong>
            </li>
            <li>
              <strong>Items highlighted in </strong>
              <span className="color-indicator orange"></span>
              <strong>
                {' '}
                have their verifications complete, child item verifications are
                incomplete
              </strong>
            </li>
            {showChildItems && (
              <li>
                <strong>Child items are highlighted in: </strong>
                <span className="color-indicator lightblue"></span>
              </li>
            )}
          </ul>
        </div>
      </div>
      <YSpacing height="10px" />
      <Panel width="100%" maxWidth="auto">
        <Table>
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Market Type</th>
              <th>Dish Type</th>
              <th>GO Meal Type</th>
              <th className="verification-cell">
                Menu Item Description Verified
              </th>
              <th className="verification-cell">Allergen Tags Verified</th>
              <th className="verification-cell">Servings Details Verified</th>
              <th className="verification-cell">Real Image Uploaded</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan={4}>
                  <LoadingIndicator />
                </td>
              </tr>
            )}
            {!isLoading && menuItems.length == 0 && (
              <tr>
                <td colSpan={5}>
                  <div className="empty-state">No menu items yet.</div>
                </td>
              </tr>
            )}
            {!isLoading &&
              menuItems.length > 0 &&
              menuItems.map((item) => {
                return (
                  <>
                    <tr
                      key={item.id}
                      onClick={() => editMenuItem(item.id)}
                      className={
                        item.itemVerificationsCompleted &&
                        !item.allChildVerificationsCompleted
                          ? 'row-verified-incomplete'
                          : ''
                      }
                    >
                      <td>{item.name}</td>
                      <td>{item.marketType}</td>
                      <td>{item.mealType}</td>
                      <td>
                        {item.tagsGroupOrderMealTypeList &&
                          item.tagsGroupOrderMealTypeList
                            .split(',')
                            .map((goMealType: string) => (
                              <span
                                key={`tag_${goMealType}`}
                                className="badge badge-cuisine"
                              >
                                {' '}
                                {goMealType}{' '}
                              </span>
                            ))}
                      </td>
                      <td className="verification-cell">
                        {item.descriptionVerified ? (
                          <FaCheckCircle
                            className="icon-centered"
                            color={'green'}
                          />
                        ) : (
                          <FaCircleXmark
                            className="icon-centered"
                            color={'red'}
                          />
                        )}
                      </td>
                      <td className="verification-cell">
                        {item.allergensVerified ? (
                          <FaCheckCircle
                            className="icon-centered"
                            color={'green'}
                          />
                        ) : (
                          <FaCircleXmark
                            className="icon-centered"
                            color={'red'}
                          />
                        )}
                      </td>
                      <td className="verification-cell">
                        {item.servingDetailsVerified ? (
                          <FaCheckCircle
                            className="icon-centered"
                            color={'green'}
                          />
                        ) : (
                          <FaCircleXmark
                            className="icon-centered"
                            color={'red'}
                          />
                        )}
                      </td>
                      <td className="verification-cell">
                        {!item.isSubMenuItem &&
                        item.marketType == 'Group Order' ? (
                          item.imageVerified ? (
                            <FaCheckCircle color={'green'} />
                          ) : (
                            <FaCircleXmark
                              className="icon-centered"
                              color={'red'}
                            />
                          )
                        ) : (
                          'N/A'
                        )}
                      </td>
                    </tr>
                    {(item.childItems || []).length > 0 &&
                      showChildItems &&
                      (item.childItems || []).map((childItem) => (
                        <tr
                          key={childItem.id}
                          onClick={() =>
                            editChildMenuItem(
                              childItem.id,
                              childItem.parentMenuItemId,
                            )
                          }
                          className="row-child-item"
                        >
                          <td>
                            {item.name} - {childItem.name}
                          </td>
                          <td>{childItem.marketType}</td>
                          <td>{childItem.mealType}</td>
                          <td>N/A</td>
                          <td className="verification-cell">
                            {childItem.descriptionVerified ? (
                              <FaCheckCircle
                                className="icon-centered"
                                color={'green'}
                              />
                            ) : (
                              <FaCircleXmark
                                className="icon-centered"
                                color={'red'}
                              />
                            )}
                          </td>
                          <td className="verification-cell">
                            {childItem.allergensVerified ? (
                              <FaCheckCircle
                                className="icon-centered"
                                color={'green'}
                              />
                            ) : (
                              <FaCircleXmark
                                className="icon-centered"
                                color={'red'}
                              />
                            )}
                          </td>
                          <td className="verification-cell">
                            {childItem.servingDetailsVerified ? (
                              <FaCheckCircle
                                className="icon-centered"
                                color={'green'}
                              />
                            ) : (
                              <FaCircleXmark
                                className="icon-centered"
                                color={'red'}
                              />
                            )}
                          </td>
                          <td className="verification-cell">N/A</td>
                        </tr>
                      ))}
                  </>
                )
              })}
          </tbody>
        </Table>
      </Panel>
    </div>
  )
}

export default MenuItemAudits
