import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { LabelInfo, LinkText } from '@components/common/form'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import { ColumnContainer, Label } from '@res/styledComponents/index'

import { moneyString } from '~/utils'

const ChefProfileListGroup = (props) => {
  if (!props.chefProfile) {
    return null
  }

  const {
    accomplishments,
    accountName,
    description,
    addOnChef,
    status,
    chefPriorityType,
    headshotThumb,
    yearsCooking,
    hometown,
    maxBuffet,
    maxIndividual,
    maxGroupOrderItems,
    minCateringPayout,
    onSiteFee,
    pricingAgreement,
    advanceHrsNotice,
    vendorOnly,
    customPath,
    groupOrderEarliestPickupTime,
    cateringEarliestPickupTime,
  } = props.chefProfile
  let line1, line2, city, state, zip
  if (props.address) {
    ;({ line1, line2, city, state, zip } = props.address)
  }
  const { headquarters } = props

  const pEarliestPickupTime = (pickupTime) => {
    if (!pickupTime) {
      return 'Not Set'
    }
    let hour = Math.floor(pickupTime / 100)
    const minute = pickupTime % 100
    const suffix = hour >= 12 ? 'PM' : 'AM'

    if (hour % 12 === 0) {
      hour = 12
    } else {
      hour = hour % 12
    }

    return `${hour}:${String(minute).padStart(2, '0')} ${suffix}`
  }

  return (
    <ColumnContainer>
      <Panel width="100%" maxWidth="400px" heading="Profile">
        {headshotThumb && (
          <FlexContainer width="28%">
            <ChefImage src={headshotThumb} />
          </FlexContainer>
        )}
        <LabelInfo label="Bio" value={description || 'N/A'} width="100%" />
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <FlexContainer
            justifyContent="space-between"
            width="48%"
            flexDirection="column"
          >
            <Label>Chef Store Link</Label>
            {vendorOnly ? (
              <p>N/A</p>
            ) : (
              <LinkText
                isLink={true}
                label="View"
                link={`https://tryhungry.com/chef/${customPath}`}
              />
            )}
          </FlexContainer>
          <LabelInfo label="Store URL Path" value={customPath} width="48%" />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <LabelInfo
            label="Add On Chef"
            value={addOnChef ? 'Yes' : 'No'}
            width="48%"
          />
          <LabelInfo
            label="Vendor Only"
            value={vendorOnly ? 'Yes' : 'No'}
            width="48%"
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <LabelInfo label="Status" value={status || 'inactive'} width="48%" />
          <LabelInfo label="Account Name" value={accountName} width="48%" />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <LabelInfo
            label="Chef Priority"
            value={chefPriorityType || 'None'}
            width="48%"
          />
          <LabelInfo label="Years as a chef" value={yearsCooking} width="48%" />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <LabelInfo
            label="Awards on Chef Page"
            value={accomplishments ? accomplishments : 'N/A'}
            width="48%"
          />
          <LabelInfo label="Hometown/Country" value={hometown} width="48%" />
        </FlexContainer>
        <YSpacing height="20px" />
        <LabelInfo
          label="Delivery Address"
          value={`${line1},${
            line2 ? ' ' + line2 : ''
          } ${city}, ${state}, ${zip}`}
          width="100%"
        />
      </Panel>

      {!vendorOnly && (
        <Panel width="100%" maxWidth="400px" heading="Capabilities">
          <FlexContainer justifyContent="space-between">
            <LabelInfo
              label="Catering Earliest Pickup Time"
              width="48%"
              value={pEarliestPickupTime(cateringEarliestPickupTime)}
            />
            <LabelInfo
              label="Group Order Earliest Pickup Time"
              width="48%"
              value={pEarliestPickupTime(groupOrderEarliestPickupTime)}
            />
          </FlexContainer>
          <YSpacing height="20px" />
          <FlexContainer justifyContent="space-between">
            <LabelInfo
              label="Advance Hours Notice"
              value={advanceHrsNotice === null ? 'Not Set' : advanceHrsNotice}
              width="48%"
            />
            <LabelInfo
              label="On-Site Fee"
              value={`$${onSiteFee}`}
              width="48%"
            />
          </FlexContainer>
          <YSpacing height="20px" />
          <FlexContainer justifyContent="space-between">
            <LabelInfo
              label="Maximum Buffets Served Daily"
              value={maxBuffet === null ? 'Not Set' : maxBuffet}
              width="48%"
            />
            <LabelInfo
              label="Maximum Individuals Served Daily"
              value={maxIndividual === null ? 'Not Set' : maxIndividual}
              width="48%"
            />
          </FlexContainer>
          <YSpacing height="20px" />
          <FlexContainer justifyContent="space-between">
            <LabelInfo
              label="Maximum Group Order items Served Daily"
              value={
                maxGroupOrderItems === 0 ? 'Unlimited' : maxGroupOrderItems
              }
              width="48%"
            />
            <LabelInfo
              label="Minimum Catering Order Payout"
              value={
                minCateringPayout
                  ? `$${moneyString(minCateringPayout)}`
                  : 'No minimum'
              }
              width="48%"
            />
          </FlexContainer>
          <YSpacing height="20px" />
          <FlexContainer>
            <LabelInfo
              label="Chef Pricing Agreement"
              value={pricingAgreement || 'Not Set'}
              width="100%"
            />
          </FlexContainer>
        </Panel>
      )}
      {headquarters.length > 1 && (
        <Panel heading="Headquarters" maxWidth="400px">
          {headquarters.map((hq) => (
            <div key={hq.id}>
              <LabelInfo label="Associated Headquarter" value={`${hq.name}`} />
              <YSpacing height="20px" />
            </div>
          ))}
        </Panel>
      )}
    </ColumnContainer>
  )
}

const ChefImage = styled.img`
  width: 70px;
  border-radius: 100px;
  position: absolute;
  right: 20px;
  top: 20px;
`

ChefProfileListGroup.propTypes = {
  address: PropTypes.object,
  chefProfile: PropTypes.object,
  headquarters: PropTypes.array,
}

ChefProfileListGroup.defaultProps = {
  chefProfile: {},
}

export default ChefProfileListGroup
