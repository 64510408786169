import { connect } from 'react-redux'
import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'
import InvoicesPageV2 from '../../components/invoice/InvoicesPageV2'
import { config } from 'hungry-core2'

const mapStateToProps = (state) => {
  const port = 3001
  const uri =
    config.marketplace_url +
    (config.marketplace_url === 'http://localhost' ? `:${port}` : '')
  const invoiceUrl = `${uri}/all-invoices`
  const invoicePDFUrl = `${config.api_host}/accounting/invoice`
  const { router } = state

  return {
    invoiceUrl,
    invoicePDFUrl,
    ...router,
  }
}

const mapDispatchToProps = () => {
  const {
    pRequestARParams,
    pResponseVXAccounts,
    pResponseInvoice,
    pResponseInvoicesDashboard,
    pResponseGeneric,
    pResponseUnpaidOrders,
  } = presenters.Api
  const { RestService, UIService, VXInvoiceService } = services
  const {
    GetInvoice,
    GetAccountingClient,
    GetInvoiceHistory,
    SearchDetailAccounts,
    SearchInvoices,
    SearchOrders,
    GetUnpaidOrders,
  } = coordinators
  const { newInvoice, newOrderPayment, newBuyer } = VXInvoiceService
  const { FlashMessage, ConfirmationModal } = UIService
  const { displayFailureMessage } = FlashMessage
  const getAccountingClient = GetAccountingClient({
    RestService,
    pResponseEditClientAccounting: pResponseGeneric,
  })
  const getInvoice = GetInvoice({ RestService, pResponseInvoice, HandleError })
  const getInvoiceHistory = GetInvoiceHistory({
    RestService,
    HandleError,
    UIService,
  })
  const getUnpaidOrders = GetUnpaidOrders({
    RestService,
    pRequestARParams,
    pResponseUnpaidOrders,
    HandleError,
  })
  const searchInvoices = SearchInvoices({
    RestService,
    pResponseInvoices: pResponseInvoicesDashboard,
    HandleError,
  })
  const searchOrders = SearchOrders({ RestService, HandleError })
  const searchClients = SearchDetailAccounts({
    RestService,
    pResponseAccounts: pResponseVXAccounts,
  })

  return {
    confirmationModal: ConfirmationModal,
    displayFailureMessage,
    getAccountingClient,
    getInvoice,
    getInvoiceHistory,
    getUnpaidOrders,
    searchClients,
    searchInvoices,
    newInvoice,
    newOrderPayment,
    newBuyer,
    pResponseInvoicesDashboard,
    searchOrders,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesPageV2)
