import { connect } from 'react-redux'

import { ConsumptionReportsPage } from '@components/consumption'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { user } = state

  return { headquarterId: user.lastLoggedInHq }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService } = services
  const { pResponseChefs, pResponseGeneric } = presenters.Api
  const { AsyncLoadAccounts, LoadChefs } = coordinators
  const loadAccounts = AsyncLoadAccounts({ RestService, pResponseGeneric })
  const loadChefs = LoadChefs({ ChefService, RestService, pResponseChefs })

  return {
    loadAccounts,
    loadChefs,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConsumptionReportsPage)
