export const vAddress = {
  line1: [{ type: 'present', message: 'street address missing' }],
  city: [{ type: 'present' }],
  state: [{ type: 'present' }],
  zip: [
    { type: 'present' },
    {
      type: 'regex',
      pattern: /^(\d{5}|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/,
      message: 'invalid zipcode',
    },
  ],
}

export const vAccountAddress = {
  line1: [{ type: 'present', message: 'street address missing' }],
  city: [{ type: 'present' }],
  state: [{ type: 'present' }],
  zip: [
    { type: 'present' },
    {
      type: 'regex',
      pattern: /^(\d{5}|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/,
      message: 'invalid zipcode',
    },
  ],
  buildingInstructions: [{ type: 'present' }],
}
