import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FlexContainer } from '@components/common'
import Modal from '@components/common/modal/Modal'
import Table from '@components/common/Table'
import YSpacing from '@components/common/YSpacing'
import Loader from '@components/common/Loader'
import { Button, Checkbox, Dropdown } from '@components/common/form'
import { Tab } from '@res/styledComponents/index'
import { colors } from '@constants'
import TrashPng from '@res/images/trash-icon.png'
import sort from '@res/images/sort.svg'

const ItemEditsTab = 'itemEdits'
const NewItemsTab = 'newItems'

const MenuItemUpdates = (props) => {
  const {
    chefNetworkBulkCompleteNewItem,
    chefNetworkBulkCompleteItemUpdate,
    chefNetworkCreatedNewItem,
    chefNetworkCompletedItemUpdate,
    chefNetworkDeletedCreateRequest,
    chefNetworkSaveMenuItemImage,
    chefNetworkDeletedUpdateRequest,
    chefNetworkUpdateItemRequest,
    loadNewMenuItems,
    loadMenuItemUpdates,
    headquarters,
    headquarterId,
  } = props
  const [activeTab, setActiveTab] = useState(ItemEditsTab)
  const [viewArchivedRequests, setViewArchivedRequests] = useState(false)
  const [menuItemUpdates, setMenuItemUpdates] = useState([])
  const [newMenuItems, setNewMenuItems] = useState([])
  const [sortAscending, setSortAscending] = useState(true)
  const [sortField, setSortField] = useState()
  const [menuItemImage, setMenuItemImage] = useState()
  const [bulkCheckedItems, setBulkCheckedItems] = useState({})
  const [showLoader, setShowLoader] = useState(false)

  const [imageSaveIsLoading, setImageSaveIsLoading] = useState(false)
  const [hqId, setHqId] = useState(headquarterId)

  useEffect(() => {
    onLoadNewMenuItems()
    onLoadMenuItemUpdates()
  }, [hqId, viewArchivedRequests])

  const onLoadMenuItemUpdates = useCallback(async (sort = undefined) => {
    setShowLoader(true)
    const req = {
      headquarter_id: hqId,
      archived: viewArchivedRequests,
    }
    if (sort) {
      req.sort = sort
    }
    const menuItems = await loadMenuItemUpdates(req)
    if (menuItems) {
      setMenuItemUpdates(menuItems)
    } else {
      setMenuItemUpdates([])
    }
    setShowLoader(false)
  })

  const onLoadNewMenuItems = useCallback(async (sort = undefined) => {
    setShowLoader(true)
    const req = {
      headquarter_id: hqId,
      archived: viewArchivedRequests,
    }
    if (sort) {
      req.sort = sort
    }
    const menuItems = await loadNewMenuItems(req)
    if (menuItems) {
      setNewMenuItems(menuItems)
    } else {
      setNewMenuItems([])
    }
    setShowLoader(false)
  })

  const onMarkArchive = async (item) => {
    const updateReq = {
      id: item.id,
      archived: !viewArchivedRequests,
      headquarter_id: hqId,
    }
    // Used to Reload item requests
    const fetchReq = {
      headquarter_id: hqId,
      archived: viewArchivedRequests,
    }
    let forNewItems
    if (activeTab === ItemEditsTab) {
      forNewItems = false
    } else {
      forNewItems = true
    }
    const menuItems = await chefNetworkUpdateItemRequest(
      updateReq,
      fetchReq,
      forNewItems,
    )
    if (menuItems && activeTab === ItemEditsTab) {
      setMenuItemUpdates(menuItems)
    } else if (menuItems && activeTab === NewItemsTab) {
      setNewMenuItems(menuItems)
    }
  }

  const onMarkUpdated = async (item) => {
    const req = {
      id: item.id,
      changes_completed: true,
      headquarter_id: hqId,
    }

    const success = await chefNetworkCompletedItemUpdate(req)
    if (success) {
      onLoadMenuItemUpdates(sortField)
    }
  }

  const onMarkCreated = async (item) => {
    const req = {
      id: item.id,
      item_created: true,
      headquarter_id: hqId,
    }

    const success = await chefNetworkCreatedNewItem(req)
    if (success) {
      onLoadNewMenuItems(sortField)
    }
  }

  const onBulkCreationsComplete = useCallback(async () => {
    setShowLoader(true)
    const itemIds = Object.keys(bulkCheckedItems).filter(
      (id) => bulkCheckedItems[id],
    )
    const reqs = itemIds.map((id) => {
      const menuItem = newMenuItems.find((u) => u.id === id)

      return {
        id,
        item_created: true,
        headquarter_id: hqId,
        menuItemName: menuItem?.menuItemName,
      }
    })
    const successfulItemsMap = await chefNetworkBulkCompleteNewItem(reqs)

    setBulkCheckedItems((prev) => {
      const newBulkCheckedItems = { ...prev }
      Object.keys(successfulItemsMap).forEach(
        (id) => delete newBulkCheckedItems[id],
      )

      return newBulkCheckedItems
    })

    onLoadNewMenuItems(sortField)
    setShowLoader(false)
  }, [
    bulkCheckedItems,
    newMenuItems,
    hqId,
    onLoadNewMenuItems,
    sortField,
    chefNetworkBulkCompleteNewItem,
  ])

  const onBulkEditsComplete = useCallback(async () => {
    setShowLoader(true)
    const itemIds = Object.keys(bulkCheckedItems).filter(
      (id) => bulkCheckedItems[id],
    )

    const reqs = itemIds.map((id) => {
      const menuItem = menuItemUpdates.find((u) => u.id === id)

      return {
        id,
        changes_completed: true,
        headquarter_id: hqId,
        menuItemName: menuItem?.menuItemName,
      }
    })
    const successfulItemsMap = await chefNetworkBulkCompleteItemUpdate(reqs)
    setBulkCheckedItems((prev) => {
      const newBulkCheckedItems = { ...prev }
      Object.keys(successfulItemsMap).forEach(
        (id) => delete newBulkCheckedItems[id],
      )

      return newBulkCheckedItems
    })
    onLoadMenuItemUpdates(sortField)

    setShowLoader(false)
  }, [
    bulkCheckedItems,
    menuItemUpdates,
    hqId,
    onLoadMenuItemUpdates,
    sortField,
    chefNetworkBulkCompleteItemUpdate,
  ])

  const onDeleteUpdateRequest = async (item) => {
    const req = {
      id: item.id,
    }
    const success = await chefNetworkDeletedUpdateRequest(req)
    if (success) {
      onLoadMenuItemUpdates(sortField)
    }
  }

  const onDeleteCreateRequest = async (item) => {
    const req = {
      id: item.id,
    }
    const success = await chefNetworkDeletedCreateRequest(req)
    if (success) {
      onLoadNewMenuItems()
    }
  }

  const onSaveMenuItemImage = async () => {
    setImageSaveIsLoading(true)
    const req = {
      image_id: menuItemImage.image.id,
      menu_item_id: menuItemImage.menuItemId,
    }
    const success = await chefNetworkSaveMenuItemImage(req)
    if (success) {
      setMenuItemImage(undefined)
      onLoadMenuItemUpdates(sortField)
    }
    setImageSaveIsLoading(false)
  }

  const sortItemsByString = (columnName) => {
    let newSort = `${columnName} ${sortAscending ? 'ASC' : 'DESC'}`
    if (columnName === 'name') {
      newSort = 'menu_items.' + newSort
    }
    if (activeTab === ItemEditsTab) {
      onLoadMenuItemUpdates(newSort)
    } else {
      onLoadNewMenuItems(newSort)
    }
    setSortField(newSort)
    setSortAscending(!sortAscending)
  }

  const sortItemsByChefValue = (columnName) => {
    const newSort = `users.${columnName} ${sortAscending ? 'ASC' : 'DESC'}`
    if (activeTab === ItemEditsTab) {
      onLoadMenuItemUpdates(newSort)
    } else {
      onLoadNewMenuItems(newSort)
    }
    setSortField(newSort)
    setSortAscending(!sortAscending)
  }

  const renderItemEdits = () => {
    return (
      <>
        <Table>
          <tr>
            <th
              className="pointer"
              onClick={() => sortItemsByString('edit_date')}
            >
              <p className="flex">
                Edit Request Date
                <img className="item-sort" src={sort} />
              </p>
            </th>
            <th
              className="pointer"
              onClick={() => sortItemsByChefValue('first_name')}
            >
              <p className="flex">
                Chef Name
                <img className="item-sort" src={sort} />
              </p>
            </th>
            <th>Chef Email</th>
            <th>Chef Phone</th>
            <th className="pointer" onClick={() => sortItemsByString('name')}>
              <p className="flex">
                Item Name
                <img className="item-sort" src={sort} />
              </p>
            </th>
            <th>Requested Edit</th>
            <th>Image</th>
            <th>CN Made Changes</th>
            <th>{viewArchivedRequests ? 'Unarchive' : 'Archive'} Item</th>
            <th>Delete Item</th>
            <th>Bulk Mark Completed</th>
          </tr>
          {showLoader ? (
            <Loader />
          ) : (
            <>
              {menuItemUpdates.map((item, i) => (
                <tr key={`${item.menuItemId}-${i}`}>
                  {' '}
                  {/* Prevent rendering issues if there are multiple reqs for the same item */}
                  <td>
                    <p>{item.editDate}</p>
                  </td>
                  <td>
                    <p style={{ width: '150px' }}>{item.chef.name}</p>
                  </td>
                  <td>
                    <p>{item.chef.email}</p>
                  </td>
                  <td>
                    <p>{item.chef.phone}</p>
                  </td>
                  <td>
                    <p style={{ width: '150px' }}>{item.menuItemName}</p>
                  </td>
                  <td>
                    <p style={{ width: '150px' }}>
                      {item.requestType === 'remove' && (
                        <span
                          style={{ color: colors.violet, fontFamily: 'bold' }}
                        >
                          DELETE ITEM:{' '}
                        </span>
                      )}
                      {item.message}
                    </p>
                  </td>
                  <td>
                    {item.image ? (
                      <button
                        style={{ color: 'green' }}
                        onClick={() => setMenuItemImage(item)}
                      >
                        View Image
                      </button>
                    ) : (
                      <p>No Image</p>
                    )}
                  </td>
                  <td>
                    {item.changesCompleted ? (
                      <div className="flex flex-col items-center justify-center">
                        <p className="mb-2">Yes</p>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center">
                        <Button
                          label="Changes Completed"
                          width="min-content"
                          padding="5px 10px"
                          className="mb-2"
                          onClick={() => onMarkUpdated(item)}
                        />
                      </div>
                    )}
                  </td>
                  <td>
                    <Button
                      label={`${
                        viewArchivedRequests ? 'Unarchive' : 'Archive'
                      } Item`}
                      width="min-content"
                      padding="5px 10px"
                      className="mb-2"
                      onClick={() => onMarkArchive(item)}
                    />
                  </td>
                  <td>
                    <div className="flex flex-col items-center justify-center">
                      <img
                        src={TrashPng}
                        alt="Delete"
                        width="15"
                        height="15"
                        style={{
                          cursor: item.changesCompleted
                            ? 'pointer'
                            : 'not-allowed',
                          opacity: item.changesCompleted ? 1 : 0.5,
                        }}
                        onClick={() => {
                          if (item.changesCompleted) {
                            onDeleteUpdateRequest(item)
                          }
                        }}
                      />
                    </div>
                  </td>
                  <td className="flex flex-col items-center justify-center align-center">
                    <div>
                      {!item.changesCompleted && (
                        <Checkbox
                          checked={bulkCheckedItems[item.id]}
                          onChange={() =>
                            setBulkCheckedItems({
                              ...bulkCheckedItems,
                              [item.id]: !bulkCheckedItems[item.id],
                            })
                          }
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </>
          )}
        </Table>
        {Object.values(bulkCheckedItems).filter((v) => v).length > 0 && (
          <div className="page-fixed">
            <button className="button-primary" onClick={onBulkEditsComplete}>
              Bulk Mark Edit Changes Complete
            </button>
          </div>
        )}
      </>
    )
  }
  //Admin changes
  const renderNewItems = () => {
    return (
      <div>
        <Table>
          <tr>
            <th
              className="pointer"
              onClick={() => sortItemsByString('request_date')}
            >
              <p className="flex">
                Date
                <img className="item-sort" src={sort} />
              </p>
            </th>
            <th
              className="pointer"
              onClick={() => sortItemsByChefValue('first_name')}
            >
              <p className="flex">
                Chef Name
                <img className="item-sort" src={sort} />
              </p>
            </th>
            <th>Chef Email</th>
            <th>Chef Phone</th>
            <th>Item Name</th>
            <th>Description</th>
            <th>Chef Price</th>
            <th>Ingredients</th>
            <th>Dietary Restrictions</th>
            <th>Image</th>
            <th>Item created</th>
            <th>{viewArchivedRequests ? 'Unarchive' : 'Archive'} Item</th>
            <th>Delete Item</th>
            <th>Bulk Mark Completed</th>
          </tr>
          {showLoader ? (
            <Loader />
          ) : (
            <>
              {newMenuItems.map((item, i) => (
                <tr key={`${item.id}-${i}`}>
                  {' '}
                  {/* Prevent rendering issues if there are multiple reqs for the same item */}
                  <td>
                    <p style={{ width: '100px' }}>{item.requestDate}</p>
                  </td>
                  <td>
                    <p style={{ width: '150px' }}>{item.chef.name}</p>
                  </td>
                  <td>
                    <p>{item.chef.email}</p>
                  </td>
                  <td>
                    <p>{item.chef.phone}</p>
                  </td>
                  <td>
                    <p style={{ width: '150px' }}>{item.menuItemName}</p>
                  </td>
                  <td>
                    <p style={{ width: '150px' }}>{item.description}</p>
                  </td>
                  <td>
                    <p>${item.chefPrice}</p>
                  </td>
                  <td>
                    <p>{item.ingredients}</p>
                  </td>
                  <td>
                    <p>{item.dietaryPreferences}</p>
                  </td>
                  <td>
                    {item.image ? (
                      <button
                        style={{ color: 'green' }}
                        onClick={() => setMenuItemImage(item)}
                      >
                        View Image
                      </button>
                    ) : (
                      <p>No Image</p>
                    )}
                  </td>
                  <td>
                    {item.itemCreated ? (
                      <div className="flex flex-col items-center justify-center">
                        <p className="mb-2">Yes</p>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center">
                        <Button
                          label="Mark Created"
                          width="auto"
                          padding="5px 10px"
                          className="mb-2"
                          onClick={() => onMarkCreated(item)}
                        />
                      </div>
                    )}
                  </td>
                  <td>
                    <Button
                      label={`${
                        viewArchivedRequests ? 'Unarchive' : 'Archive'
                      } Item`}
                      width="min-content"
                      padding="5px 10px"
                      className="mb-2"
                      onClick={() => onMarkArchive(item)}
                    />
                  </td>
                  <td>
                    <div className="flex flex-col items-center justify-center">
                      <img
                        src={TrashPng}
                        alt="Delete"
                        width="15"
                        height="15"
                        style={{
                          cursor: item.itemCreated ? 'pointer' : 'not-allowed',
                          opacity: item.itemCreated ? 1 : 0.5,
                        }}
                        onClick={() => {
                          if (item.itemCreated) {
                            onDeleteCreateRequest(item)
                          }
                        }}
                        disabled={true}
                      />
                    </div>
                  </td>
                  <td className="flex flex-col items-center justify-center align-center">
                    <div>
                      {!item.itemCreated && (
                        <Checkbox
                          checked={bulkCheckedItems[item.id]}
                          onChange={() =>
                            setBulkCheckedItems({
                              ...bulkCheckedItems,
                              [item.id]: !bulkCheckedItems[item.id],
                            })
                          }
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </>
          )}
        </Table>
        {Object.values(bulkCheckedItems).filter((v) => v).length > 0 && (
          <div className="page-fixed">
            <button
              className="button-primary"
              onClick={onBulkCreationsComplete}
            >
              Bulk Mark Edit Changes Complete
            </button>
          </div>
        )}
      </div>
    )
  }

  const renderImageModal = () => {
    return (
      <Modal
        hideModal={() => setMenuItemImage(undefined)}
        title={`Image for ${menuItemImage.menuItemName}`}
        width="550px"
      >
        <FlexContainer flexDirection="column" alignItems="center">
          <h1 className="text-grey-darkest mb-1 text-center text-xl font-bold">
            {`${menuItemImage.menuItemName}'s Image`}
          </h1>
          <div>
            <img src={menuItemImage.image.image.url} />
          </div>
          <YSpacing height="5px" />
          {activeTab !== NewItemsTab ? (
            <>
              {imageSaveIsLoading ? (
                <Loader />
              ) : (
                <Button
                  label="Save Image to Item"
                  onClick={() => onSaveMenuItemImage()}
                />
              )}
              <YSpacing height="5px" />
              <p style={{ color: 'red' }}>
                NOTE: Saving this Image to Item will automatically link this new
                image to the menu item.
              </p>
            </>
          ) : (
            <p style={{ color: 'green' }}>
              Please download this image & upload to new menu item after it has
              been created
            </p>
          )}
        </FlexContainer>
      </Modal>
    )
  }

  return (
    <div>
      <FlexContainer flexDirection="column" overflow="scroll">
        <FlexContainer padding="0 0 20px 0" alignItems="flex-end">
          <Dropdown
            width="200px"
            marginBottom="0"
            label="Headquarter"
            value={hqId}
            onChange={(e) => setHqId(Number(e.target.value))}
          >
            {headquarters.map((hq) => (
              <option key={hq.id} value={hq.id}>
                {hq.name}
              </option>
            ))}
          </Dropdown>
          <div style={{ height: '100%', width: '20px' }} />
          <Tab
            fontSize="15px"
            onClick={() => {
              setBulkCheckedItems({})
              setActiveTab(ItemEditsTab)
            }}
            isActive={activeTab === ItemEditsTab}
            width="225px"
          >
            Menu Item Edits
          </Tab>
          <Tab
            fontSize="15px"
            onClick={() => {
              setBulkCheckedItems({})
              setActiveTab(NewItemsTab)
            }}
            isActive={activeTab === NewItemsTab}
            width="225px"
          >
            New Menu Items
          </Tab>
          <Checkbox
            label="View Archived Updates"
            checked={viewArchivedRequests}
            onChange={() => {
              setBulkCheckedItems({})
              setViewArchivedRequests(!viewArchivedRequests)
            }}
            marginBottom="5px"
          />
        </FlexContainer>
        <p className="mb-5">
          {activeTab === ItemEditsTab
            ? `Menu Item Edits (${menuItemUpdates.length})`
            : `New Menu Items (${newMenuItems.length})`}
        </p>
      </FlexContainer>
      {activeTab === ItemEditsTab ? renderItemEdits() : renderNewItems()}
      {menuItemImage && renderImageModal()}
    </div>
  )
}

MenuItemUpdates.propTypes = {
  headquarters: PropTypes.array,
  headquarterId: PropTypes.number,

  chefNetworkBulkCompleteNewItem: PropTypes.func,
  chefNetworkBulkCompleteItemUpdate: PropTypes.func,
  chefNetworkCreatedNewItem: PropTypes.func,
  chefNetworkCompletedItemUpdate: PropTypes.func,
  chefNetworkDeletedCreateRequest: PropTypes.func,
  chefNetworkDeletedUpdateRequest: PropTypes.func,
  chefNetworkSaveMenuItemImage: PropTypes.func,
  chefNetworkUpdateItemRequest: PropTypes.func,
  loadMenuItemUpdates: PropTypes.func,
  loadNewMenuItems: PropTypes.func,
}

export default MenuItemUpdates
