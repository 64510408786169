import { connect } from 'react-redux'

import { MenuItemAudits } from '@components/chef/chefSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { chef, editMenuItemModal } = state
  const chefId = chef && chef.id

  return {
    chefId,
    showEditItemModal: editMenuItemModal.show,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService, UIService, SettingsService } = services
  const { pToMenuItemVerifications } = presenters
  const {
    pResponseMenuItem,
    pResponseAuditItems,
    pSerializeLoadMenuItemsParams,
    pResponseSettings,
    pRequestSettings,
  } = presenters.Api
  const {
    EditChildMenuItem,
    EditMenuItem,
    LoadChefMenuItemsForAudits,
    LoadSettings,
    RetryOnTimeout,
  } = coordinators

  const editMenuItem = EditMenuItem({
    ChefService,
    RestService,
    UIService,
    pResponseMenuItem,
  })
  const editChildMenuItem = EditChildMenuItem({
    ChefService,
    RestService,
    UIService,
    pResponseMenuItem,
  })
  const loadChefItemsForAudit = LoadChefMenuItemsForAudits({
    RestService,
    pResponseAuditItems,
    pSerializeLoadMenuItemsParams,
    HandleError,
  })

  const loadMenuItemSettings = LoadSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    RetryOnTimeout,
    HandleError,
  })('menuItems')

  return {
    editChildMenuItem,
    editMenuItem,
    loadChefItemsForAudit,
    loadMenuItemSettings,
    pToMenuItemVerifications,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemAudits)
