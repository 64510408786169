import { Thread } from '@assistant-ui/react'
import React, { useState, useEffect, ChangeEvent } from 'react'
import Moment from 'moment-timezone'

import { Dropdown, RangeSlider } from '@components/common/form'
import { HungryRuntimeProvider } from './runtimeProvider'
import { UserChat, Chat, ChatLog, LLMModel } from './types'
import coordinators from '@coordinators'
import services from '@services'

const { AiApiService } = services
const { GetChatLogs, GetLLMModels } = coordinators

const getChatLogs = async (userChatId: number): Promise<ChatLog[]> =>
  GetChatLogs({ AiApiService })(userChatId)
const getLLMModels = async (): Promise<LLMModel[]> =>
  GetLLMModels({ AiApiService })()

interface Account {
  id: number
  name: string
  clientChampion?: { firstName: string }
  clientHunter?: { firstName: string }
  pin?: string
}

interface DinerProfile {
  id: string
  accountId: number
  name: string
  detailName?: string
}

interface Order {
  orderNumber: string
}

interface Survey {
  results: Array<{ answer: string }>
}

interface Headquarters {
  id: number
  name: string
}

interface EditUserChatReq {
  userId: string
  userChatId: number
  title?: string
  isArchived?: boolean
}

interface ShowInfoModalReq {
  title: string
  content: React.ReactNode
}

const PlaygroundInfo = () => {
  return (
    <div className="flex flex-col items-center tracking-wider">
      <div>
        The Catering Concierge Playground is a chat bot that responds to the
        user&apos;s queries with natural language responses. Chats can be shared
        with other Hungry users by sending them the link to the chat.
      </div>

      <div className="my-4">
        <div className="uppercase underline font-semibold">
          Playground Settings
        </div>
        <div className="my-4">
          Thes playground settings configures the behavior of the chat
          bot&apos;s response.
          <ul className="list-disc pl-6 my-4">
            <li>
              <span className="font-semibold">LLM Model:</span> The underlying
              AI large language model used by the chat bot. Currently only
              supports OpenAI models. More details about them can be found here{' '}
              <a
                className="underline text-blue-700"
                target="_blank"
                href="https://platform.openai.com/docs/models"
                rel="noreferrer"
              >
                OpenAI LLM models
              </a>
            </li>
            <li>
              <span className="font-semibold">Temperature:</span> Controls
              Randomness. Lowering results in less random completions. As the
              temperature approaches zero, the model becomes deterministic and
              repetitive.
            </li>
            <li>
              <span className="font-semibold">Max Tokens:</span> The max number
              of tokens to generate shared between the prompt and completion.
              The exact limit varies by model. One token is roughly 4 characters
              for standard English text.
            </li>
            <li>
              <span className="font-semibold">Top P:</span> Controls diversity
              via nucleus sampling: 0.5 means half of all likelihood-weighted
              options are considered.
            </li>
          </ul>
        </div>
      </div>

      <div className="my-4">
        <div className="uppercase underline font-semibold">Tools</div>
        <div className="my-4">
          The chat bot has access to the following tools.
          <ul className="list-disc pl-6 my-4">
            <li>Get all Hungry markets</li>
            <li>Search and filter for accounts</li>
            <li>Search and filter for diner profiles</li>
            <li>Search and filter for chefs</li>
            <li>Search and filter for menu items</li>
            <li>Search and filter for catering orders</li>
            <li>List client - chef surveys for an account</li>
          </ul>
        </div>
        <div className="my-4">
          Tools can be API calls to the Hungry servers, calculators, or
          documents, currently all tools are API calls to Hungry servers. The
          chat will display when a tool is being called, what values are being
          passed to the tool, and what the tool&apos;s result is. The key to
          getting the chat bot to be effective is to make sure it calls the
          right tools with the right values.
        </div>
      </div>
    </div>
  )
}

interface Props {
  router: any
  userId: string
  headquarters: Headquarters[]
  editUserChat: (req: EditUserChatReq) => Promise<UserChat>
  getChatHistory: (chatId: number) => Promise<any[]>
  getUserChats: (userChatId: string) => Promise<UserChat[]>
  getMarketActiveItems: (hqId: number) => Promise<any[]>
  loadDinerProfiles: (params: { hqIds: number[] }) => Promise<DinerProfile[]>
  loadSurveys: (params: any) => Promise<Survey[]>
  searchAccountingClients: (params: { ids: number[] }) => Promise<Account[]>
  searchOrders: (params: any) => Promise<Order[]>
  showInfoModal: (req: ShowInfoModalReq) => void
  showConfirmModal: ({ text }: { text: string }) => Promise<boolean>
}

const extractTaskStepOutputResponse = (jsonStr: string): string => {
  try {
    const data = JSON.parse(jsonStr)
    const { payload } = data
    const regex =
      /TaskStepOutput\(output=AgentChatResponse\(response=["']([\s\S]*?)["'],\s*sources=/
    const match = payload.match(regex)

    return match ? match[1] : ''
  } catch (error) {
    return ''
  }
}

const extractToolCallName = (jsonStr: string): string => {
  try {
    const data = JSON.parse(jsonStr)
    const { payload } = data
    const regex = /tool=ToolMetadata\((?:[\s\S]*?)name\s*=\s*'([^']+)'/
    const match = payload.match(regex)

    return match ? match[1] : ''
  } catch (error) {
    return ''
  }
}

const extractToolCallArgs = (jsonStr: string): string => {
  try {
    const data = JSON.parse(jsonStr)
    const { payload } = data
    const regex = /arguments='(.*?)'/
    const match = payload.match(regex)

    return match ? match[1] : ''
  } catch (error) {
    return ''
  }
}

const ChatLogsDisplay = ({ logs }: { logs: ChatLog[] }) => {
  const [expandedLogs, setExpandedLogs] = useState<number[]>([])
  const [expandedGroups, setExpandedGroups] = useState<number[]>([])

  const toggleExpand = (index: number) => {
    setExpandedLogs((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    )
  }

  const toggleGroupExpand = (groupIndex: number) => {
    setExpandedGroups((prev) =>
      prev.includes(groupIndex)
        ? prev.filter((i) => i !== groupIndex)
        : [...prev, groupIndex],
    )
  }

  // Group logs by prompt
  const groupedLogs = logs.reduce<ChatLog[][]>((groups, log, index) => {
    const previousLog = logs[index - 1]
    if (
      (previousLog && previousLog.eventType === 'AgentChatWithStepEndEvent') ||
      index === 0
    ) {
      groups.push([])
    }

    const lastGroup = groups[groups.length - 1]
    lastGroup.push(log)

    return groups
  }, [])

  return (
    <div className="max-h-[70vh] overflow-y-auto w-full">
      {groupedLogs.map((group, groupIndex) => {
        const firstLog = group[0]
        const toolCalls = group.filter(
          (log) => log.eventType === 'AgentToolCallEvent',
        )
        const reasoningSteps = group.filter(
          (log) => log.eventType === 'AgentRunStepEndEvent',
        )

        return (
          <div
            key={groupIndex}
            className="mb-6 p-4 border-2 border-blue-200 rounded-lg"
          >
            <div
              className="flex items-center cursor-pointer hover:bg-gray-100 p-2 rounded"
              onClick={() => toggleGroupExpand(groupIndex)}
            >
              <span className="mr-2">
                {expandedGroups.includes(groupIndex) ? '▼' : '▶'}
              </span>
              <div>
                <div className="font-semibold">
                  {Moment(firstLog.timestamp).format('lll')}
                </div>
                <div className="text-sm">
                  {toolCalls.length > 0 && (
                    <div className="text-sm">
                      <span className="font-medium">Tool Calls:</span>{' '}
                      {toolCalls.length}
                    </div>
                  )}
                  {reasoningSteps.length > 0 && (
                    <div className="text-sm">
                      <span className="font-medium">Reasoning Steps:</span>{' '}
                      {reasoningSteps.length}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {expandedGroups.includes(groupIndex) &&
              group.map((log, index) => {
                const logContent =
                  typeof log.log === 'string'
                    ? log.log
                    : JSON.stringify(log.log, null, 2)

                return (
                  <div
                    key={index}
                    className="mb-4 p-3 border-solid border border-gray-400 rounded bg-gray-200 mt-2"
                  >
                    <div className="font-semibold">
                      {Moment(log.timestamp).format('lll')}
                    </div>
                    <div className="mt-2 text-sm">
                      <span className="font-medium">Type:</span> {log.logType}
                      {log.eventType && (
                        <span className="ml-3 font-medium">Event:</span>
                      )}{' '}
                      {log.eventType}
                      {log.operation && (
                        <span className="ml-3 font-medium">Operation:</span>
                      )}{' '}
                      {log.operation}
                    </div>
                    {log.eventType === 'AgentToolCallEvent' && (
                      <>
                        <div className="mt-2 text-sm">
                          <span className="font-medium">Tool:</span>{' '}
                          {extractToolCallName(logContent)}
                        </div>
                        <div className="mt-2 text-sm">
                          <span className="font-medium">Arguments:</span>{' '}
                          {extractToolCallArgs(logContent)}
                        </div>
                      </>
                    )}
                    {log.eventType === 'AgentRunStepEndEvent' && (
                      <>
                        <div className="mt-2 text-sm">
                          <span className="font-medium">Response:</span>
                        </div>
                        <div className="mt-2">
                          <textarea
                            readOnly
                            className="w-full font-mono text-sm bg-white p-2 rounded resize-none"
                            rows={3}
                            value={extractTaskStepOutputResponse(logContent)}
                          />
                        </div>
                      </>
                    )}
                    <div className="mt-2 text-sm font-medium">Data:</div>
                    <div className="mt-2">
                      <textarea
                        readOnly
                        className="w-full font-mono text-sm bg-white p-2 rounded resize-none"
                        rows={expandedLogs.includes(index) ? 15 : 5}
                        value={logContent}
                      />
                      <button
                        onClick={() => toggleExpand(index)}
                        className="mt-2 text-blue-500 text-sm hover:text-blue-700"
                      >
                        {expandedLogs.includes(index)
                          ? 'Show Less'
                          : 'Show More'}
                      </button>
                    </div>
                  </div>
                )
              })}
          </div>
        )
      })}
    </div>
  )
}

const PlaygroundPage: React.FC<Props> = ({
  router,
  userId,
  editUserChat,
  getChatHistory,
  getUserChats,
  showInfoModal,
  showConfirmModal,
}) => {
  const [llmModels, setLlmModels] = useState<LLMModel[]>([])
  const [selectedLLMModel, setSelectedLLMModel] = useState('')
  const [maxTokens, setMaxTokens] = useState(2048)
  const [topP, setTopP] = useState(0.0)
  const [temperature, setTemperature] = useState(0.1)
  const [userChatId, setUserChatId] = useState<number | null>(null)
  const [chatHistory, setChatHistory] = useState<Chat[]>([])
  const [userChats, setUserChats] = useState<UserChat[]>([])
  const [editUserChatReq, setEditUserChatReq] =
    useState<EditUserChatReq | null>(null)
  const [hoverUserChatId, setHoverUserChatId] = useState<number | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const chatsPerPage = 10

  // Calculate pagination values
  const indexOfLastChat = currentPage * chatsPerPage
  const indexOfFirstChat = indexOfLastChat - chatsPerPage
  const currentChats = userChats.slice(indexOfFirstChat, indexOfLastChat)
  const totalPages = Math.ceil(userChats.length / chatsPerPage)

  const setUrlChatId = (nextUserChatId: number) => {
    const params = new URLSearchParams(window.location.search)
    params.set('userChatId', nextUserChatId.toString())
    const newUrl = `${window.location.pathname}?${params.toString()}`
    window.history.pushState({}, '', newUrl)
  }

  const onLoadUserChatHistory = async (userChatId: number) => {
    const chatHistory = await getChatHistory(userChatId)
    setChatHistory(chatHistory)
  }

  // react router location only triggers when page loads so this only triggers on nav
  useEffect(() => {
    const params = new URLSearchParams(router.location.search)
    const userChatIdStr = params.get('userChatId')
    const userChatId = Number(userChatIdStr)
    if (!isNaN(userChatId) && userChatId > 0) {
      setUserChatId(userChatId)
      onLoadUserChatHistory(userChatId)
    }
  }, [router.location.search])

  useEffect(() => {
    const loadUserChats = async () => {
      let userChats = await getUserChats(userId)
      userChats = userChats.sort((a, b) =>
        Moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1,
      )
      setUserChats(userChats)
    }
    const loadLLMModels = async () => {
      const llmModels: LLMModel[] = await getLLMModels()
      if (llmModels) {
        setLlmModels(llmModels)
        setSelectedLLMModel(llmModels[0].modelName)
      }
    }

    loadUserChats()
    loadLLMModels()
  }, [])

  const onNewUserChat = () => {
    setUserChatId(null)
    setChatHistory([])
  }

  const onSelectUserChat = (userChat: UserChat) => () => {
    if (userChatId === userChat.id) {
      return
    }
    setUrlChatId(userChat.id)
    setUserChatId(userChat.id)
    onLoadUserChatHistory(userChat.id)
  }

  const onAddUserChat = (newUserChat: UserChat) => {
    setUserChats([newUserChat, ...userChats])
    setUrlChatId(newUserChat.id)
    setUserChatId(newUserChat.id)
  }

  const onEditUserChatTitle = (e: ChangeEvent<HTMLInputElement>) => {
    if (!userChatId) {
      return
    }
    const nextEditUserChatReq = { userId, userChatId, title: e.target.value }
    setEditUserChatReq(nextEditUserChatReq)
  }

  const onSaveUserChatEdit = (idx: number) => async () => {
    if (!editUserChatReq) {
      return
    }
    const nextUserChat = await editUserChat(editUserChatReq)
    const nextUserChats = userChats.slice()
    nextUserChats[idx] = nextUserChat
    setUserChats(nextUserChats)
    setEditUserChatReq(null)
  }

  const onArchiveUserChat = (userChat: UserChat, idx: number) => async () => {
    const doArchive = await showConfirmModal({
      text: `Are you sure you want to archive chat '${userChat.title}'?`,
    })
    if (doArchive) {
      const nextUserChat = await editUserChat({
        userId,
        userChatId: userChat.id,
        isArchived: true,
      })
      if (nextUserChat && nextUserChat.isArchived) {
        setUserChats([...userChats.slice(0, idx), ...userChats.slice(idx + 1)])
        if (userChatId === userChat.id) {
          onNewUserChat()
        }
      }
    }
  }

  console.log('render', userChatId, chatHistory)

  return (
    <div className="min-h-screen w-screen grid grid-rows-[auto_1fr] rounded-lg border-2 border-gray-300 border-solid shadow my-10 mx-10 relative">
      <HungryRuntimeProvider
        userId={userId}
        userChatId={userChatId}
        llmModel={selectedLLMModel}
        chatHistory={chatHistory}
        temperature={temperature}
        topP={topP}
        maxTokens={maxTokens}
        onAddUserChat={onAddUserChat}
      >
        <header className="bg-gray-100 px-3 py-2 border-2 border-gray-300 border-solid">
          <div className="flex flex-row">
            <span className="font-semibold text-lg">Playground Settings</span>
            <button
              className="w-6 h-6 flex items-center justify-center bg-green-300 text-white text-xl font-extrabold rounded-full shadow hover:bg-white hover:text-blue-300 focus:outline-none focus:ring focus:ring-blue-300 ml-4"
              aria-label="Info"
              title="Info"
              onClick={() =>
                showInfoModal({
                  title: 'Playground Info',
                  content: <PlaygroundInfo />,
                })
              }
            >
              ℹ
            </button>
          </div>
          <div className="flex flex-row items-center gap-x-6 my-4">
            <Dropdown
              label="LLM Model"
              width="300px"
              value={selectedLLMModel}
              onChange={(e) => setSelectedLLMModel(e.target.value)}
            >
              <option value="">No Filter</option>
              {llmModels.map((model) => (
                <option key={model.modelName} value={model.modelName}>
                  {model.modelName} ({model.provider})
                </option>
              ))}
            </Dropdown>

            <RangeSlider
              label="Temperature"
              min={0}
              max={1}
              step={0.05}
              initialValue={0.1}
              onChange={(nextTemp) => setTemperature(nextTemp)}
            />

            <RangeSlider
              label="Top P"
              min={0}
              max={20}
              step={1}
              initialValue={0}
              onChange={(nextTopP) => setTopP(nextTopP)}
            />

            <RangeSlider
              label="Max Tokens"
              min={0}
              max={16383}
              step={1}
              initialValue={2048}
              onChange={(nextMaxTokens) => setMaxTokens(nextMaxTokens)}
            />
          </div>
        </header>

        <div className="grid grid-cols-[25%_75%]">
          <aside className="bg-gray-100 px-3 py-2 border-2 border-gray-300 border-solid font-medium">
            <div
              className="rounded border-2 border-gray-400 border-solid w-full pointer p-2 bg-gray-200 hover:bg-gray-400"
              onClick={onNewUserChat}
            >
              + New Chat
            </div>
            {currentChats.map((userChat, idx) => {
              const actualIdx = indexOfFirstChat + idx
              const isEditing = editUserChatReq?.userChatId === userChat.id
              const isHovering = hoverUserChatId === userChat.id
              const isSelected = userChatId === userChat.id

              return (
                <div
                  className={`flex items-center justify-center rounded border-2 border-gray-400 border-solid w-full pointer p-2 my-2 ${
                    userChatId === userChat.id ? 'bg-gray-400' : 'bg-gray-200'
                  } hover:bg-gray-400`}
                  key={userChat.id}
                  onClick={onSelectUserChat(userChat)}
                  onMouseEnter={() => setHoverUserChatId(userChat.id)}
                  onMouseLeave={() => setHoverUserChatId(null)}
                >
                  <div className="mr-auto">
                    {Moment(userChat.createdAt).format('lll')}
                  </div>
                  <input
                    type="text"
                    className={`mr-auto rounded p-1 border border-1 border-solid ${
                      isSelected ? 'bg-gray-400' : 'bg-gray-200'
                    } ${
                      isHovering
                        ? `border-gray-100`
                        : isSelected
                          ? 'border-gray-400'
                          : 'border-gray-200'
                    }`}
                    value={isEditing ? editUserChatReq.title : userChat.title}
                    onChange={onEditUserChatTitle}
                  />
                  {isEditing && (
                    <button
                      className="w-6 h-6 flex items-center justify-center bg-green-300 text-white font-extrabold rounded-full shadow hover:bg-white hover:text-blue-300 focus:outline-none focus:ring focus:ring-blue-300 ml-auto"
                      aria-label="Cancel"
                      title="Cancel"
                      onClick={() => setEditUserChatReq(null)}
                    >
                      x
                    </button>
                  )}
                  {isEditing && (
                    <button
                      className="w-6 h-6 flex items-center justify-center bg-green-300 text-white font-extrabold rounded-full shadow hover:bg-white hover:text-blue-300 focus:outline-none focus:ring focus:ring-blue-300 ml-auto"
                      aria-label="Save"
                      title="Info"
                      onClick={onSaveUserChatEdit(actualIdx)}
                    >
                      ✓
                    </button>
                  )}
                  {isHovering && !isEditing && (
                    <button
                      className="w-6 h-6 flex items-center justify-center bg-green-300 text-white font-extrabold rounded-full shadow hover:bg-white hover:text-blue-300 focus:outline-none focus:ring focus:ring-blue-300 ml-auto"
                      aria-label="Archive"
                      title="Archive"
                      onClick={onArchiveUserChat(userChat, actualIdx)}
                    >
                      🗑
                    </button>
                  )}
                </div>
              )
            })}

            {/* Pagination Controls */}
            {userChats.length > chatsPerPage && (
              <div className="flex justify-between items-center mt-4 px-2">
                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  disabled={currentPage === 1}
                  className={`px-3 py-1 rounded ${
                    currentPage === 1
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                  }`}
                >
                  Previous
                </button>
                <span className="text-sm">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  disabled={currentPage === totalPages}
                  className={`px-3 py-1 rounded ${
                    currentPage === totalPages
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                  }`}
                >
                  Next
                </button>
              </div>
            )}
          </aside>

          <main className="bg-white px-3 py-2 border-2 border-gray-300 border-solid overflow-y-auto">
            <Thread />
            {userChatId && (
              <button
                onClick={async () => {
                  if (userChatId) {
                    const logs = await getChatLogs(userChatId)
                    showInfoModal({
                      title: 'Chat Logs',
                      content: <ChatLogsDisplay logs={logs} />,
                    })
                  }
                }}
                className="sticky bottom-16 right-8 w-12 h-12 flex items-center justify-center bg-blue-500 text-white rounded-full shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors ml-auto mr-4"
                title="Fetch Chat Logs"
              >
                🔍
              </button>
            )}
          </main>
        </div>
      </HungryRuntimeProvider>
    </div>
  )
}

export default PlaygroundPage
