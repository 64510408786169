import React, { useState, useEffect, useRef } from 'react'
import Moment from 'moment-timezone'

import { AutocompleteInput } from '@containers/common/form'
import { DateInput } from '@components/common/form'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import LoadingIndicator from '@components/common/LoadingIndicator'
import { AutoBatchPeriods } from '../../../constants'
import { InvoicesTable } from './InvoicesTable'
import {
  useInvoiceSearch,
  Invoice,
  SearchInvoicesParams,
  InvoiceStatus,
} from './UseInvoiceSearch'
import {
  useUnpaidOrders,
  Order,
  GetUnpaidOrdersParams,
  UnpaidOrderStatus,
  AllUnpaidOrderStatuses,
} from './UseUnpaidOrders'
import { formatNumberWithCommas } from '@utils'

// Types

interface Client {
  id: string
  name: string
  pin: string
}

interface AccountingClient extends Client {
  autoBatchActive: boolean
  autoBatchPeriod: number
  netPaymentDays: number
}

interface ClientARManagementProps {
  invoiceUrl: string
  getAccountingClient: (accountId: string) => Promise<AccountingClient | null>
  getInvoiceHistory: (id: string, invoiceNumber: string) => void
  getUnpaidOrders: (params: GetUnpaidOrdersParams) => Promise<Order[]>
  searchClients: (params: any) => Promise<Client[]> // TODO: Define proper search params type
  searchInvoices: (params: SearchInvoicesParams) => Promise<Invoice[]>
  onViewPDF: (invoice: Invoice, isSummary: boolean) => () => void
  onEditInvoice: (id: string) => () => void
  updatedInvoice?: Invoice
}

const invoicesPerPage = 50
const ordersPerPage = 50

// Define AutoBatchPeriods type
type AutoBatchPeriodsType = {
  [key: number]: string
}

const AutoBatchPeriodsTyped = AutoBatchPeriods as AutoBatchPeriodsType

const ClientARManagement: React.FC<ClientARManagementProps> = ({
  invoiceUrl,
  updatedInvoice,
  getAccountingClient,
  getInvoiceHistory,
  getUnpaidOrders,
  searchClients,
  searchInvoices,
  onEditInvoice,
  onViewPDF,
}) => {
  // Break down state into individual hooks
  const [client, setClient] = useState<Client | undefined>(undefined)
  const [accountingClient, setAccountingClient] = useState<
    AccountingClient | undefined
  >(undefined)
  const [openActionPopoverId, setOpenActionPopoverId] = useState<string | null>(
    null,
  )

  // Move hiddenColumns state to the root component
  const [hiddenOrderColumns, setHiddenOrderColumns] = useState<string[]>([
    'effectiveDate',
    'dueDate',
    'totalDue',
    'totalPaid',
    'totalUnpaid',
  ])

  // Define a single source of truth for order columns
  const orderColumns = [
    {
      id: 'orderNumber' as keyof Order,
      label: 'Order Number',
      sortKey: 'orderNumber' as keyof Order,
      renderCell: (order: Order) => order.orderNumber,
    },
    {
      id: 'date' as keyof Order,
      label: 'Event Date',
      sortKey: 'date' as keyof Order,
      sortTransform: (date: any) => Moment(date).unix(),
      renderCell: (order: Order) => Moment(order.date).format('l'),
    },
    {
      id: 'effectiveDate' as keyof Order,
      label: 'Eff. Date',
      sortKey: 'effectiveDate' as keyof Order,
      sortTransform: (date: any) => Moment(date).unix(),
      renderCell: (order: Order) => Moment(order.effectiveDate).format('l'),
    },
    {
      id: 'dueDate' as keyof Order,
      label: 'Due Date',
      sortKey: 'dueDate' as keyof Order,
      sortTransform: (date: any) => Moment(date).unix(),
      renderCell: (order: Order) => Moment(order.dueDate).format('l'),
    },
    {
      id: 'totalDue' as keyof Order,
      label: 'Total',
      sortKey: 'totalDue' as keyof Order,
      sortTransform: (balance: any) => parseFloat(balance),
      renderCell: (order: Order) =>
        `$${formatNumberWithCommas(order.totalDue)}`,
    },
    {
      id: 'totalPaid' as keyof Order,
      label: 'Total Paid',
      sortKey: 'totalPaid' as keyof Order,
      sortTransform: (balance: any) => parseFloat(balance),
      renderCell: (order: Order) =>
        `$${formatNumberWithCommas(order.totalPaid)}`,
    },
    {
      id: 'totalUnpaid' as keyof Order,
      label: 'Total Unpaid',
      sortKey: 'totalUnpaid' as keyof Order,
      sortTransform: (balance: any) => parseFloat(balance),
      renderCell: (order: Order) =>
        `$${formatNumberWithCommas(order.totalUnpaid)}`,
    },
    {
      id: 'status' as keyof Order,
      label: 'Status',
      sortKey: 'status' as keyof Order,
      renderCell: (order: Order) => (
        <div
          className={`px-2 py-1 rounded flex items-center ${getStatusBgColor(
            order.status,
          )}`}
        >
          {order.status}
        </div>
      ),
    },
    {
      id: 'numBatchInvoices' as keyof Order,
      label: '# Batches',
      sortKey: 'numBatchInvoices' as keyof Order,
      renderCell: (
        order: Order,
        { showBatchInvoicesTooltip, setShowBatchInvoicesTooltip }: any,
      ) => (
        <div className="relative ml-1 inline-block">
          <div
            className="bg-blue-200 font-medium px-2 py-1 rounded flex items-center shadow cursor-pointer"
            onMouseEnter={() => {
              if (order.batchInvoices.length > 0) {
                setShowBatchInvoicesTooltip(order.id)
              }
            }}
            onMouseLeave={() => {
              setShowBatchInvoicesTooltip(undefined)
            }}
          >
            {order.numBatchInvoices}
          </div>
          {showBatchInvoicesTooltip === order.id && (
            <div
              className="absolute bottom-full left-0 mb-1 bg-gray-800 text-white text-xs rounded py-1 px-2 w-24 shadow-lg z-50 normal-case"
              style={{ marginBottom: '8px', marginLeft: '-20px' }}
            >
              {order.batchInvoices.map((invoice, index) => (
                <React.Fragment key={index}>
                  {invoice}
                  {index < order.batchInvoices.length - 1 && <br />}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      ),
    },
  ]

  // Helper function for status background colors
  const getStatusBgColor = (status: UnpaidOrderStatus) => {
    switch (status) {
      case UnpaidOrderStatus.OverInvoiced:
        return 'bg-red-400'
      case UnpaidOrderStatus.UnpaidBatch:
        return 'bg-blue-400'
      case UnpaidOrderStatus.NeedsBatch:
        return 'bg-yellow-400'
      default:
        return 'bg-gray-300'
    }
  }

  // Add toggleOrderColumnVisibility function
  const toggleOrderColumnVisibility = (columnId: string) => {
    setHiddenOrderColumns((prev) =>
      prev.includes(columnId)
        ? prev.filter((col) => col !== columnId)
        : [...prev, columnId],
    )
  }

  // Use the custom hook for invoice search
  const {
    pageInvoices,
    totalFilteredInvoices,
    search: invoicesSearchText,
    statuses: invoicesStatuses,
    excludeZeroDue,
    excludeAllPaidOrders,
    serviceDateStartFrom,
    serviceDateStartTo,
    serviceDateEndFrom,
    serviceDateEndTo,
    setSearch: setInvoicesSearchText,
    setStatuses: setInvoicesStatuses,
    setExcludeZeroDue,
    setExcludeAllPaidOrders,
    setServiceDateStartFrom,
    setServiceDateStartTo,
    setServiceDateEndFrom,
    setServiceDateEndTo,
    pageNum: invoicesPageNum,
    setPageNum: setInvoicesPageNum,
    executeSearchInvoices,
    resetSearch: resetInvoicesSearch,
    sortInvoices,
    sortByIcon: invoicesSortByIcon,
    onUpdateInvoiceInState,
    loadingInvoices,
  } = useInvoiceSearch({
    searchInvoices,
    clientId: client?.id,
    invoicesPerPage,
  })

  // Use the custom hook for unpaid orders
  const {
    pageOrders,
    search: ordersSearchText,
    searchFrom: ordersSearchFrom,
    searchTo: ordersSearchTo,
    statuses: ordersStatuses,
    setSearch: setOrdersSearchText,
    setSearchFrom: setOrdersSearchFrom,
    setSearchTo: setOrdersSearchTo,
    setStatuses: setOrdersStatuses,
    setPageNum: setOrdersPageNum,
    executeFilterOrders,
    resetSearch: resetOrdersSearch,
    sortOrders,
    sortByIcon: ordersSortByIcon,
    totalFilteredOrders,
    isLoading: ordersLoading,
    pageNum: ordersPageNum,
    loadUnpaidOrders,
  } = useUnpaidOrders({
    getUnpaidOrders,
    clientId: client?.id,
    ordersPerPage,
  })

  // Close popover when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Only close the popover if one is open and the click is outside the popover
      if (
        openActionPopoverId &&
        !(event.target as Element).closest('.action-popover-container')
      ) {
        setOpenActionPopoverId(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [openActionPopoverId])

  useEffect(() => {
    if (updatedInvoice) {
      onUpdateInvoiceInState(updatedInvoice)
      // only fetch orders that have been effected by the updated invoice
      const orderIds = (updatedInvoice.payments || []).reduce(
        (acc, payment) => {
          if (payment.paymentFor) {
            acc[payment.paymentFor.id] = true
          }

          return acc
        },
        {} as Record<string, boolean>,
      )
      if (Object.keys(orderIds).length > 0) {
        loadUnpaidOrders(Object.keys(orderIds))
      }
    }
  }, [updatedInvoice, onUpdateInvoiceInState, loadUnpaidOrders])

  // Handlers
  const resetPage = () => {
    resetInvoicesSearch()
    resetOrdersSearch()
  }

  const handleClientSelect = async (selectedClient: Client) => {
    setClient(selectedClient)
    resetPage()

    executeSearchInvoices()

    const newAccountingClient = await getAccountingClient(selectedClient.id)
    if (newAccountingClient) {
      setAccountingClient(newAccountingClient)
    }
  }

  const handleClearClient = () => {
    setClient(undefined)
    setAccountingClient(undefined)
    resetInvoicesSearch()
    resetOrdersSearch()
  }

  // Orders Handlers

  const handleClearOrdersSearchFrom = () => {
    setOrdersSearchFrom(undefined)
    setOrdersPageNum(1)
    executeFilterOrders()
  }

  const handleClearOrdersSearchTo = () => {
    setOrdersSearchTo(undefined)
    setOrdersPageNum(1)
    executeFilterOrders()
  }

  const ClientProfilePanel: React.FC = () => {
    if (!accountingClient) {
      return null
    }

    return (
      <div className="mb-3">
        <div className="bg-white rounded-lg shadow p-3">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
            <div className="flex flex-col">
              <span className="text-xs font-semibold underline">Name</span>
              <span className="font-medium">{`${accountingClient.name} (${accountingClient.pin})`}</span>
            </div>
            <div className="flex flex-col">
              <span className="text-xs font-semibold underline">
                Auto Batch Invoice?
              </span>
              <span className="font-medium">
                {accountingClient.autoBatchActive ? 'Yes' : 'No'}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-xs font-semibold underline">
                Invoicing Cadence
              </span>
              <span className="font-medium">{`${
                AutoBatchPeriodsTyped[accountingClient.autoBatchPeriod || 0]
              }`}</span>
            </div>
            <div className="flex flex-col">
              <span className="text-xs font-semibold underline">
                Payment Terms
              </span>
              <span className="font-medium">{`${accountingClient.netPaymentDays} days`}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // Mutating the invoice search text causes a re-render of this component and is a problem
  // for the search text input because it causes the input to lose focus. To fix this issue
  // we use a local state to manage the input value and update the parent state when the
  // enter key is pressed or search button is clicked.
  const InvoiceSearchControls: React.FC = () => {
    // Add local state to manage input value
    const [showSearchInvoicesTooltip, setShowSearchInvoicesTooltip] =
      useState(false)
    const [localInvoicesSearchText, setLocalInvoicesSearchText] =
      useState(invoicesSearchText)

    // Handle input change without immediately updating the parent state
    const handleSearchInputChange = (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      setLocalInvoicesSearchText(e.target.value)
    }

    // Handle Enter key press
    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        setInvoicesSearchText(localInvoicesSearchText)
      }
    }

    // Keep local state in sync with parent state
    useEffect(() => {
      setLocalInvoicesSearchText(invoicesSearchText)
    }, [])

    const toggleStatus = (status: InvoiceStatus) => {
      const newStatuses = invoicesStatuses.includes(status)
        ? invoicesStatuses.filter((s) => s !== status)
        : [...invoicesStatuses, status]

      // Ensure we have at least one status selected
      setInvoicesStatuses(
        newStatuses.length > 0 ? newStatuses : [InvoiceStatus.Unpaid],
      )
    }

    const removeStatus = (status: InvoiceStatus) => {
      const newStatuses = invoicesStatuses.filter((s) => s !== status)
      // Ensure we have at least one status selected
      setInvoicesStatuses(
        newStatuses.length > 0 ? newStatuses : [InvoiceStatus.Unpaid],
      )
    }

    const onSearchInvoicesAndUpdateSearchText = () => {
      setInvoicesSearchText(localInvoicesSearchText)
      executeSearchInvoices()
    }

    return (
      <div className="flex flex-col bg-white rounded-lg py-2 px-3 ml-4 shadow-md h-full">
        <div className="flex flex-row flex-wrap">
          <XSpacing width="10px" />

          <div className="flex flex-col">
            <div className="flex items-center mb-2">
              <input
                type="checkbox"
                id="excludeZeroDue"
                checked={excludeZeroDue}
                onChange={(e) => setExcludeZeroDue(e.target.checked)}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label
                htmlFor="excludeZeroDue"
                className="ml-2 text-sm text-gray-700"
              >
                Hide Zero Due
              </label>
            </div>

            <div className="flex items-center mb-2">
              <input
                type="checkbox"
                id="excludeAllPaidOrders"
                checked={excludeAllPaidOrders}
                onChange={(e) => setExcludeAllPaidOrders(e.target.checked)}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label
                htmlFor="excludeAllPaidOrders"
                className="ml-2 text-sm text-gray-700"
              >
                Hide All Orders Paid
              </label>
            </div>
          </div>

          <div className="flex flex-col ml-2">
            <label className="text-xs font-semibold uppercase">
              Service Date Start
            </label>
            <DateInput
              width="200px"
              label="From"
              date={serviceDateStartFrom}
              dateFormat="default"
              isStatic={false}
              onChange={setServiceDateStartFrom}
              clearDate={() => setServiceDateStartFrom(undefined)}
            />

            <DateInput
              width="200px"
              label="To"
              date={serviceDateStartTo}
              dateFormat="default"
              isStatic={false}
              onChange={(date: Moment.Moment) =>
                setServiceDateStartTo(date.endOf('day'))
              }
              clearDate={() => setServiceDateStartTo(undefined)}
            />
          </div>

          <div className="flex flex-col ml-2">
            <label className="text-xs font-semibold uppercase">
              Service Date End
            </label>
            <DateInput
              width="200px"
              label="From"
              date={serviceDateEndFrom}
              dateFormat="default"
              isStatic={false}
              onChange={setServiceDateEndFrom}
              clearDate={() => setServiceDateEndFrom(undefined)}
            />

            <DateInput
              width="200px"
              label="To"
              date={serviceDateEndTo}
              dateFormat="default"
              isStatic={false}
              onChange={(date: Moment.Moment) =>
                setServiceDateEndTo(date.endOf('day'))
              }
              clearDate={() => setServiceDateEndTo(undefined)}
            />
          </div>

          <div className="ml-2">
            <label className="block text-xs uppercase font-semibold text-gray-700 flex items-center">
              Search Invoice Text
              <div className="relative ml-1 inline-block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-gray-500 cursor-help pointer-cursor"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  onMouseEnter={() => setShowSearchInvoicesTooltip(true)}
                  onMouseLeave={() => setShowSearchInvoicesTooltip(false)}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                {showSearchInvoicesTooltip && (
                  <div
                    className="absolute bottom-full left-0 mb-1 bg-gray-800 text-white text-xs rounded py-1 px-2 w-64 shadow-lg z-50 normal-case"
                    style={{ marginBottom: '8px', marginLeft: '-20px' }}
                  >
                    Search by invoice number or order number on the invoice
                  </div>
                )}
              </div>
            </label>
            <input
              className="border border-black border-solid rounded px-3 py-2 w-[200px]"
              type="text"
              value={localInvoicesSearchText}
              onChange={handleSearchInputChange}
              onKeyDown={handleKeyDown}
            />
          </div>

          <div className="ml-2">
            <label className="block text-xs uppercase font-semibold text-gray-700">
              Statuses
            </label>
            <div className="relative">
              <select
                className="border border-black border-solid rounded px-3 py-3 w-[200px]"
                value=""
                onChange={(e) => {
                  const value = e.target.value as InvoiceStatus
                  if (value) {
                    toggleStatus(value)
                  }
                  // Reset the select to empty value after selection
                  e.target.value = ''
                }}
              >
                <option value="" disabled>
                  Select status...
                </option>
                <option value={InvoiceStatus.Unpaid}>Unpaid</option>
                <option value={InvoiceStatus.Paid}>Paid</option>
                <option value={InvoiceStatus.Resolved}>Resolved</option>
              </select>
            </div>

            {/* Status Pills */}
            <div className="flex flex-wrap mt-2 gap-1">
              {invoicesStatuses.map((status) => (
                <div
                  key={status}
                  className="bg-blue-300 text-blue-800 text-xs font-medium px-2 py-1 rounded flex items-center shadow"
                >
                  {status}
                  <button
                    className="ml-1 text-blue-800 hover:text-blue-900"
                    onClick={() => removeStatus(status)}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="ml-auto self-center">
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded text-xs shadow"
              onClick={onSearchInvoicesAndUpdateSearchText}
            >
              Search Invoices
            </button>
          </div>
        </div>

        <PaginationControls
          pageNum={invoicesPageNum}
          setPageNum={setInvoicesPageNum}
          resultsPerPage={invoicesPerPage}
          currNumResults={pageInvoices.length}
        />

        <div className="mt-4 font-semibold">
          * Depending on your screen size you can also zoom out to see more
          table columns
        </div>
      </div>
    )
  }

  // Mutating the orders search text causes a re-render of this component and is a problem
  // for the search text input because it causes the input to lose focus. To fix this issue
  // we use a local state to manage the input value and update the parent state on delay
  const OrdersSearchControls: React.FC = () => {
    // Add local state to manage input value
    const [localSearchText, setLocalSearchText] = useState(ordersSearchText)
    // Add ref for timeout
    const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null)

    // Handle input change without immediately updating the parent state
    const handleSearchInputChange = (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      setLocalSearchText(e.target.value)

      // Clear any existing timeout
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current)
      }

      // Set a new timeout to update the parent state and execute search
      searchTimeoutRef.current = setTimeout(() => {
        setOrdersSearchText(e.target.value)
      }, 700)
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        setOrdersSearchText(localSearchText)
      }
    }

    // Keep local state in sync with parent state
    useEffect(() => {
      setLocalSearchText(ordersSearchText)
    }, [])

    // Clean up timeout on unmount
    useEffect(() => {
      return () => {
        if (searchTimeoutRef.current) {
          clearTimeout(searchTimeoutRef.current)
        }
      }
    }, [])

    // Handle column selection change
    const handleColumnSelectChange = (
      e: React.ChangeEvent<HTMLSelectElement>,
    ) => {
      const columnId = e.target.value as keyof Order
      toggleOrderColumnVisibility(columnId)
      // Reset the select to default option after selection
      e.target.value = ''
    }

    // Add back status filter functionality
    const toggleStatus = (status: UnpaidOrderStatus) => {
      const newStatuses = ordersStatuses.includes(status)
        ? ordersStatuses.filter((s) => s !== status)
        : [...ordersStatuses, status]

      // Ensure we have at least one status selected
      setOrdersStatuses(
        newStatuses.length > 0 ? newStatuses : AllUnpaidOrderStatuses,
      )
    }

    const removeStatus = (status: UnpaidOrderStatus) => {
      const newStatuses = ordersStatuses.filter((s) => s !== status)
      // Ensure we have at least one status selected
      setOrdersStatuses(
        newStatuses.length > 0 ? newStatuses : AllUnpaidOrderStatuses,
      )
    }

    return (
      <div className="flex flex-col bg-white rounded-lg py-2 px-3 shadow-md h-full">
        <div className="flex flex-row flex-wrap gap-4 items-center mb-4">
          <div className="m-2">
            <DateInput
              width="200px"
              label="From"
              date={ordersSearchFrom}
              dateFormat="default"
              isStatic={false}
              onChange={setOrdersSearchFrom}
              clearDate={handleClearOrdersSearchFrom}
            />
          </div>

          <div className="m-2">
            <DateInput
              width="200px"
              label="To"
              date={ordersSearchTo}
              dateFormat="default"
              isStatic={false}
              onChange={setOrdersSearchTo}
              clearDate={handleClearOrdersSearchTo}
            />
          </div>

          <div className="m-2">
            <label className="block text-xs uppercase font-semibold text-gray-700 flex items-center">
              Search Order Text
            </label>
            <input
              className="border border-black border-solid rounded px-3 py-2 w-[200px]"
              type="text"
              value={localSearchText}
              onChange={handleSearchInputChange}
              onKeyDown={handleKeyDown}
            />
          </div>

          {/* Column visibility select */}
          <div className="m-2">
            <label className="block text-xs uppercase font-semibold text-gray-700 mb-1">
              Manage Columns
            </label>
            <select
              className="form-select block w-48 px-3 py-2 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              onChange={handleColumnSelectChange}
              value=""
            >
              <option value="" disabled>
                Select column...
              </option>
              <optgroup label="Show Column">
                {orderColumns
                  .filter((column) => hiddenOrderColumns.includes(column.id))
                  .map((column) => (
                    <option key={`show-${column.id}`} value={column.id}>
                      Show: {column.label}
                    </option>
                  ))}
              </optgroup>
              <optgroup label="Hide Column">
                {orderColumns
                  .filter((column) => !hiddenOrderColumns.includes(column.id))
                  .map((column) => (
                    <option key={`hide-${column.id}`} value={column.id}>
                      Hide: {column.label}
                    </option>
                  ))}
              </optgroup>
            </select>
          </div>

          {/* Status filter */}
          <div className="m-2">
            <label className="block text-xs uppercase font-semibold text-gray-700 mb-1">
              Statuses
            </label>
            <select
              className="form-select block w-48 px-3 py-2 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value=""
              onChange={(e) => {
                const value = e.target.value as UnpaidOrderStatus
                if (value) {
                  toggleStatus(value)
                }
                // Reset the select to empty value after selection
                e.target.value = ''
              }}
            >
              <option value="" disabled>
                Select status...
              </option>
              <option value={UnpaidOrderStatus.NeedsBatch}>Needs Batch</option>
              <option value={UnpaidOrderStatus.UnpaidBatch}>
                Unpaid Batch
              </option>
              <option value={UnpaidOrderStatus.OverInvoiced}>
                Over Invoiced
              </option>
            </select>
          </div>
        </div>

        <div className="flex flex-row flex-wrap gap-2 mb-4">
          {/* Status Pills */}
          <div className="flex flex-wrap gap-2 items-center ml-4">
            {ordersStatuses.length < AllUnpaidOrderStatuses.length && (
              <span className="text-xs font-semibold text-gray-700 mr-2">
                Status Filters:
              </span>
            )}
            {ordersStatuses.map((status) => (
              <div
                key={status}
                className="bg-blue-300 text-blue-800 text-xs font-medium px-2 py-1 rounded-full flex items-center"
              >
                {status}
                <button
                  className="ml-1 text-blue-800 hover:text-blue-900 focus:outline-none"
                  onClick={() => removeStatus(status)}
                  title="Remove status filter"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        </div>

        <PaginationControls
          pageNum={ordersPageNum}
          setPageNum={setOrdersPageNum}
          resultsPerPage={ordersPerPage}
          currNumResults={pageOrders.length}
        />
      </div>
    )
  }

  const PaginationControls = ({
    pageNum,
    setPageNum,
    resultsPerPage,
    currNumResults,
  }: {
    pageNum: number
    setPageNum: (p: number) => void
    resultsPerPage: number
    currNumResults: number
  }) => {
    const hasMore = currNumResults == resultsPerPage

    return (
      <div className="flex items-end text-xs">
        <div className="flex">Page {pageNum}</div>
        <div className="w-5"></div>
        {pageNum > 2 && (
          <>
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded text-xs"
              onClick={() => setPageNum(1)}
            >
              &lt;&lt;
            </button>
            <div className="w-1"></div>
          </>
        )}
        {pageNum > 1 && (
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded text-xs"
            onClick={() => setPageNum(pageNum - 1)}
          >
            &lt;
          </button>
        )}
        <div className="w-2"></div>
        {hasMore && (
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded text-xs"
            onClick={() => setPageNum(pageNum + 1)}
          >
            &gt;
          </button>
        )}
      </div>
    )
  }

  const OutstandingOrdersTable: React.FC = () => {
    const [showBatchInvoicesTooltip, setShowBatchInvoicesTooltip] = useState<
      string | undefined
    >(undefined)

    return (
      <div style={{ flex: '1 1 40%' }} className="mt-4">
        <div
          className="relative border-2 border-gray-400 border-solid rounded-lg mx-2"
          style={{ height: '100vh' }}
        >
          {ordersLoading && (
            <div className="flex justify-center items-center h-full">
              <LoadingIndicator />
            </div>
          )}
          {!ordersLoading && (
            <div className="absolute inset-0 overflow-auto">
              <table className="w-full">
                <thead>
                  <tr>
                    {orderColumns.map(
                      (column) =>
                        !hiddenOrderColumns.includes(column.id) && (
                          <th
                            key={column.id}
                            className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10"
                            onClick={() =>
                              column.sortTransform
                                ? sortOrders(
                                    column.sortKey,
                                    column.sortTransform,
                                  )
                                : sortOrders(column.sortKey)
                            }
                          >
                            <div className="flex items-center justify-between">
                              <span>
                                {column.label}{' '}
                                {ordersSortByIcon(column.sortKey)}
                              </span>
                            </div>
                          </th>
                        ),
                    )}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {pageOrders.map((order) => (
                    <tr key={order.id} className="hover:bg-gray-50">
                      {orderColumns.map(
                        (column) =>
                          !hiddenOrderColumns.includes(column.id) && (
                            <td
                              key={column.id}
                              className="px-4 py-2 whitespace-nowrap text-sm"
                            >
                              {column.renderCell(order, {
                                showBatchInvoicesTooltip,
                                setShowBatchInvoicesTooltip,
                              })}
                            </td>
                          ),
                      )}
                    </tr>
                  ))}
                  {pageOrders.length === 0 && !ordersLoading && (
                    <tr>
                      <td
                        colSpan={
                          orderColumns.length - hiddenOrderColumns.length
                        }
                        className="px-4 py-4 text-center text-gray-500"
                      >
                        No unpaid orders found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="flex w-1/2 p-4">
        <AutocompleteInput
          label="Select Client"
          width="75%"
          displayAttribute="name"
          loaderFunction={(search) => searchClients({ ...search })}
          placeholder="Client Name"
          value={(client && client.name) || ''}
          onSelect={handleClientSelect}
          onClear={handleClearClient}
        />
      </div>

      {client && (
        <>
          <YSpacing height="20px" />
          <ClientProfilePanel />
          <YSpacing height="20px" />

          <div className="grid grid-cols-3 gap-1">
            {/* Unpaid Orders Filters */}
            <div className="flex flex-col col-span-1">
              <h3 className="my-2 font-semibold underline">
                Unpaid Orders ({totalFilteredOrders})
              </h3>
              <OrdersSearchControls />
            </div>

            {/* Invoices Filters */}
            <div className="flex flex-col col-span-2">
              <h3 className="my-2 font-semibold underline">
                Invoices ({totalFilteredInvoices})
              </h3>
              <InvoiceSearchControls />
            </div>

            <div className="relative col-span-1">
              <OutstandingOrdersTable />
            </div>

            <div className="relative col-span-2">
              <InvoicesTable
                invoiceUrl={invoiceUrl}
                invoices={pageInvoices}
                loadingInvoices={loadingInvoices}
                hideClientColumn={true}
                sortInvoices={sortInvoices}
                invoicesSortByIcon={invoicesSortByIcon}
                editInvoice={onEditInvoice}
                onViewPDF={onViewPDF}
                getInvoiceHistory={getInvoiceHistory}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ClientARManagement
