import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { withRouter } from 'react-router'
import Modal from '@components/common/modal/Modal'
import { Button, LinkText, Input, DateInput } from '@components/common/form'
import LoadingIndicator from '@components/common/LoadingIndicator'
import { BiCheck, BiX } from 'react-icons/bi'
import { orderBy, debounce, mapKeys } from 'lodash'
import Moment from 'moment-timezone'

const openPathInNewTab = (path) => {
  const { origin } = window.location
  const url = `${origin}${path}`
  window.open(url, '_blank')
}

// Prevent user from searching / selecting any orders that haven't already finished
// const maxDate = Moment().subtract( 1, 'day' )
// Tmp change to all e2e testing
const maxDate = Moment().add(1, 'week')

const initialState = {
  saveReqLoading: false,
  searchReqLoading: false,
  selectedGroupOrderIds: [],
  groupOrders: {},
  visibleGroupOrderIds: [],
  search: '',
  to: Moment(maxDate),
  from: Moment(maxDate).subtract(1, 'month'),
}

class GroupOrderInvoiceModal extends Component {
  state = { ...initialState }

  debouncedSearch = debounce(() => this.searchGroupOrders(), 550)

  componentDidMount() {
    this.searchGroupOrders()
  }

  getClientSettingsById = (clientSettingsId) => {
    const { clientSettingsCollection } = this.props
    if (!clientSettingsCollection) {
      return undefined
    }

    return clientSettingsCollection.find((cs) => cs.id === clientSettingsId)
  }

  getVisibleGroupOrders() {
    const { groupOrders, visibleGroupOrderIds } = this.state
    const visible = visibleGroupOrderIds
      .map((id) => groupOrders[id])
      // Rm possible undefined values
      .filter(Boolean)
    // Always sort by event date
    const sorted = orderBy(visible, ['date'], ['desc'])

    return sorted
  }

  getSelectedGroupOrders() {
    const { groupOrders, selectedGroupOrderIds } = this.state
    // TODO: removing undefined values is possibly misleading to end user
    // since the ids are still in the selectedGroupOrderIds list and will be
    // submitted to api
    const selected = selectedGroupOrderIds
      .map((id) => groupOrders[id])
      // Rm possible undefined values
      .filter(Boolean)

    return selected
  }

  searchGroupOrders = async () => {
    const { clientId, onSearchGroupOrders } = this.props
    const { to, from, search } = this.state
    const searchMinimumMet = search.length > 5
    // If no search params provided by user, return
    if (!(to || from || searchMinimumMet)) {
      return
    }
    this.setState({ searchReqLoading: true })
    // TODO: params should also include a statuses or headcount filter so that
    // only Go's that have active suborders are returned
    const params = {
      start: from,
      end: to,
      search,
      clientId,
      offset: 0,
      // TODO: maybe lower limit and add true pagination handling here
      limit: 100,
    }
    const results = await onSearchGroupOrders(params)
    this.setState({ searchReqLoading: false })
    if (results) {
      const nextGroupOrders = {
        ...this.state.groupOrders,
        ...mapKeys(results, 'id'),
      }
      const nextVisibleGroupOrderIds = results.map((order) => order.id)
      this.setState({
        groupOrders: nextGroupOrders,
        visibleGroupOrderIds: nextVisibleGroupOrderIds,
      })
    }
  }

  onHide = () => this.props.closeModal()

  onSelectGroupOrder = (id) => {
    const nextIds = this.state.selectedGroupOrderIds.concat([id])
    const unique = Array.from(new Set(nextIds))
    this.setState({ selectedGroupOrderIds: unique })
  }

  onRemoveGroupOrder = (id) => {
    const nextIds = this.state.selectedGroupOrderIds.filter(
      (orderId) => orderId !== id,
    )
    this.setState({ selectedGroupOrderIds: nextIds })
  }

  handleClickOrder = (orderId) => {
    const { selectedGroupOrderIds } = this.state
    if (selectedGroupOrderIds.includes(orderId)) {
      this.setState({
        selectedGroupOrderIds: selectedGroupOrderIds.filter(
          (id) => id !== orderId,
        ),
      })
    } else {
      this.setState({
        selectedGroupOrderIds: selectedGroupOrderIds.concat([orderId]),
      })
    }
  }

  save = async () => {
    const { clientId, flashError } = this.props
    const { selectedGroupOrderIds } = this.state

    if (!selectedGroupOrderIds.length) {
      flashError('Please select at least one order')

      return
    }

    this.setState({ saveReqLoading: true })
    const result = await this.props.onPrepareGroupOrderInvoice(
      selectedGroupOrderIds,
    )
    this.setState({ saveReqLoading: false })

    if (result && result.invoiceOrderIds && result.invoiceOrderIds.length) {
      const orderIdsString = result.invoiceOrderIds.join(',')
      const nextPath = `/invoices?ar_client_id=${clientId}&order_ids=${orderIdsString}`
      openPathInNewTab(nextPath)
      this.onHide()
    }
  }

  renderSearchBar() {
    const { to, from, search } = this.state

    return (
      <div className="search-bar">
        <div className="search-element">
          <DateInput
            label="From"
            date={from}
            dateFormat="default"
            maxDate={maxDate}
            isStatic={false}
            onChange={(from) => this.setState({ from }, this.searchGroupOrders)}
            clearDate={() =>
              this.setState({ from: undefined }, this.searchGroupOrders)
            }
          />
        </div>
        <div className="search-element">
          <DateInput
            label="To"
            date={to}
            dateFormat="default"
            maxDate={maxDate}
            isStatic={false}
            onChange={(to) => {
              if (to) {
                this.setState({ to: to.endOf('day') }, this.searchGroupOrders)
              }
            }}
            clearDate={() =>
              this.setState({ to: undefined }, this.searchGroupOrders)
            }
          />
        </div>
        <div className="search-element">
          <Input
            label="Order Number"
            marginBottom="0"
            type="text"
            value={search}
            onChange={(e) =>
              this.setState({ search: e.target.value }, this.debouncedSearch)
            }
          />
        </div>
      </div>
    )
  }

  renderGroupOrder(order, selected, listContainer) {
    const selectedListItem = listContainer === 'selected'
    const cls = selectedListItem ? 'selected-item' : 'group-order-item'
    const clientSettings = this.getClientSettingsById(order.clientSettingsId)

    return (
      <button
        key={order.id}
        className={`${cls} ${selected ? 'selected' : ''}`}
        onClick={() => this.handleClickOrder(order.id)}
      >
        <div className="item-left">
          <div className="order-number">{order.number}</div>
          <div className="order-date">
            {moment(order.date).format('MM-DD-YYYY')}
          </div>
          <div className="order-location">
            {clientSettings ? clientSettings.name : 'Unknown location'}
          </div>
          <div className="suborders-count">
            {order.headCount} active order(s)
          </div>
        </div>
        <div className="item-right">
          <div className="check-container">
            {selectedListItem ? <BiX /> : <BiCheck />}
          </div>
        </div>
      </button>
    )
  }

  renderContent() {
    const { selectedGroupOrderIds, searchReqLoading } = this.state

    return (
      <div className="group-order-invoice-modal">
        <div className="search-bar-container">{this.renderSearchBar()}</div>
        <div className="primary-container">
          <div className="search-results-container">
            <div className="list-header results-header">
              <div className="header-text">Search results:</div>
              <div className="search-loading">
                {searchReqLoading && <LoadingIndicator />}
              </div>
            </div>
            <div className="search-results">
              {this.getVisibleGroupOrders().map((order) => {
                const selected = selectedGroupOrderIds.includes(order.id)

                return this.renderGroupOrder(order, selected)
              })}
            </div>
          </div>

          <div className="selected-container">
            <div className="list-header selected-header">
              <div className="header-text">Selected:</div>
            </div>
            <div className="selected-items">
              {this.getSelectedGroupOrders().map((order) => {
                return this.renderGroupOrder(order, true, 'selected')
              })}
            </div>
          </div>
        </div>
        <div className="footer-container">
          <div className="buttons-container">
            <LinkText label="Cancel" onClick={this.onHide} />
            <Button
              label="Generate invoice proposal"
              onClick={this.save}
              disabled={this.state.saveReqLoading}
              className="save-button"
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const title = 'Prepare Group Order Invoice'

    return (
      <Modal
        title={title}
        hideModal={this.onHide}
        color="#001940"
        maxWidth="800px"
        width="800px"
        className="go-invoice-modal-outer"
        innerClassName="go-invoice-modal-inner"
        includePadding={false}
      >
        {this.renderContent()}
      </Modal>
    )
  }
}

GroupOrderInvoiceModal.propTypes = {
  onPrepareGroupOrderInvoice: PropTypes.func,
  onSearchGroupOrders: PropTypes.func,
  clientId: PropTypes.string,
  clientSettingsCollection: PropTypes.array,
  flashError: PropTypes.func,
  closeModal: PropTypes.func,
}

export default withRouter(GroupOrderInvoiceModal)
