import { connect } from 'react-redux'

import { EditMenuItemModal } from '@components/chef/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { copyMenuItemModal, editMenuItem, editMenuItemModal, chef, user } =
    state

  const { chefType } = chef
  const { show } = editMenuItemModal
  const errors = state.errors.menuItemModal || {}

  return {
    isCopy: copyMenuItemModal.show,
    errors,
    menuItem: editMenuItem,
    show,
    chefType,
    user,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, OrderService, RestService, SettingsService, UIService } =
    services
  const {
    pStateToReduxMenuItem,
    pDefaultChildMenuFields,
    pToMenuItemVerifications,
  } = presenters
  const {
    pRequestSettings,
    pResponseMenuItem,
    pResponseMenuItems,
    pSerializeLoadMenuItemsParams,
    pResponseSettings,
    pRequestUpdateMarketplaceAddOn,
    pRequestUpdateMenuItem,
    pRequestUpdateItemImages,
    pResponseChefs,
  } = presenters.Api
  const {
    CloseChildMenuItemModal,
    DeleteMenuItem,
    DeleteChildMenuItem,
    EditChildMenuItem,
    EditMenuItem,
    LoadChefMenuItems,
    LoadSettings,
    NewChildMenuItem,
    RetryOnTimeout,
    SaveMenuItem,
    LoadChefs,
    LoadGroupOrderAddOnItems,
    RestoreMenuItem,
  } = coordinators

  const { close } = UIService.EditMenuItem
  const {
    calculatePrice: calculateMarketPrice,
    calculateChefPrice,
    calculateChefPriceFromRetail,
  } = OrderService
  const clearErrors = UIService.Errors.clear
  const editChildMenuItem = EditChildMenuItem({
    ChefService,
    RestService,
    UIService,
    pResponseMenuItem,
  })
  const newChildMenuItem = NewChildMenuItem({
    ChefService,
    UIService,
    pDefaultChildMenuFields,
  })
  const saveMenuItem = SaveMenuItem({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateMarketplaceAddOn,
    pRequestUpdateMenuItem,
    pRequestUpdateItemImages,
    pResponseMenuItem,
    pResponseMenuItems,
    pSerializeLoadMenuItemsParams,
    CloseChildMenuItemModal,
    EditMenuItem,
    HandleError,
    pStateToReduxMenuItem,
  })

  const deleteMenuItem = DeleteMenuItem({
    ChefService,
    RestService,
    UIService,
    pResponseMenuItems,
    LoadChefMenuItems,
    pSerializeLoadMenuItemsParams,
    HandleError,
  })
  const deleteChildMenuItem = DeleteChildMenuItem({
    RestService,
    UIService,
    HandleError,
  })
  const loadMenuItemSettings = LoadSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    RetryOnTimeout,
    HandleError,
  })('menuItems')
  const displayAlert = ({ message, title, options }) =>
    UIService.Alert.showPopup({ message, title, options })
  const loadChefs = LoadChefs({ ChefService, RestService, pResponseChefs })
  const loadGroupOrderAddOnItems = LoadGroupOrderAddOnItems({
    RestService,
    pResponseMenuItems: (json) => json.map((i) => pResponseMenuItem(i)),
  })

  const restoreMenuItem = RestoreMenuItem({
    ChefService,
    RestService,
    UIService,
    pResponseMenuItems,
    LoadChefMenuItems,
    pSerializeLoadMenuItemsParams,
    HandleError,
  })

  return {
    calculateChefPrice,
    calculateMarketPrice,
    calculateChefPriceFromRetail,
    clearErrors,
    close,
    deleteMenuItem,
    deleteChildMenuItem,
    displayAlert,
    editChildMenuItem,
    loadMenuItemSettings,
    newChildMenuItem,
    saveMenuItem,
    loadChefs,
    loadGroupOrderAddOnItems,
    pToMenuItemVerifications,
    restoreMenuItem,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMenuItemModal)
