import { connect } from 'react-redux'

import { CopyMenuItemsModal } from '@components/chef/chefSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state: any) => {
  const { chef } = state

  return {
    chef,
  }
}

const mapDispatchToProps = () => {
  const { RestService, MarketService, ChefService, UIService } = services
  const {
    LoadMarkets,
    LoadChefs,
    SearchMenuItems,
    CloseCopyMenuItems,
    CopyMenuItems,
  } = coordinators
  const { pResponseHeadquarters, pResponseChefs, pResponseIndexMenuItems } =
    presenters.Api

  const loadHqs = LoadMarkets({
    MarketService,
    RestService,
    pResponseHeadquarters,
  })

  const loadChefs = LoadChefs({ ChefService, RestService, pResponseChefs })

  const searchMenuItems = SearchMenuItems({
    RestService,
    pResponseMenuItemsSearch: pResponseIndexMenuItems,
  })

  const copyMenuItems = CopyMenuItems({
    RestService,
    HandleError,
  })

  const close = CloseCopyMenuItems({ UIService })

  const { displaySuccessMessage, displayFailureMessage } =
    UIService.FlashMessage

  return {
    loadHqs,
    loadChefs,
    searchMenuItems,
    copyMenuItems,
    close,
    displaySuccessMessage,
    displayFailureMessage,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyMenuItemsModal)
