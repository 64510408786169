import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { config } from 'hungry-core2'
import { BiPencil, BiStar } from 'react-icons/bi'
import { RiFileCopyLine, RiWallet3Line } from 'react-icons/ri'
import { HiOutlineTruck, HiOutlineDocumentDownload } from 'react-icons/hi'
import { BsCreditCard } from 'react-icons/bs'
import { FiMail } from 'react-icons/fi'
import Moment from 'moment-timezone'
import { Dropdown, LabelInfo, LinkText } from '@components/common/form'
import Table from '@components/common/Table'
import YSpacing from '@components/common/YSpacing'
import DividerLine from '@components/common/DividerLine'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import {
  Label,
  ModalSectionTitle,
  ButtonsBarLegend,
  ButtonsBar,
  ResponsiveTextDiv,
} from '@res/styledComponents/index'
import { colors } from '../../../../constants'
import {
  AlertChef,
  AwaitingPayment,
  ClientOrderReminder,
  OrderSurvey,
  ServiceDetails,
} from '@containers/order/orderSections/panel'
import ManualChargeSection from '@containers/order/orderSections/ManualChargeSection'
import {
  AuthorizedDisplay,
  AuthorizedInteractable,
} from '@containers/common/auth'
import { SECTION1, SECTION3, NONFINANCEEDIT } from '../../../../constants'

class Overview extends Component {
  state = { width: 0 }

  handleResize = () =>
    this.setState({
      width: window.innerWidth,
    })

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
    const { order } = this.props
    if (order) {
      this.props.loadHubspotDeal(order.id)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentWillReceiveProps(nextProps) {
    const { hubspotDeal, order } = nextProps
    if (!this.props.hubspotDeal || (order !== this.props.order && order.id)) {
      this.props.loadHubspotDeal(order.id)
    }
    if (
      hubspotDeal &&
      hubspotDeal.hubspotId !== this.props.hubspotDeal.hubspotId
    ) {
      this.setState({ hubspotDeal })
    }
  }

  getAuthorizedSection = (section) => {
    const { order, user, isAuthorized } = this.props
    const { clientSetUpTime } = order || {}

    const saveOrderRoles = ['sales lead', 'master admin', 'chef lead']
    if (
      clientSetUpTime &&
      Moment(clientSetUpTime).add(10, 'days').isAfter(Moment())
    ) {
      saveOrderRoles.push('sales rep')
    }
    const authorized = isAuthorized(user, saveOrderRoles)

    return authorized ? section : NONFINANCEEDIT
  }

  onChangeStatus = (selected) => {
    const s = selected.target.value
    if (!(s === 'canceled' || s === 'created' || s === 'delivered')) {
      return
    }
    if (s === 'canceled' || this.props.chefPayouts.length !== 0) {
      this.props.setReduxOrderStatus(s)
      this.props.showChefPayouts()
    } else {
      this.props.changeOrderStatus(s)
    }
  }

  onClickAdjustPayouts = () => {
    this.props.showChefPayouts()
  }

  onClickEdit = () => {
    const section = this.getAuthorizedSection(SECTION3)
    this.props.editOrder(section)
  }

  onClickCopy = () => {
    this.props.copyOrder()
  }

  orderToProposalCallback = (proposal) => {
    const { uri } = this.props
    window.open(`${uri}/proposals/?proposal_id=${proposal.id}`)
  }

  onClickOrderToProposal = () => {
    this.props.orderToProposal(
      this.props.order.id,
      this.orderToProposalCallback,
    )
  }

  onClickDelete = () => {
    const { deleteOrder, order } = this.props
    deleteOrder(order)
  }

  calculateSubtotalPreTaxPricePerHead = () => {
    const { headCount, subtotal } = this.props.order

    return headCount ? parseFloat(subtotal) / headCount : 0
  }

  calculateTotalPostTaxPricePerHead = () => {
    const { headCount, total } = this.props.order
    if (total) {
      return parseFloat(total) / headCount
    }

    return 0
  }

  onEditSection = (section) => {
    const authorizedSection = this.getAuthorizedSection(section)
    this.props.editOrder(authorizedSection)
  }

  calculateMargin() {
    const { calculateGrossMargin, calculateDiscounts, pResponseEditOrder } =
      this.props
    const order = pResponseEditOrder({
      orderableJson: this.props.order,
      calculateDiscounts,
    })
    const {
      adjustedChefPayouts,
      chefs,
      tip,
      total,
      tax,
      predictedServiceCosts,
      supplyBreakdown,
      carbonNeutralContribution,
    } = order

    const suppliesCost = (supplyBreakdown && supplyBreakdown.total.cost) || 0

    const margin =
      calculateGrossMargin({
        adjustedChefPayouts,
        chefs,
        tip: Number(tip) || 0,
        total: Number(total) || 0,
        tax: Number(tax) || 0,
        predictedServiceCosts: Number(predictedServiceCosts) || 0,
        suppliesCost,
        carbonNeutralContribution: Number(carbonNeutralContribution) || 0,
      }) || 0

    return margin
  }

  renderEditButtons(orderStatus) {
    const { order } = this.props
    const {
      clientOrderReminderLastSentStr,
      needAlertChef,
      needServiceDetails,
      needToOrderSurvey,
      isPaid,
      isInvoiceSent,
    } = order
    const editRoles =
      orderStatus === 'canceled'
        ? ['master admin', 'sales lead', 'chef lead']
        : ['master admin', 'sales rep', 'sales lead', 'chef lead']
    const needAttentionStyles = { color: colors.violet, fontFamily: 'bold' }
    const completedStyles = {
      color: colors.blue,
      fontFamily: 'bold',
      display: 'flex',
    }

    return (
      <div>
        <ButtonsBarLegend>
          <p>
            <span style={{ background: colors.blue }} />
            Completed
          </p>
          <p>
            <span style={{ background: colors.violet }} />
            Incomplete
          </p>
        </ButtonsBarLegend>
        <ButtonsBar>
          {order.statuses[0] && order.statuses[0].code == 'canceled' ? (
            <FlexContainer flexWrap="wrap">
              <p className="mr-8">Order Canceled</p>
              <button
                style={!isInvoiceSent ? needAttentionStyles : completedStyles}
                onClick={() => this.setState({ showInvoiceModal: true })}
              >
                <FiMail />
                Send Proposal & Invoice
              </button>
              <button
                style={!isPaid ? needAttentionStyles : completedStyles}
                onClick={() => this.setState({ showChargeClientModal: true })}
              >
                <BsCreditCard />
                Charge Client/Payment Status
              </button>
            </FlexContainer>
          ) : (
            <FlexContainer flexWrap="wrap">
              <button
                style={!isInvoiceSent ? needAttentionStyles : completedStyles}
                onClick={() => this.setState({ showInvoiceModal: true })}
              >
                <FiMail />
                Send Proposal & Invoice
              </button>
              <button
                style={!isPaid ? needAttentionStyles : completedStyles}
                onClick={() => this.setState({ showChargeClientModal: true })}
              >
                <BsCreditCard />
                Charge Client/Payment Status
              </button>
              <button
                style={needAlertChef ? needAttentionStyles : completedStyles}
                onClick={() => this.setState({ showAlertChefModal: true })}
              >
                <FiMail />
                Send Order To Chef
              </button>
              <AuthorizedDisplay
                roles={[
                  'master admin',
                  'sales rep',
                  'sales lead',
                  'catering captain',
                  'captain lead',
                ]}
                style={{ display: 'inline' }}
              >
                <button
                  style={
                    needServiceDetails ? needAttentionStyles : completedStyles
                  }
                  onClick={() => this.setState({ showCaptainModal: true })}
                >
                  <HiOutlineTruck size={15} />
                  See Captain Status
                </button>
              </AuthorizedDisplay>
              <button
                style={
                  needToOrderSurvey ? needAttentionStyles : completedStyles
                }
                onClick={() => this.setState({ showSurveyModal: true })}
              >
                <BiStar size={15} />
                Send Client Survey
              </button>
              {order && order.orderType !== 'VCX' && (
                <button
                  style={
                    !clientOrderReminderLastSentStr
                      ? needAttentionStyles
                      : completedStyles
                  }
                  onClick={() => this.setState({ showSendMenuModal: true })}
                >
                  <FiMail />
                  Send Menu To Client
                </button>
              )}
              <a
                style={completedStyles}
                href={`${config.API_HOST}/api/admin/services_dashboard/${order.id}/pdf?type=day_of_menu`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <HiOutlineDocumentDownload />
                Download Day of Menu
              </a>
            </FlexContainer>
          )}
          <DividerLine />
          <YSpacing height="10px" />
          <FlexContainer>
            <AuthorizedInteractable roles={editRoles}>
              <button onClick={this.onClickEdit}>
                <BiPencil />
                Edit
              </button>
            </AuthorizedInteractable>
            <AuthorizedInteractable
              roles={['master admin', 'sales rep', 'sales lead', 'chef lead']}
            >
              <button onClick={this.onClickCopy}>
                <RiFileCopyLine />
                Copy Order
              </button>
            </AuthorizedInteractable>
            <AuthorizedInteractable
              roles={['master admin', 'sales rep', 'sales lead', 'chef lead']}
            >
              <button onClick={this.onClickOrderToProposal}>
                <RiFileCopyLine />
                Copy Order To Proposal
              </button>
            </AuthorizedInteractable>
            <AuthorizedInteractable
              roles={['master admin', 'sales rep', 'sales lead', 'chef lead']}
            >
              <button onClick={this.onClickAdjustPayouts}>
                <RiWallet3Line /> Adjust Chef Payouts{' '}
              </button>
            </AuthorizedInteractable>
          </FlexContainer>
        </ButtonsBar>
      </div>
    )
  }

  getChefOrderInstructions = (chef) => {
    const {
      order: { chefOrderInstructions },
    } = this.props

    const chefInstrs = chefOrderInstructions.find((c) => c.chefId === chef.id)

    return chefInstrs && chefInstrs.words.length > 0 ? chefInstrs.words : 'N/A'
  }

  getAvgPricePerPerson = () => {
    const {
      order: {
        account: { avgPricePerPerson },
        dinerProfile,
      },
    } = this.props
    const { budgetPerEmployee } = dinerProfile || {}

    if (budgetPerEmployee && parseFloat(budgetPerEmployee)) {
      return `$${parseFloat(budgetPerEmployee).toFixed(2)}`
    } else if (avgPricePerPerson && parseFloat(avgPricePerPerson)) {
      return `$${parseFloat(avgPricePerPerson).toFixed(2)}`
    } else {
      return 'N/A'
    }
  }

  render() {
    const { order } = this.props
    const {
      showAlertChefModal,
      showInvoiceModal,
      showCaptainModal,
      showChargeClientModal,
      showSendMenuModal,
      showSurveyModal,
      width,
    } = this.state
    if (!order) {
      return null
    }

    const {
      id,
      orderNumber,
      orderType,
      proposal,
      setUpInstructions,
      generateFeedbackQr,

      clientSetUpTimeStr,
      hasEffectiveDateOverride,
      dateStr,
      dueDate,
      effectiveDate,
      chargeDate,
      dropoffAddress,
      billingAddress,
      deliveryInstructions,
      servicesInstructions,

      headCount,
      statuses,
      setUpStyle,
      discount,
      discounts,
      dietaryRestrictions = 'None',

      accountExecutive,
      account,
      chefs,
      serviceFee,
      subtotal,
      tax,
      tip,
      total,
      totalDiscount,
      // modal statuses
      needAlertChef,
      needServiceDetails,
      needToOrderSurvey,
      needsStaffing,
      needsCleanup,
      contact,
      invoiceContact,
      receiptContact,

      carbonNeutral,
      carbonNeutralContribution,

      latePaymentReminderSentAt,

      paymentMethod,
    } = order
    const sameAsDropoff =
      billingAddress &&
      dropoffAddress &&
      billingAddress.id === dropoffAddress.id
    const orderStatus = (statuses[0] && statuses[0].code) || 'created'
    const authRoles =
      orderStatus === 'canceled'
        ? ['master admin', 'sales lead', 'chef lead', 'sales rep']
        : ['master admin', 'sales lead', 'sales rep', 'chef lead'] //should sales reps have uncancel access?
    const orderStatusOptions = ['created', 'delivered', 'canceled']
    const dropoffAddressString =
      dropoffAddress &&
      `${dropoffAddress.line1} ${dropoffAddress.line2}, ${dropoffAddress.city} ${dropoffAddress.state}, ${dropoffAddress.zip}`
    const billingAddressString =
      billingAddress &&
      `${billingAddress.line1} ${billingAddress.line2}, ${billingAddress.city} ${billingAddress.state}, ${billingAddress.zip}`
    const margin = this.calculateMargin()
    const marginColor = margin >= 30 ? 'green' : 'red'
    const colWidth = width > 1400 ? '31%' : '48%'

    let menuItemsTotal = 0
    let chefPriceTotal = 0

    let serviceItemsTotal = 0
    let serviceItemsChefTotal = 0

    return (
      <div>
        {this.renderEditButtons(orderStatus)}
        <FlexContainer flexDirection="row" width="100%">
          <FlexContainer width="40%" flexDirection="column">
            <Panel
              width="100%"
              maxWidth="450px"
              heading="Order Details"
              isEdit={true}
              onEditCallBack={() => this.onEditSection(SECTION1)}
            >
              <div className="flex flex-wrap">
                <LabelInfo
                  label="Order Number"
                  value={orderNumber}
                  width={colWidth}
                  spacing="10px"
                />
                {proposal && (
                  <LabelInfo
                    label="Proposal Number"
                    value={proposal}
                    width={colWidth}
                    spacing="10px"
                  />
                )}
                <LabelInfo
                  label="Client Name"
                  value={account ? `${account.name} (${account.pin})` : ''}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="Event Date & Time"
                  value={dateStr}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label={`Effective Date${
                    hasEffectiveDateOverride ? ' (Override)' : ''
                  }`}
                  value={Moment(effectiveDate).format('MM/DD/YYYY')}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="Due Date"
                  value={Moment(dueDate).format('MM/DD/YYYY')}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="Charge Date"
                  value={Moment(chargeDate).format('MM/DD/YYYY')}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="Head Count"
                  value={headCount}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="Order Type"
                  value={orderType}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="Client Set Up Time"
                  value={clientSetUpTimeStr}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="Set up Style"
                  value={setUpStyle}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="Add'l Staff?"
                  value={needsStaffing ? 'Yes' : 'No'}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="VIP Clean Up"
                  value={needsCleanup ? 'Yes' : 'No'}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="Payment Method?"
                  value={paymentMethod ? 'Yes' : 'No'}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="Event Address"
                  value={dropoffAddressString}
                  width="100%"
                  spacing="20px"
                />
                <LabelInfo
                  label="Default Address Delivery Instructions"
                  value={deliveryInstructions}
                  width="100%"
                  spacing="20px"
                />
                <LabelInfo
                  label="Billing Address"
                  value={
                    sameAsDropoff
                      ? 'Same as event address'
                      : billingAddressString
                  }
                  width="100%"
                  spacing="10px"
                />
                <YSpacing height="10px" />
                {generateFeedbackQr && (
                  <>
                    <FlexContainer
                      justifyContent="space-between"
                      width="48%"
                      flexDirection="column"
                    >
                      <Label>QR Menu Link</Label>
                      <LinkText
                        isLink={true}
                        label="View"
                        link={`${config.qr_menu_url}/?id=${id}`}
                      />
                    </FlexContainer>
                    <YSpacing height="20px" />
                  </>
                )}
                <LabelInfo
                  label="Invoice Note"
                  value={setUpInstructions ? setUpInstructions : ''}
                  width="100%"
                  spacing="20px"
                />
                <LabelInfo
                  label="Order Delivery, Parking & Set Up Instructions"
                  value={
                    servicesInstructions &&
                    servicesInstructions.replace(/<\/?p>/g, '')
                  }
                  width="100%"
                  spacing="20px"
                />
                <LabelInfo
                  label="Late Payment Reminder Sent At"
                  value={
                    latePaymentReminderSentAt
                      ? Moment(latePaymentReminderSentAt).format(
                          'MMM. d, YYYY h:mm a',
                        )
                      : 'Not sent'
                  }
                  width="100%"
                />
                <YSpacing height="20px" />
                <LabelInfo
                  label="Dietary Restrictions"
                  value={dietaryRestrictions ? dietaryRestrictions : 'N/A'}
                  width="100%"
                  spacing="20px"
                />
                <AuthorizedInteractable roles={authRoles}>
                  <Dropdown
                    label="Order Status"
                    value={orderStatus}
                    onChange={this.onChangeStatus}
                  >
                    {orderStatusOptions.map((o) => (
                      <option key={o} value={o}>
                        {o}
                      </option>
                    ))}
                  </Dropdown>
                </AuthorizedInteractable>
              </div>
            </Panel>

            <Panel
              width="100%"
              maxWidth="450px"
              heading="Order Margin Details"
              isEdit={true}
              onEditCallBack={() => this.onEditSection(SECTION3)}
            >
              <FlexContainer
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
              >
                <FlexContainer flexDirection="column" width="47%">
                  <ModalSectionTitle>Client Pricing</ModalSectionTitle>
                  <YSpacing height="10px" />
                  <FlexContainer width="100%" justifyContent="space-between">
                    <div> Subtotal: </div>
                    <div>${subtotal} </div>
                  </FlexContainer>
                  <FlexContainer width="100%" justifyContent="space-between">
                    <div> Discounts: </div>
                    <div>${totalDiscount} </div>
                  </FlexContainer>
                  <FlexContainer width="100%" justifyContent="space-between">
                    <div> Service Fee: </div>
                    <div>${serviceFee} </div>
                  </FlexContainer>
                  <FlexContainer width="100%" justifyContent="space-between">
                    <div> Tax: </div>
                    <div>${tax} </div>
                  </FlexContainer>
                  <FlexContainer width="100%" justifyContent="space-between">
                    <div> Gratuity: </div>
                    <div>${tip} </div>
                  </FlexContainer>
                  {carbonNeutral && (
                    <FlexContainer width="100%" justifyContent="space-between">
                      <div> Carbon Neutral Contribution: </div>
                      <div>${carbonNeutralContribution} </div>
                    </FlexContainer>
                  )}
                  <FlexContainer width="100%" justifyContent="space-between">
                    <div> Total: </div>
                    <div>${total} </div>
                  </FlexContainer>
                  <YSpacing height="10px" />
                </FlexContainer>

                <FlexContainer flexDirection="column" width="47%">
                  {account && (
                    <FlexContainer flexDirection="column">
                      <LabelInfo
                        label="Est. Pre-Tax Price Per Head"
                        value={`$${this.calculateSubtotalPreTaxPricePerHead().toFixed(
                          2,
                        )}`}
                      />
                      <YSpacing height="10px" />
                      <LabelInfo
                        label="Est. Post-Tax Price Per Head"
                        value={`$${this.calculateTotalPostTaxPricePerHead().toFixed(
                          2,
                        )}`}
                      />
                      <YSpacing height="10px" />
                      <LabelInfo
                        label="Price Per Person"
                        value={this.getAvgPricePerPerson()}
                      />
                      <YSpacing height="10px" />
                      <ModalSectionTitle>Estimated Margin</ModalSectionTitle>
                      <b style={{ color: marginColor }}>{margin}%</b>
                    </FlexContainer>
                  )}
                </FlexContainer>
              </FlexContainer>
              <FlexContainer width="47%" justifyContent="space-between">
                <ModalSectionTitle>Discount Added?</ModalSectionTitle>
                <div>{discount ? 'Yes' : 'No'}</div>
              </FlexContainer>
              <YSpacing height="10px" />
              <FlexContainer width="60%" justifyContent="space-between">
                {discount && (
                  <div>
                    <LabelInfo label="Kind" value={discount.kind} />
                    <LabelInfo label="Reason" value={discount.reason} />
                  </div>
                )}

                {discounts.map((d, i) => (
                  <div key={i}>
                    <LabelInfo label="Kind" value={d.kind} />
                    <LabelInfo label="Reason" value={d.reason} />
                  </div>
                ))}
              </FlexContainer>
            </Panel>
          </FlexContainer>
          <FlexContainer width="60%" flexDirection="column">
            {account && (
              <Panel
                width="100%"
                maxWidth="100%"
                heading="Client Point of Contact(s)"
                isEdit={true}
                onEditCallBack={() => this.onEditSection(SECTION1)}
              >
                <FlexContainer
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <LabelInfo
                    label="Order Contact"
                    value={contact ? contact.name : ''}
                    width="31%"
                  />
                  <LabelInfo
                    label="Email"
                    value={contact ? contact.email : ''}
                    width="31%"
                  />
                  <LabelInfo
                    label="Phone"
                    value={contact ? contact.phoneNumber : ''}
                    width="31%"
                  />
                </FlexContainer>
                <YSpacing height="20px" />
                <FlexContainer
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <LabelInfo
                    label="Invoice Contact"
                    value={invoiceContact ? invoiceContact.name : ''}
                    width="31%"
                  />
                  <LabelInfo
                    label="Email"
                    value={invoiceContact ? invoiceContact.email : ''}
                    width="31%"
                  />
                  <LabelInfo
                    label="Phone"
                    value={invoiceContact ? invoiceContact.phoneNumber : ''}
                    width="31%"
                  />
                </FlexContainer>
                <YSpacing height="20px" />
                <FlexContainer
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <LabelInfo
                    label="Receipt Contact"
                    value={receiptContact ? receiptContact.name : ''}
                    width="31%"
                  />
                  <LabelInfo
                    label="Email"
                    value={receiptContact ? receiptContact.email : ''}
                    width="31%"
                  />
                  <LabelInfo
                    label="Phone"
                    value={receiptContact ? receiptContact.phoneNumber : ''}
                    width="31%"
                  />
                </FlexContainer>
              </Panel>
            )}
            <FlexContainer
              flexDirection="row"
              maxWidth="750px"
              justifyContent="space-between"
            >
              <Panel
                width="27%"
                heading="Sales Rep"
                isEdit={true}
                onEditCallBack={() => this.onEditSection(SECTION1)}
              >
                <div>{`${accountExecutive.firstName} ${accountExecutive.lastName}`}</div>
                <YSpacing height="20px" />
                <div>{accountExecutive.email}</div>
                <YSpacing height="20px" />
                <div>{accountExecutive.phoneNumber}</div>
              </Panel>

              <Panel width="70%" heading="Chef Pickup Addresses & Contact Info">
                <FlexContainer width="100%" flexDirection="column">
                  {chefs.map((chef) => (
                    <>
                      <FlexContainer
                        key={chef.id}
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <ModalSectionTitle>{chef.name}</ModalSectionTitle>
                        <div>{chef.pickupAddress}</div>
                      </FlexContainer>
                      <YSpacing key={`${chef.id}-spacing-1`} height="10px" />
                      <FlexContainer
                        key={`${chef.id}-contact-headings`}
                        justifyContent="space-between"
                      >
                        <FlexContainer width="24%" flexDirection="row">
                          <ModalSectionTitle>EMAIL</ModalSectionTitle>
                        </FlexContainer>
                        <FlexContainer width="20%" flexDirection="column">
                          <ModalSectionTitle>PHONE</ModalSectionTitle>
                        </FlexContainer>
                        <FlexContainer width="31%">
                          <ModalSectionTitle>CHEF NOTES</ModalSectionTitle>
                        </FlexContainer>
                      </FlexContainer>
                      <FlexContainer
                        key={`${chef.id}-contact-info`}
                        justifyContent="space-between"
                      >
                        <ResponsiveTextDiv width="24%">
                          {chef.email}
                        </ResponsiveTextDiv>
                        <ResponsiveTextDiv width="20%">
                          {chef.phoneNumber}
                        </ResponsiveTextDiv>
                        <ResponsiveTextDiv width="31%">
                          {this.getChefOrderInstructions(chef)}
                        </ResponsiveTextDiv>
                      </FlexContainer>
                      <YSpacing key={`${chef.id}-spacing-2`} height="20px" />
                    </>
                  ))}
                </FlexContainer>
              </Panel>
            </FlexContainer>
            <Panel
              width="100%"
              maxWidth="100%"
              heading="Menu Details & Chef Pricing"
              isScroll={true}
              isEdit={true}
              onEditCallBack={() => this.onEditSection(SECTION3)}
            >
              <div style={{ maxHeight: '500px', overflow: 'scroll' }}>
                <Label>Menu Items</Label>
                <Table
                  isStickyHeading={true}
                  headings={[
                    'Chef',
                    'Item',
                    'Type',
                    'Packaging',
                    'QTY',
                    'Price',
                    'Total',
                    'Chef Pricing',
                    'Food Margin',
                  ]}
                >
                  {order.orderMenuItems.map((o) => {
                    const price = Number(o.price)
                    const cost = Number(o.cost)
                    const mealType = o.menuItem
                      ? o.menuItem.isSubMenuItem
                        ? o.menuItem.subMenuItemCategory
                        : o.menuItem.mealType
                      : ''
                    const margin =
                      price === 0.0 || o.quantity === 0
                        ? 0
                        : Math.trunc(((price - cost) / price) * 100)

                    menuItemsTotal += Number(o.price) * o.quantity
                    chefPriceTotal += Number(o.cost) * o.quantity

                    let packaging = 'None'
                    if (
                      !!order.supplyBreakdown &&
                      !!order.supplyBreakdown.orderItems[o.id]
                    ) {
                      packaging = order.supplyBreakdown.orderItems[
                        o.id
                      ].packaging
                        .map((p) => {
                          return `${p.count}x ${p.name}`
                        })
                        .join(', ')
                    }

                    return (
                      <tr key={o.id}>
                        <td>{o.chefName}</td>
                        <td>{o.name}</td>
                        <td>{mealType}</td>
                        <td>{packaging}</td>
                        <td>{o.quantity}</td>
                        <td className="right">${price.toFixed(2)}</td>
                        <td className="right">
                          ${(price * o.quantity).toFixed(2)}
                        </td>
                        <td className="right">
                          ${(cost * o.quantity).toFixed(2)}
                        </td>
                        <td className="right">
                          {margin == null ? 'N/A' : `${margin.toFixed(2)}%`}
                        </td>
                      </tr>
                    )
                  })}
                  <tfoot style={{ position: 'sticky', bottom: 0 }}>
                    <tr style={{ background: colors.gray100, color: 'white' }}>
                      <td colSpan={5}></td>
                      <td className="right">TOTAL</td>
                      <td className="right">${menuItemsTotal.toFixed(2)}</td>
                      <td className="right">${chefPriceTotal.toFixed(2)}</td>
                      <td className="right">
                        {chefPriceTotal == 0
                          ? 'N/A'
                          : `${Math.trunc(
                              ((menuItemsTotal - chefPriceTotal) * 100) /
                                menuItemsTotal,
                            ).toFixed(2)}%`}
                      </td>
                    </tr>
                  </tfoot>
                </Table>
                {order.orderServiceItems.length > 0 && (
                  <>
                    <YSpacing height="20px" />
                    <Label>Service Items</Label>
                    <Table
                      isStickyHeading={true}
                      headings={[
                        'Chef',
                        'Service Item',
                        'QTY',
                        'Price',
                        'Total',
                        'Chef Pricing',
                      ]}
                    >
                      {order.orderServiceItems.map((o) => {
                        const price = Number(o.price)
                        const cost = Number(o.cost)
                        serviceItemsTotal += price * o.quantity
                        serviceItemsChefTotal += cost * o.quantity

                        return (
                          <tr key={o.id}>
                            <td>{o.chefName}</td>
                            <td>{o.name}</td>
                            <td>{o.quantity}</td>
                            <td>${price.toFixed(2)}</td>
                            <td>${(price * o.quantity).toFixed(2)}</td>
                            <td>${(cost * o.quantity).toFixed(2)}</td>
                          </tr>
                        )
                      })}
                      <tfoot style={{ position: 'sticky', bottom: 0 }}>
                        <tr
                          style={{ background: colors.gray100, color: 'white' }}
                        >
                          <td colSpan={3}></td>
                          <td>TOTAL</td>
                          <td>${serviceItemsTotal.toFixed(2)}</td>
                          <td>${serviceItemsChefTotal.toFixed(2)}</td>
                        </tr>
                      </tfoot>
                    </Table>
                  </>
                )}
              </div>
            </Panel>
          </FlexContainer>
        </FlexContainer>
        {/* MODALS */}
        {showInvoiceModal && (
          <AwaitingPayment
            hideModal={() => this.setState({ showInvoiceModal: false })}
            order={order}
          />
        )}
        {showChargeClientModal && (
          <ManualChargeSection
            hideModal={() => this.setState({ showChargeClientModal: false })}
          />
        )}
        {showAlertChefModal && (
          <AlertChef
            hideModal={() => this.setState({ showAlertChefModal: false })}
            order={order}
            actionRequired={needAlertChef}
          />
        )}
        {showCaptainModal && (
          <ServiceDetails
            order={order}
            actionRequired={needServiceDetails}
            hideModal={() => this.setState({ showCaptainModal: false })}
          />
        )}
        {showSurveyModal && (
          <OrderSurvey
            order={order}
            actionRequired={needToOrderSurvey}
            hideModal={() => this.setState({ showSurveyModal: false })}
          />
        )}
        {showSendMenuModal && (
          <ClientOrderReminder
            order={order}
            hideModal={() => this.setState({ showSendMenuModal: false })}
          />
        )}
      </div>
    )
  }
}

Overview.propTypes = {
  order: PropTypes.object,
  hubspotDeal: PropTypes.object,
  dealUrl: PropTypes.string,
  chefPayouts: PropTypes.array,
  uri: PropTypes.string,
  user: PropTypes.object,

  changeOrderStatus: PropTypes.func,
  calculateDiscounts: PropTypes.func,
  calculateGrossMargin: PropTypes.func,
  copyOrder: PropTypes.func,
  deleteOrder: PropTypes.func,
  editOrder: PropTypes.func,
  getDeliveryFeePercentAndLimit: PropTypes.func,
  isAuthorized: PropTypes.func,
  loadHubspotDeal: PropTypes.func,
  orderToProposal: PropTypes.func,
  setReduxOrderStatus: PropTypes.func,
  showChefPayouts: PropTypes.func,
  pResponseEditOrder: PropTypes.func,
}

export default Overview
