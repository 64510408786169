import React, { useState, useEffect, useMemo } from 'react'
import Moment from 'moment-timezone'
import styled from '@emotion/styled'
import { DateMenus } from '@containers/groupOrders'
import ChefNotifs from './ChefNotifsModal'
import GroupOrderModal from '@components/groupOrders/GroupOrderModal'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import loadGif from '@res/images/loading.gif'
import { Button } from '@components/common/form'
import { colors } from '@constants'
import {
  setAutoStagedMenus,
  setChangedDateMenuMap,
  updateDateMenus,
} from '../../../state/actions/groupOrder'
import { GetUpdatedDateMenusAfterCutoffChange } from '../../../utils'

type Props = {
  headquarter?: any
  locale?: any
  client?: any
  dateMenus?: any
  changedDateMenuMap?: any
  autoStagedMenus?: any
  clientSettings?: any
  selectedIndex?: any

  searchClientSettings?: any
  searchDateMenus?: any
  saveDateMenu?: any
  setDateMenus?: any
  updateDateMenus?: any
  setChangedDateMenuMap?: any
  clearChangedDateMenuMap?: any
  setAutoStagedMenus?: any
  notifyChefs?: any
  getGroupOrderBySettingsDate?: any
  displayFailureMessage?: any
}

let alreadySaving = false

const GroupOrderCalendar = (props: Props) => {
  const { client, selectedIndex, clientSettings, locale } = props

  // map to keep track if date menus are loaded
  const clientSettingsHasLoadedDateMenusMap: any = {}
  clientSettings.forEach((cs: any, i: any) => {
    clientSettingsHasLoadedDateMenusMap[i] = false
  })

  const weekHeadings = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]

  const [month, setMonth] = useState(Moment())
  const [showModal, setShowModal] = useState(false)
  const [showChefNotif, setShowChefNotif] = useState(false)
  const [isLoading, _setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [selectedDate, setSelectedDate] = useState()
  const [currentDateMenuId, setCurrentDateMenuId] = useState()
  const [groupOrder, setGroupOrder] = useState(undefined)

  useEffect(() => {
    if (clientSettings.length > 0) {
      onLoadDateMenus(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSettings])

  useEffect(() => {
    if (clientSettings.length > 0) {
      onLoadDateMenus(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex])

  useEffect(() => {
    if (clientSettings.length > 0 && !!selectedDate) {
      onLoadGroupOrder()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSettings, selectedIndex, selectedDate])

  useEffect(() => {
    if (month != null && month != undefined && clientSettings.length > 0) {
      onLoadDateMenus(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month])

  const subsidySettings = useMemo(() => {
    let settings
    if (groupOrder) {
      ;({ subsidySettings: settings } = groupOrder)
    } else {
      const { subsidySettings: subsidies } = clientSettings[selectedIndex] || {}
      const subsidySettingsMap = (
        (subsidies && subsidies.subsidies) ||
        []
      ).reduce((map: any, subsidySetting: any) => {
        if (Array.isArray(subsidySetting.days)) {
          if (subsidySetting.isActive) {
            subsidySetting.days.forEach((d: any) => {
              map[d.toString()] = subsidySetting
            })
          }
        }

        return map
      }, {})

      settings =
        (selectedDate && subsidySettingsMap[(selectedDate as any).day()]) || {}
    }
    const {
      fullySubsidized,
      itemPriceSettings,
      limitPurchase,
      perOrderSubsidy,
      subsidyPercentLimitAmt,
      isSubsidyPercentLimit,
      subsidyType,
    } = settings || {}

    return {
      fullySubsidized,
      itemPriceSettings,
      limitPurchase,
      perOrderSubsidy,
      subsidyPercentLimitAmt,
      isSubsidyPercentLimit,
      subsidyType,
    }
  }, [clientSettings, groupOrder, selectedIndex, selectedDate])

  const onLoadDateMenus = async (replaceOrders: any) => {
    // Get previous & following month days shown on calendar
    // to display dateMenus for all dates shown
    const currentMonth = month.clone()
    const daysInMonth = currentMonth.daysInMonth()
    const currentMonthDates = new Array(currentMonth.daysInMonth())
      .fill(null)
      .map((x, i) => currentMonth.clone().startOf('month').add(i, 'days'))
    const prevMonthDays = currentMonthDates[0].isoWeekday()
    let nextMonthDays = 1
    let calendarDays = daysInMonth + prevMonthDays
    while (calendarDays % 7 !== 0) {
      calendarDays += 1
      nextMonthDays += 1
    }
    const start = month
      .clone()
      .startOf('month')
      .subtract(prevMonthDays - 1, 'days')
    const end = month
      .clone()
      .endOf('month')
      .add(nextMonthDays, 'days')
      .endOf('day')
    if (replaceOrders) {
      const newDateMenus = await props.searchDateMenus({
        settingsId: clientSettings[selectedIndex].id,
        start,
        end,
      })
      if (newDateMenus) {
        props.setDateMenus(newDateMenus)
        clientSettingsHasLoadedDateMenusMap[selectedIndex] = true
        clientSettings.forEach((cs: any, i: any) => {
          if (i === selectedIndex) {
            clientSettingsHasLoadedDateMenusMap[i] = true
          } else {
            clientSettingsHasLoadedDateMenusMap[i] = false
          }
        })
      }
    } else if (!clientSettingsHasLoadedDateMenusMap[selectedIndex]) {
      const newDateMenus = await props.searchDateMenus({
        settingsId: clientSettings[selectedIndex].id,
        start,
        end,
      })
      if (newDateMenus) {
        props.setDateMenus([...props.dateMenus, ...newDateMenus])
        clientSettingsHasLoadedDateMenusMap[selectedIndex] = true
      }
    }
  }

  const onSaveDateMenus = async () => {
    const newStagedMenus: any = []
    setIsSaving(true)
    if (!alreadySaving) {
      alreadySaving = true
      for (const dateMenu of props.dateMenus) {
        const dateMenuChangedInfo =
          props.changedDateMenuMap[dateMenu.id || dateMenu.dateStr]
        if (!dateMenuChangedInfo) {
          continue
        }
        const validationErr = validateCutoff(dateMenu)
        if (validationErr) {
          props.displayFailureMessage(validationErr)
          continue
        }

        const result = await props.saveDateMenu({
          dateMenu: GetUpdatedDateMenusAfterCutoffChange(dateMenu, locale),
          changedDateMenuMap: dateMenuChangedInfo,
          dateStr: dateMenu.dateStr,
        })
        if (result) {
          const removedMap = props.changedDateMenuMap
          const mapKey =
            result.id === dateMenu.id ? result.id : dateMenu.dateStr
          const chefKeys = Object.keys(removedMap[mapKey])
          //might be from a newly created datemenu that didn't have id. In that case wipe only the menu info from the changed map
          if (mapKey !== result.id) {
            dateMenu.menus.forEach((menu: any) => {
              removedMap[mapKey] && delete removedMap[mapKey][menu.id]
            })
          } else {
            delete removedMap[mapKey]
          }
          props.setChangedDateMenuMap(removedMap)

          chefKeys.forEach((chefId) => {
            const stagedMenu: any = {}
            stagedMenu[result.id] = chefId
            newStagedMenus.push(stagedMenu)
          })
        }
      }
      alreadySaving = true
    }
    props.setAutoStagedMenus([...props.autoStagedMenus, ...newStagedMenus])
    setIsSaving(false)
    alreadySaving = false
    onLoadDateMenus(true)
  }

  const validateCutoff = (dateMenu: any) => {
    let errStr = ''
    const { cutoffTime } = dateMenu
    const sortedMenus = dateMenu.menus
      .filter((menu: any) => menu.active)
      .sort((a: any, b: any) =>
        Moment(a.cutoffTime).isAfter(Moment(b.cutoffTime)) ? -1 : 1,
      )

    //May be setting menus before they are set to active
    if (sortedMenus.length < 1) {
      return errStr
    }
    const oldestCutoffMenu = sortedMenus[0]
    if (Moment(cutoffTime).isAfter(oldestCutoffMenu.cutoffTime)) {
      errStr += `Error for menus on ${dateMenu.dateStr}. Menu: ${oldestCutoffMenu.name} has the latest cutoff time of all active menus but is before the cutoff time of the Group Order. Please adjust the cutoff time on the Group Order or add a menu with a later cutoff time. `
    }

    return errStr
  }

  const addMenu = (date: any, dateMenuId: any) => {
    setSelectedDate(date)
    setCurrentDateMenuId(dateMenuId)
    setShowModal(true)
  }

  const onLoadGroupOrder = async () => {
    const groupOrder = await props.getGroupOrderBySettingsDate({
      settingsId: clientSettings[selectedIndex].id,
      date: (selectedDate as any).format('YYYY-MM-DD'),
    })
    if (groupOrder) {
      setGroupOrder(groupOrder)
    } else {
      setGroupOrder(undefined)
    }
  }

  const onRemoveMenu = (menuId: any, dateMenuId: any) => {
    const isNew =
      props.changedDateMenuMap[dateMenuId] &&
      props.changedDateMenuMap[dateMenuId][menuId] &&
      props.changedDateMenuMap[dateMenuId][menuId]['_new']
    const newChangedMap = { ...props.changedDateMenuMap }
    if (newChangedMap[dateMenuId] && newChangedMap[dateMenuId][menuId]) {
      delete newChangedMap[dateMenuId][menuId]
    }
    if (isNew) {
      const dateMenuIndex = props.dateMenus.findIndex(
        (dm: any) => dm.id === dateMenuId || dm.dateStr === dateMenuId,
      )
      const updatedDateMenus = [...props.dateMenus]
      const updatedDateMenu = props.dateMenus[dateMenuIndex]
      updatedDateMenu.menus = updatedDateMenu.menus.filter(
        (m: any) => m.id !== menuId,
      )
      if (updatedDateMenu.menus.length <= 0) {
        updatedDateMenus.splice(dateMenuIndex, 1)
      } else {
        updatedDateMenus.splice(dateMenuIndex, 1, updatedDateMenu)
      }
      updateDateMenus({
        dateMenus: updatedDateMenus,
        changedDateMenuMap: newChangedMap,
      })

      return
    }
    setChangedDateMenuMap(newChangedMap)
  }

  const renderSavedMenu = (menu: any) => {
    return (
      <Menu key={menu.id} background="#d9f0f4">
        <div className="menu-heading">{menu.name}</div>
        <div className="menu-chef-name">{menu.chef.name}</div>
        {menu.menuItems.map((item: any) => {
          if (item.itemType === 'Entree') {
            const { averageRating, numRatings } = item.ratingInfo || {}

            return (
              <MenuItem key={item.id}>
                {item.name}{' '}
                <h4 style={{ fontWeight: 'bold' }}>
                  Rating:{' '}
                  {numRatings && numRatings !== 0
                    ? `${averageRating.toFixed(2)} ⭐ (${numRatings})`
                    : 'N/A'}
                </h4>
              </MenuItem>
            )
          }
        })}
      </Menu>
    )
  }

  const renderChangedMenu = (menu: any, dateMenu: any) => {
    return (
      <Menu key={menu.id} background="pink">
        <div className="menu-heading" style={{ background: '#f89eab' }}>
          {menu.name}
        </div>
        <span
          onClick={() => onRemoveMenu(menu.id, dateMenu.id || dateMenu.dateStr)}
        >
          X
        </span>
        <div className="menu-chef-name">{menu.chef.name}</div>
        {menu.menuItems.map((item: any) => {
          if (item.itemType === 'Entree') {
            const { averageRating, numRatings } = item.ratingInfo || {}

            return (
              <MenuItem key={item.id}>
                {item.name}{' '}
                <h4 style={{ fontWeight: 'bold' }}>
                  Rating:{' '}
                  {numRatings && numRatings !== 0
                    ? `${averageRating.toFixed(2)} ⭐ (${numRatings})`
                    : 'N/A'}
                </h4>
              </MenuItem>
            )
          }
        })}
      </Menu>
    )
  }

  const getDays = () => {
    const currentMonth = month
    const daysInMonth = currentMonth.daysInMonth()
    const currentMonthDates = new Array(currentMonth.daysInMonth())
      .fill(null)
      .map((x, i) => currentMonth.clone().startOf('month').add(i, 'days'))
    const firstDayOfWeek = currentMonthDates[0].isoWeekday()
    const days = []
    const rows: any = []
    let cells: any = []
    const prevMonthDays = firstDayOfWeek
    let calendarDays = daysInMonth + prevMonthDays
    const monthStart = month.clone().startOf('month')
    const startDate = Moment(monthStart).subtract(prevMonthDays - 1, 'days')
    const settingsIdToMatch =
      clientSettings &&
      clientSettings[selectedIndex] &&
      clientSettings[selectedIndex].id

    const locationStagedMenus =
      props.dateMenus &&
      props.dateMenus.filter(
        (o: any) => o.clientSettingsId === settingsIdToMatch,
      )
    // adds days from next month to total days to fill calendar
    while (calendarDays % 7 !== 0) {
      calendarDays += 1
    }
    for (let i = 0; i < calendarDays; i++) {
      const date = Moment(startDate).clone().add(i, 'days')
      const today = Moment().format('M D')
      const isToday = date.format('M D') === today
      const isCurrentMonth =
        Moment(date).format('M') === Moment(month).format('M')

      const locationDatemenus =
        locationStagedMenus &&
        locationStagedMenus.filter(
          (o: any) => Moment(o.date).format('M D') === date.format('M D'),
        )

      days.push(
        <td>
          <p
            className={`
              ${isCurrentMonth ? 'calendar-date' : 'calendar-date-light'}
              ${isToday && 'is-today'}
            `}
          >
            {Moment(date).format('D')}
          </p>
          <Orders>
            {locationDatemenus &&
              locationDatemenus.map((dateMenu: any) => {
                const mapKey = dateMenu.id || dateMenu.dateStr

                if (
                  !props.changedDateMenuMap ||
                  !props.changedDateMenuMap[mapKey] ||
                  Object.keys(props.changedDateMenuMap[mapKey]).length <= 0
                ) {
                  return (
                    <div key={dateMenu.id} style={{ width: '100%' }}>
                      {dateMenu.menus.map((menu: any) => renderSavedMenu(menu))}

                      <Button
                        className="calendar-button"
                        width="100%"
                        backgroundColor={colors.orange}
                        testId="add"
                        label="ADD/EDIT MENUS"
                        onClick={() => addMenu(date, dateMenu.id)}
                      />
                    </div>
                  )
                } else {
                  return (
                    <div key={dateMenu.id} style={{ width: '100%' }}>
                      {dateMenu.menus.map((menu: any) => {
                        if (props.changedDateMenuMap[mapKey][menu.id]) {
                          return renderChangedMenu(menu, dateMenu)
                        } else {
                          return renderSavedMenu(menu)
                        }
                      })}
                      <Button
                        className="calendar-button"
                        width="100%"
                        backgroundColor={colors.orange}
                        testId="add"
                        label="ADD/EDIT MENUS"
                        onClick={() => addMenu(date, mapKey)}
                      />
                    </div>
                  )
                }
              })}
          </Orders>
          {(!locationDatemenus || locationDatemenus.length <= 0) && (
            <Button
              className="calendar-button"
              width="100%"
              backgroundColor={colors.orange}
              testId="add"
              label="ADD/EDIT MENUS"
              onClick={() => addMenu(date, null)}
            />
          )}
        </td>,
      )
    }
    days.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row)
      } else {
        rows.push(cells)
        cells = []
        cells.push(row)
      }
      if (i === days.length - 1) {
        rows.push(cells)
      }
    })

    return rows.map((d: any, i: any) => <tr key={i}>{d}</tr>)
  }

  const onChangeMonth = (value: any) => () => {
    const currentMonth = Moment(month).clone()
    if (value < 0) {
      currentMonth.subtract(1, 'M')
    } else {
      currentMonth.add(1, 'M')
    }
    setMonth(currentMonth)
  }

  return (
    <CalendarContainer>
      {showModal && (
        <GroupOrderModal onHide={() => setShowModal(false)} date={selectedDate}>
          <DateMenus
            date={selectedDate}
            clientSettingsId={clientSettings[selectedIndex].id}
            clientSettings={clientSettings[selectedIndex] || null}
            clientId={client.id}
            dateMenuId={currentDateMenuId}
            groupOrder={groupOrder || null}
            perOrderSubsidy={parseFloat(subsidySettings.perOrderSubsidy)}
            subsidyPercentLimitAmt={parseFloat(
              subsidySettings.subsidyPercentLimitAmt,
            )}
            validateMenus={
              subsidySettings.limitPurchase && !subsidySettings.fullySubsidized
            }
            countMap={{}}
            scheduleSettings={
              clientSettings[selectedIndex] &&
              clientSettings[selectedIndex].scheduleSettings
            }
            subsidyType={subsidySettings.subsidyType}
            isSubsidyPercentLimit={subsidySettings.isSubsidyPercentLimit}
            hideModal={() => setShowModal(false)}
            forCalendarModal={true}
            itemPriceSettings={subsidySettings.itemPriceSettings}
          />
        </GroupOrderModal>
      )}
      {showChefNotif && (
        <ChefNotifs
          onHide={() => setShowChefNotif(false)}
          dateMenus={props.dateMenus}
          notifyChefs={props.notifyChefs}
          setAutoStagedMenus={setAutoStagedMenus}
          date={month}
        />
      )}
      {/*
        selectedOrder &&
        <OrderModal customizeOrder={ ( id, menuCard ) => props.customizeOrder( id, menuCard ) } order={ selectedOrder } hideModal={ () => setSelectedOrder( undefined ) } />
      */}
      <CalendarTools>
        <div className="calendar-title">
          <p>Orders for {Moment(month).clone().format('MMMM YYYY')}</p>
          <Arrows>
            <IoIosArrowBack fill="green" onClick={onChangeMonth(-1)} />
            <IoIosArrowForward fill="green" onClick={onChangeMonth(1)} />
          </Arrows>
        </div>
        <CalendarLegends>
          <div className="legend">
            <div className="panel-legend">
              <span style={{ background: '#f89eac' }} />
              <p>Unsaved menus</p>
            </div>
            <div className="panel-legend" style={{ marginLeft: '10px' }}>
              <span style={{ background: '#15acc1' }} />
              <p>Saved menus</p>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            {isSaving ? (
              <div style={{ height: '70px' }}>
                <img
                  style={{ height: '70px', width: '80px' }}
                  src={loadGif}
                  alt="loading image"
                />
              </div>
            ) : (
              <Button
                width="40%"
                margin="10px"
                backgroundColor={colors.orange}
                label="SAVE ALL"
                onClick={() => onSaveDateMenus()}
              />
            )}
            <Button
              width="55%"
              margin="10px"
              label="SELECT CHEF TO NOTIFY"
              onClick={() => setShowChefNotif(true)}
            />
          </div>
        </CalendarLegends>
      </CalendarTools>
      {isLoading ? (
        <div className="loading-gif mt-10">
          <img src={loadGif} alt="loading image" />
        </div>
      ) : (
        <Calendar>
          <table>
            <tr>
              {weekHeadings.map((s) => (
                <th key={s}>{s}</th>
              ))}
            </tr>
            {getDays()}
          </table>
        </Calendar>
      )}
    </CalendarContainer>
  )
}

const CalendarContainer = styled.div`
  width: max-content;
  margin-top: 10px;
`

const Calendar = styled.div`
  background: #fff;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  box-shadow:
    0 10px 15px -3px rgba(154, 167, 237, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  table {
    width: 100%;
  }
  th {
    font-family: 'regular';
    color: gray;
    opacity: 1;
    text-align: left;
    padding-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .calendar-date,
  .calendar-date-light {
    color: ${colors.dark500};
    font-family: 'regular';
    font-size: 17px;
    text-align: left;
    width: 20px;
    height: 20px;
    text-align: center;
  }
  .calendar-date-light {
    color: ${colors.dark500};
    opacity: 0.5;
  }
  .is-today {
    background: #3787e9;
    color: #fff;
    font-family: 'bold';
    border-radius: 10px;
  }
  td {
    border: 2px solid #e1e3ea;
    min-width: 25px;
    height: 60px;
    padding: 10px;
    text-align: left;
    &:hover {
      .calendar-button {
        opacity: 1;
      }
    }
  }
  .calendar-button {
    opacity: 0;
  }
`

const Orders = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 160px;
  width: 100%;
`

const Arrows = styled.div`
  width: 80px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const CalendarTools = styled.div`
  .calendar-title {
    color: gray;
    font-family: 'bold';
    font-size: 23px;
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin-bottom: 20px;
  }
`
const CalendarLegends = styled.div`
  display: flex;
  justify-content: space-between;
  .legend {
    display: flex;
    justify-content: flex-start;
  }
  .saveAllButton {
    display: flex;
    align-self: flex-end;
    background-color: #f89eac;
  }
`
const Menu = styled.div<{ background?: string }>`
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  background: ${(props) => props.background};
  .menu-heading {
    background: #15acc1;
    text-align: center;
    font-family: 'bold';
    color: #fff;
  }
  .menu-chef-name {
    font-family: 'bold';
    text-align: center;
    padding: 3px 5px;
  }
`

const MenuItem = styled.div`
  border-top: 1px solid #15acc1;
  padding: 3px 5px;
  font-size: 13px;
`

export default GroupOrderCalendar
