import React, { useState, useEffect } from 'react'
import Moment from 'moment'

import { Invoice, Client, InvoiceStatus } from './UseInvoiceSearch'
import LoadingIndicator from '@components/common/LoadingIndicator'
import LinkText from '@components/common/form/LinkText'
import { formatNumberWithCommas } from '@utils'

interface InvoicesTableProps {
  invoiceUrl: string
  invoices: Invoice[]
  loadingInvoices: boolean
  hideClientColumn?: boolean
  sortInvoices?: (attribute: keyof Invoice, transform?: (x: any) => any) => void
  invoicesSortByIcon?: (field: string) => React.ReactNode
  editInvoice: (id: string) => () => void
  onViewPDF: (invoice: Invoice, isSummary: boolean) => () => void
  getInvoiceHistory: (id: string, invoiceNumber: string) => void
}

export const InvoicesTable: React.FC<InvoicesTableProps> = ({
  invoiceUrl,
  invoices,
  loadingInvoices,
  hideClientColumn,
  sortInvoices,
  invoicesSortByIcon,
  editInvoice,
  onViewPDF,
  getInvoiceHistory,
}) => {
  const [openActionPopoverId, setOpenActionPopoverId] = useState<string | null>(
    null,
  )
  const [showOrderNumsTooltip, setShowOrderNumsTooltip] = useState<
    string | undefined
  >(undefined)

  const getStatusBgColor = (status: InvoiceStatus) => {
    switch (status) {
      case InvoiceStatus.Unpaid:
        return 'bg-orange-400'
      case InvoiceStatus.Paid:
        return 'bg-green-400'
      case InvoiceStatus.Resolved:
        return 'bg-blue-400'
      default:
        return 'bg-gray-300'
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Only close the popover if one is open and the click is outside the popover
      if (
        openActionPopoverId &&
        !(event.target as Element).closest('.action-popover-container')
      ) {
        setOpenActionPopoverId(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [openActionPopoverId])

  return (
    <div style={{ flex: '1 1 60%', marginRight: '20px' }} className="mt-4">
      <div
        className="relative border-2 border-gray-400 border-solid rounded-lg mx-2"
        style={{ height: '100vh' }}
      >
        {loadingInvoices && (
          <div className="flex justify-center items-center h-full">
            <LoadingIndicator />
          </div>
        )}
        {!loadingInvoices && (
          <div className="absolute inset-0 overflow-auto">
            <table className="w-full">
              <thead>
                <tr>
                  <th
                    className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10"
                    onClick={
                      sortInvoices
                        ? () => sortInvoices('invoiceNumber')
                        : undefined
                    }
                  >
                    Invoice Num{' '}
                    {invoicesSortByIcon
                      ? invoicesSortByIcon('invoiceNumber')
                      : null}
                  </th>
                  <th
                    className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10"
                    onClick={
                      sortInvoices ? () => sortInvoices('numOrders') : undefined
                    }
                  >
                    # Orders{' '}
                    {invoicesSortByIcon
                      ? invoicesSortByIcon('numOrders')
                      : null}
                  </th>
                  {!hideClientColumn && (
                    <th
                      className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10"
                      onClick={
                        sortInvoices
                          ? () =>
                              sortInvoices(
                                'buyer',
                                (client: Client) => client.name,
                              )
                          : undefined
                      }
                    >
                      Client{' '}
                      {invoicesSortByIcon ? invoicesSortByIcon('buyer') : null}
                    </th>
                  )}
                  <th
                    className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10"
                    onClick={
                      sortInvoices
                        ? () =>
                            sortInvoices('createdAt', (crAt) =>
                              Moment(crAt).unix(),
                            )
                        : undefined
                    }
                  >
                    Created At{' '}
                    {invoicesSortByIcon
                      ? invoicesSortByIcon('createdAt')
                      : null}
                  </th>
                  <th
                    className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10"
                    onClick={
                      sortInvoices
                        ? () =>
                            sortInvoices('serviceDateStart', (dt) =>
                              Moment(dt).unix(),
                            )
                        : undefined
                    }
                  >
                    Service Date Start{' '}
                    {invoicesSortByIcon
                      ? invoicesSortByIcon('serviceDateStart')
                      : null}
                  </th>
                  <th
                    className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10"
                    onClick={
                      sortInvoices
                        ? () =>
                            sortInvoices('serviceDateEnd', (dt) =>
                              Moment(dt).unix(),
                            )
                        : undefined
                    }
                  >
                    Service Date End{' '}
                    {invoicesSortByIcon
                      ? invoicesSortByIcon('serviceDateEnd')
                      : null}
                  </th>
                  <th
                    className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10"
                    onClick={
                      sortInvoices
                        ? () =>
                            sortInvoices('dueDate', (dueDate) =>
                              Moment(dueDate).unix(),
                            )
                        : undefined
                    }
                  >
                    Due Date{' '}
                    {invoicesSortByIcon ? invoicesSortByIcon('dueDate') : null}
                  </th>
                  <th
                    className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10"
                    onClick={
                      sortInvoices
                        ? () => sortInvoices('totalDue', (x) => parseFloat(x))
                        : undefined
                    }
                  >
                    Total Due{' '}
                    {invoicesSortByIcon ? invoicesSortByIcon('totalDue') : null}
                  </th>
                  <th
                    className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10"
                    onClick={
                      sortInvoices
                        ? () => sortInvoices('totalPaid', (x) => parseFloat(x))
                        : undefined
                    }
                  >
                    Total Paid{' '}
                    {invoicesSortByIcon
                      ? invoicesSortByIcon('totalPaid')
                      : null}
                  </th>
                  <th
                    className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10"
                    onClick={
                      sortInvoices ? () => sortInvoices('status') : undefined
                    }
                  >
                    Status{' '}
                    {invoicesSortByIcon ? invoicesSortByIcon('status') : null}
                  </th>
                  <th
                    className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10"
                    onClick={
                      sortInvoices ? () => sortInvoices('isPaid') : undefined
                    }
                  >
                    Paid?{' '}
                    {invoicesSortByIcon ? invoicesSortByIcon('isPaid') : null}
                  </th>
                  <th
                    className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10"
                    onClick={
                      sortInvoices
                        ? () => sortInvoices('isAllOrdersPaid')
                        : undefined
                    }
                  >
                    All Orders
                    <br />
                    Paid?{' '}
                    {invoicesSortByIcon
                      ? invoicesSortByIcon('isAllOrdersPaid')
                      : null}
                  </th>
                  <th
                    className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10"
                    onClick={
                      sortInvoices
                        ? () => sortInvoices('isResolved')
                        : undefined
                    }
                  >
                    Resolved?{' '}
                    {invoicesSortByIcon
                      ? invoicesSortByIcon('isResolved')
                      : null}
                  </th>
                  <th
                    className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10"
                    onClick={
                      sortInvoices ? () => sortInvoices('isAutoInv') : undefined
                    }
                  >
                    Auto-Batch?{' '}
                    {invoicesSortByIcon
                      ? invoicesSortByIcon('isAutoInv')
                      : null}
                  </th>
                  <th
                    className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10"
                    onClick={
                      sortInvoices ? () => sortInvoices('isInvalid') : undefined
                    }
                  >
                    Invalid?{' '}
                    {invoicesSortByIcon
                      ? invoicesSortByIcon('isInvalid')
                      : null}
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-bold text-gray-700 bg-gray-300 uppercase tracking-wider border-2 border-gray-700 border-solid cursor-pointer sticky top-0 z-10">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {invoices.map((invoice) => {
                  return (
                    <tr key={invoice.id}>
                      <td className="px-4 py-2 whitespace-nowrap text-sm">
                        <LinkText
                          label={invoice.invoiceNumber}
                          onClick={editInvoice(invoice.id)}
                        />
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm">
                        <div className="relative ml-1 inline-block">
                          <div
                            className="bg-blue-200 font-medium px-2 py-1 rounded flex items-center shadow cursor-pointer"
                            onMouseEnter={() => {
                              if (invoice.numOrders > 0) {
                                setShowOrderNumsTooltip(invoice.id)
                              }
                            }}
                            onMouseLeave={() => {
                              setShowOrderNumsTooltip(undefined)
                            }}
                          >
                            {invoice.numOrders}
                          </div>
                          {showOrderNumsTooltip === invoice.id && (
                            <div
                              className="absolute bottom-full left-0 mb-1 bg-gray-800 text-white text-xs rounded py-1 px-2 w-24 shadow-lg z-50 normal-case"
                              style={{
                                marginBottom: '8px',
                                marginLeft: '-20px',
                              }}
                            >
                              {invoice.orderNumbers.join(' ')}
                            </div>
                          )}
                        </div>
                      </td>
                      {!hideClientColumn && (
                        <td className="px-4 py-2 whitespace-nowrap text-sm">
                          {invoice.buyer?.name}
                        </td>
                      )}
                      <td className="px-4 py-2 whitespace-nowrap text-sm">
                        {Moment(invoice.createdAt).format('l')}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm">
                        {invoice.serviceDateStart
                          ? Moment(invoice.serviceDateStart).format('l')
                          : ''}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm">
                        {invoice.serviceDateEnd
                          ? Moment(invoice.serviceDateEnd).format('l')
                          : ''}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm">
                        {Moment(invoice.dueDate).format('l')}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm">
                        ${formatNumberWithCommas(invoice.totalDue)}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm">
                        ${formatNumberWithCommas(invoice.totalPaid)}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm">
                        <div
                          className={`px-2 py-1 rounded flex items-center ${getStatusBgColor(
                            invoice.status,
                          )}`}
                        >
                          {invoice.status}
                        </div>
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm">
                        {invoice.isPaid ? 'Yes' : 'No'}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm">
                        {invoice.isAllOrdersPaid ? 'Yes' : 'No'}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm">
                        {invoice.isResolved ? 'Yes' : 'No'}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm">
                        {invoice.isAutoInv ? 'Yes' : 'No'}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm">
                        {invoice.isInvalid ? (
                          <div className="px-2 py-1 rounded flex items-center bg-red-400 text-white text-xs">
                            {invoice.overBatchedOrders?.length} Invalid Order(s)
                          </div>
                        ) : (
                          'Valid'
                        )}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm relative">
                        <div className="action-popover-container">
                          <button
                            className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded text-xs"
                            onClick={() =>
                              setOpenActionPopoverId(
                                openActionPopoverId === invoice.id
                                  ? null
                                  : invoice.id,
                              )
                            }
                          >
                            Actions
                          </button>

                          {openActionPopoverId === invoice.id && (
                            <div className="absolute right-0 mt-1 bg-gray-100 shadow-lg rounded-md border-2 border-gray-200 border-solid z-20 w-48">
                              <div className="py-1">
                                <div
                                  className="px-4 py-2 text-sm text-blue-600 hover:bg-gray-300 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    window.open(`${invoiceUrl}/${invoice.id}`)
                                    setOpenActionPopoverId(null)
                                  }}
                                >
                                  Payment Form
                                </div>
                                <div
                                  className="px-4 py-2 text-sm text-blue-600 hover:bg-gray-300 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    onViewPDF(invoice, false)()
                                    setOpenActionPopoverId(null)
                                  }}
                                >
                                  Detail PDF
                                </div>
                                <div
                                  className="px-4 py-2 text-sm text-blue-600 hover:bg-gray-300 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    onViewPDF(invoice, true)()
                                    setOpenActionPopoverId(null)
                                  }}
                                >
                                  Summary PDF
                                </div>
                                <div
                                  className="px-4 py-2 text-sm text-blue-600 hover:bg-gray-300 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    getInvoiceHistory(
                                      invoice.id,
                                      invoice.invoiceNumber,
                                    )
                                    setOpenActionPopoverId(null)
                                  }}
                                >
                                  Audit History
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}
