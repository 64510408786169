import { BaseService } from 'hungry-core2'
import actions from '@actions'
import { CutoffTimeDayOffSetType } from '@types'

import {
  GROUP_ORDER_SUBSIDY_TYPE_FIXED,
  GroupOrderDeliveryFeeType,
} from '@components/groupOrders/constants'

export class GroupOrdersService extends BaseService {
  newDateMenu = () => ({
    id: undefined,
    date: undefined,
    dateStr: '',
    clientSettingsId: undefined,
    clientId: undefined,
    active: false,
    menus: [],
  })

  newClientSettings = ({ headquarter, client }) => ({
    id: undefined,
    name: '',
    authorizationCode: '',
    headquarter: headquarter.id,
    country: headquarter.country,
    currency: headquarter.currency,
    active: false,
    hidePrices: false,
    location: undefined,
    client: {
      id: client.id,
      name: client.name,
      pin: client.pin,
      contact: null,
      logoUrl: client.logoUrl,
    },
    subsidySettings: {
      subsidies: [this.newSubsidySetting()],
    },
    usesRacks: false,
    racksCount: 0,
    overflowRacks: 0,
    shelvesPerRack: 0,
    overflowName: '',
    salesRep: undefined,
    employees: [],
    deliveryFeeType: GroupOrderDeliveryFeeType.STANDARD,
    deliveryFee: 0,
    deliveryHeadcountLimit: 0,
    tipType: 'FixedTotal',
    tip: 0,
  })

  newClientGlobalSettings = ({ headquarter, client }) => ({
    id: undefined,
    headquarter: headquarter.id,
    clientId: client.id,
    limitItems: false,
  })

  newContact = ({ id, name, email, phoneNumber }) => ({
    id,
    name,
    email,
    phone: phoneNumber,
  })

  newSubsidySetting = () => ({
    isActive: false,
    fullySubsidized: false,
    limitPurchase: false,
    subsidyType: GROUP_ORDER_SUBSIDY_TYPE_FIXED,
    isSubsidyPercentLimit: false,
    perOrderSubsidy: 0.0,
    days: [],
  })

  newScheduleSettings = () => ({
    cutoffTimeSettings: {
      dayOffset: 2,
      dayOffsetType: CutoffTimeDayOffSetType.BUSINESS_DAYS,
    },
    dropoffTimeSettings: {},
  })

  setGroupOrderPage = ({ account, id, date, clientSettingsId }) => {
    this.dispatch(
      actions.setGroupOrderPage({ id, date, account, clientSettingsId }),
    )
  }

  clearGroupOrderPage = () => {
    this.dispatch(actions.clearGroupOrderPage())
  }

  setDateMenus = (dateMenus) => {
    this.dispatch(actions.setDateMenus({ dateMenus }))
  }

  updateDateMenus = ({ dateMenus, changedDateMenuMap }) => {
    this.dispatch(actions.updateDateMenus({ dateMenus, changedDateMenuMap }))
  }

  setChangedDateMenuMap = (changedDateMenuMap) => {
    this.dispatch(actions.setChangedDateMenuMap({ changedDateMenuMap }))
  }

  clearChangedDateMenuMap = () => {
    this.dispatch(actions.clearChangedDateMenuMap())
  }

  setAutoStagedMenus = (autoStagedMenus) => {
    this.dispatch(actions.setAutoStagedMenus({ autoStagedMenus }))
  }
}
