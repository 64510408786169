import React from 'react'
import Modal from '@components/common/modal/Modal'

interface InfoModalProps {
  title: string
  content: React.ReactNode
  close: () => void
}

const InfoModal = ({ title, content, close }: InfoModalProps) => {
  return (
    <Modal title={title} hideModal={close} color="#001940" width="1000px">
      {content}
    </Modal>
  )
}

export default InfoModal
