import { connect } from 'react-redux'

import { ChefFutureOrdersModal } from '@components/chef/chefSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'
import { Chef, ChefOrder, UpdateChefOrderAddressReq } from '@types'

const mapStateToProps = (state: {
  chef: Chef
  user: { lastLoggedInHq: number }
}) => {
  const { chef, user } = state
  const { lastLoggedInHq: headquarterId } = user || {}

  return {
    chef,
    headquarterId,
  }
}

const mapDispatchToProps = () => {
  const { UIService, RestService } = services
  const { LoadChefFutureOrders, UpdateUpcomingOrderAddresses } = coordinators
  const { pResponseGeneric } = presenters.Api
  const { close } = UIService.DisplayChefFutureOrders

  const loadOrders: (chefId: string, hqId: number) => Promise<ChefOrder[]> =
    LoadChefFutureOrders({
      RestService,
      pResponseGeneric,
      HandleError,
    })

  const updateOrders: (req: UpdateChefOrderAddressReq) => Promise<boolean> =
    UpdateUpcomingOrderAddresses({
      RestService,
      UIService,
      HandleError,
    })

  return {
    close,
    updateOrders,
    loadOrders,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChefFutureOrdersModal)
