import React, { useState, useEffect } from 'react'
import { AutocompleteInput } from '@containers/common/form'
import { InvoicesTable } from './InvoicesTable'
import { Invoice, SearchInvoicesParams } from './UseInvoiceSearch'

interface FindInvoiceProps {
  invoiceUrl: string
  searchInvoices: (params: SearchInvoicesParams) => Promise<Invoice[]>
  getInvoiceHistory: (id: string, invoiceNumber: string) => void
  onViewPDF: (invoice: Invoice, isSummary: boolean) => () => void
  onEditInvoice: (id: string) => () => void
  selectedInvoice?: Invoice
}

const FindInvoice: React.FC<FindInvoiceProps> = ({
  invoiceUrl,
  searchInvoices,
  getInvoiceHistory,
  onViewPDF,
  onEditInvoice,
  selectedInvoice,
}) => {
  const [invoiceSearch, setInvoiceSearch] = useState<string>('')
  const [localSelectedInvoice, setLocalSelectedInvoice] =
    useState<Invoice | null>(null)

  useEffect(() => {
    if (selectedInvoice) {
      setLocalSelectedInvoice(selectedInvoice)
    }
  }, [selectedInvoice])

  return (
    <div className="flex flex-col p-4">
      <div className="my-2 flex w-1/4">
        <AutocompleteInput
          label="Find Single Batch Invoice"
          width="75%"
          displayAttribute="invoiceNumber"
          loaderFunction={(req: { search: string }) =>
            searchInvoices({ limit: 20, search: req.search })
          }
          placeholder="Invoice #"
          value={invoiceSearch}
          onSelect={(invoice) => {
            setLocalSelectedInvoice(invoice)
            onEditInvoice(invoice.id)
          }}
          onClear={() => {
            setLocalSelectedInvoice(null)
            setInvoiceSearch('')
          }}
        />
      </div>

      {localSelectedInvoice && (
        <InvoicesTable
          invoiceUrl={invoiceUrl}
          invoices={[localSelectedInvoice]}
          loadingInvoices={false}
          editInvoice={onEditInvoice}
          onViewPDF={onViewPDF}
          getInvoiceHistory={getInvoiceHistory}
        />
      )}
    </div>
  )
}

export default FindInvoice
