import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { BiPencil, BiTrash } from 'react-icons/bi'
import { RiFileCopyLine } from 'react-icons/ri'
import { FiMail } from 'react-icons/fi'
import { Dropdown, LabelInfo } from '@components/common/form'
import Table from '@components/common/Table'
import YSpacing from '@components/common/YSpacing'
import DividerLine from '@components/common/DividerLine'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import {
  ModalSectionTitle,
  ButtonsBarLegend,
  ButtonsBar,
  ResponsiveTextDiv,
  Label,
} from '@res/styledComponents/index'
import { colors } from '../../../../constants'
import { AuthorizedInteractable } from '@containers/common/auth'
import LoadingIndicator from '@components/common/LoadingIndicator'
import { InvoiceClient } from '@containers/proposal/proposalSections/panel'
import { removeHtmlTags, isValidUrl } from '~/utils'
import { SECTION1, SECTION3, PAY_BY_CC } from '../../../../constants'
import { validateDinerProfileTags } from '@utils/menuItemUtils'

export class Overview extends Component {
  state = {
    width: 0,
    skipCCPresenceValidationForProposalId: undefined,
    skippedTagValidationForProposalId: undefined,
    isLoading: false,
  }

  handleResize = () =>
    this.setState({
      width: window.innerWidth,
    })

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  onChangeStatus = (status) => {
    this.props.changeProposalStatus(status)
  }

  onClickEdit = () => {
    this.props.editProposal(SECTION3)
  }

  onSkipCCPresenceValidation = () => {
    const { proposal } = this.props
    this.setState({ skipCCPresenceValidationForProposalId: proposal.id })
  }

  proposalToOrderCallback = (order) => {
    const { uri } = this.props
    window.open(`${uri}/sales/${order.id}`)
  }

  validateDinerProfile = async () => {
    const { proposal, createOrder } = this.props

    const dinerProfile = await this.props.loadDinerProfile(
      proposal.dinerProfileId,
    )
    const allItems = proposal.chefs.reduce((allItems, chef) => {
      const chefMenuItems = (chef.orderMenuItems || []).reduce(
        (items, item) => {
          if (item.menuItem) {
            items.push(item.menuItem)
          } else {
            items.push(item)
          }

          return items
        },
        [],
      )
      allItems.push(...chefMenuItems)

      return allItems
    }, [])

    const { includeWarningTags, excludeWarningTags } = validateDinerProfileTags(
      allItems,
      dinerProfile,
    )
    if (includeWarningTags.length > 0 || excludeWarningTags.length > 0) {
      const msg = `This proposal does not contain menu items that meet the requirements of the Diner Profile used: ${
        includeWarningTags.length > 0
          ? `Must include: ${includeWarningTags.join(', ')}`
          : ''
      }${
        includeWarningTags.length > 0 && excludeWarningTags.length > 0 && ' | '
      }${
        excludeWarningTags.length > 0
          ? `Must exclude: ${excludeWarningTags.join(', ')}`
          : ''
      }. Are you sure you want to proceed to create the order?`

      this.props.flashWarningOptions(msg, {
        buttonAction: async () => {
          this.setState({ isLoading: true })
          await createOrder(this.proposalToOrderCallback)
          this.setState({ isLoading: false })
        },
        buttonTitle: 'Confirm',
        showCancelButton: true,
      })
    } else {
      this.setState({ isLoading: true })
      await createOrder(this.proposalToOrderCallback)
      this.setState({ isLoading: false })
    }
  }

  onCreateOrder = () => {
    const { proposal, flashError, createOrder } = this.props
    const {
      paymentMethod,
      servicesInstructions,
      account,
      dropoffAddress,
      orderType,
      beoLink,
    } = proposal
    const { buildingInstructions } = dropoffAddress
    const payByMethod = account ? account.payByMethod : ''

    if (orderType === 'Event' && !isValidUrl(beoLink)) {
      flashError(
        'Missing or invalid Event Checklist link on the Event Order. Please add your Event Checklist/BEO link to the order on the Order Details page then you will be able to book the order.',
      )

      return
    }

    if (
      (!servicesInstructions ||
        removeHtmlTags(servicesInstructions).replace(/\s+/g, '').length ===
          0) &&
      (!buildingInstructions || buildingInstructions.length === 0)
    ) {
      flashError('Must include Delivery, Parking, & Set Up Instructions')

      return
    }

    if (!paymentMethod && payByMethod == PAY_BY_CC) {
      this.props.flashWarningOptions(
        'This client pays by credit card. Are you sure you want to proceed without including a payment method?',
        {
          buttonAction: async () => {
            this.onSkipCCPresenceValidation
            this.setState({ isLoading: true })
            if (proposal.dinerProfileId) {
              await this.validateDinerProfile()
            } else {
              await createOrder(this.proposalToOrderCallback)
            }
            this.setState({ isLoading: false })
          },
          buttonTitle: 'Confirm',
          showCancelButton: true,
        },
      )

      return
    }

    this.props.flashInfoOptions(
      'Are you sure you want to create an order from this Proposal?',
      {
        buttonAction: async () => {
          if (proposal.dinerProfileId) {
            await this.validateDinerProfile()
          } else {
            this.setState({ isLoading: true })
            await createOrder(this.proposalToOrderCallback)
            this.setState({ isLoading: false })
          }
        },
        buttonTitle: 'Confirm',
      },
    )
  }

  copyProposalCallback = (proposal) => {
    const { uri } = this.props
    window.open(`${uri}/proposals/?proposal_id=${proposal.id}`)
  }

  onCopyProposal = () => {
    this.props.copyProposal(this.copyProposalCallback)
  }

  onClickDelete = () => {
    const { deleteProposal, proposal } = this.props
    deleteProposal(proposal)
  }

  calculateSubtotalPreTaxPricePerHead = () => {
    const { headCount, subtotal } = this.props.proposal

    return headCount ? parseFloat(subtotal) / headCount : 0
  }

  calculateTotalPostTaxPricePerHead = () => {
    const { headCount, total } = this.props.proposal
    if (headCount) {
      return parseFloat(total) / headCount
    }

    return 0
  }

  calculateMargin() {
    const { calculateDiscounts, calculateGrossMargin, pResponseEditProposal } =
      this.props
    const proposal = pResponseEditProposal({
      orderableJson: this.props.proposal,
      calculateDiscounts,
    })
    const {
      adjustedChefPayouts,
      chefs,
      tip,
      total,
      tax,
      predictedServiceCosts,
      supplyBreakdown,
      carbonNeutralContribution,
    } = proposal

    const suppliesCost = (supplyBreakdown && supplyBreakdown.total.cost) || 0

    const margin =
      calculateGrossMargin({
        adjustedChefPayouts,
        chefs,
        tip: Number(tip) || 0,
        total: Number(total) || 0,
        tax: Number(tax) || 0,
        predictedServiceCosts: Number(predictedServiceCosts) || 0,
        suppliesCost,
        carbonNeutralContribution: Number(carbonNeutralContribution) || 0,
      }) || 0

    return margin
  }

  onEditSection = (section) => {
    this.props.editProposal(section)
  }

  renderEditButtons() {
    const { needAlertClient, validOrder } = this.props.proposal
    const actionRequired = needAlertClient || !validOrder
    const needAttentionStyles = { color: colors.violet, fontFamily: 'bold' }
    const completedStyles = { color: colors.blue, fontFamily: 'bold' }

    return (
      <div>
        <ButtonsBarLegend>
          <p>
            <span style={{ background: colors.blue }} />
            Completed
          </p>
          <p>
            <span style={{ background: colors.violet }} />
            Incomplete
          </p>
        </ButtonsBarLegend>
        <ButtonsBar>
          <button
            style={actionRequired ? needAttentionStyles : completedStyles}
            onClick={() => this.setState({ sendProposalModal: true })}
          >
            <FiMail />
            Send Proposal to Client
          </button>
          <DividerLine />
          <YSpacing height="10px" />
          <FlexContainer>
            <AuthorizedInteractable
              roles={['master admin', 'sales rep', 'sales lead', 'chef lead']}
            >
              <button onClick={this.onClickEdit}>
                <BiPencil />
                Edit
              </button>
            </AuthorizedInteractable>
            <AuthorizedInteractable
              roles={['master admin', 'sales rep', 'sales lead', 'chef lead']}
            >
              <button onClick={this.onCreateOrder}>
                <BiPencil />
                Create Order{' '}
              </button>
            </AuthorizedInteractable>
            <AuthorizedInteractable
              roles={['master admin', 'sales rep', 'sales lead', 'chef lead']}
            >
              <button onClick={this.onCopyProposal}>
                <RiFileCopyLine />
                Copy Proposal
              </button>
            </AuthorizedInteractable>
            <AuthorizedInteractable roles={['master admin', 'sales rep']}>
              <button onClick={this.onClickDelete}>
                <BiTrash /> Delete Proposal{' '}
              </button>
            </AuthorizedInteractable>
          </FlexContainer>
        </ButtonsBar>
      </div>
    )
  }

  getChefOrderInstructions = (chef) => {
    const {
      proposal: { chefOrderInstructions },
    } = this.props

    const chefInstrs = chefOrderInstructions.find((c) => c.chefId === chef.id)

    return chefInstrs && chefInstrs.words.length > 0 ? chefInstrs.words : 'N/A'
  }

  getAvgPricePerPerson = () => {
    const {
      proposal: {
        account: { avgPricePerPerson },
        dinerProfile,
      },
    } = this.props
    const { budgetPerEmployee } = dinerProfile || {}

    if (budgetPerEmployee && parseFloat(budgetPerEmployee)) {
      return `$${parseFloat(budgetPerEmployee).toFixed(2)}`
    } else if (avgPricePerPerson && parseFloat(avgPricePerPerson)) {
      return `$${parseFloat(avgPricePerPerson).toFixed(2)}`
    } else {
      return 'N/A'
    }
  }

  render() {
    const { proposal } = this.props
    const { width, sendProposalModal, isLoading } = this.state
    if (!proposal) {
      return null
    }
    if (isLoading) {
      return <LoadingIndicator />
    }
    const {
      orderNumber,
      order,
      orderType,

      dateStr,
      clientSetUpTimeStr,
      dropoffAddress,

      headCount,
      statuses,
      setUpStyle,
      discount,
      discounts,

      accountExecutive,
      account,
      contact,
      invoiceContact,
      receiptContact,

      needsStaffing,
      needsCleanup,

      chefs,
      serviceFee,
      subtotal,
      tax,
      tip,
      total,
      totalDiscount,
      carbonNeutral,
      carbonNeutralContribution,
    } = proposal
    const proposalStatus = (statuses[0] && statuses[0].code) || 'created'
    const dropoffAddressString =
      dropoffAddress &&
      `${dropoffAddress.line1} ${dropoffAddress.line2}, ${dropoffAddress.city} ${dropoffAddress.state}, ${dropoffAddress.zip}`
    const margin = this.calculateMargin()
    const marginColor = margin >= 30 ? 'green' : 'red'
    const colWidth = width > 1400 ? '31%' : '48%'

    let menuItemsTotal = 0
    let chefPriceTotal = 0
    let serviceItemsTotal = 0
    let serviceItemsChefTotal = 0

    return (
      <div>
        {this.renderEditButtons()}
        <FlexContainer flexDirection="row" width="100%">
          <FlexContainer width="40%" flexDirection="column">
            <Panel
              width="100%"
              maxWidth="450px"
              heading="Proposal Details"
              isEdit={true}
              onEditCallBack={() => this.onEditSection(SECTION1)}
            >
              <div className="flex flex-wrap">
                <LabelInfo
                  label="Proposal Number"
                  value={orderNumber}
                  width={colWidth}
                  spacing="10px"
                />
                {order && (
                  <LabelInfo
                    label="Created Order"
                    value={order}
                    width={colWidth}
                    spacing="10px"
                  />
                )}
                <LabelInfo
                  label="Client Name"
                  value={account ? account.name : ''}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="Event Date & Time"
                  value={dateStr}
                  width={colWidth}
                  spacing="10px"
                />

                <LabelInfo
                  label="Head Count"
                  value={headCount}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="Order Type"
                  value={orderType}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="Client Set Up Time"
                  value={clientSetUpTimeStr}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="Set up Style"
                  value={setUpStyle}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="Add'l Staff?"
                  value={needsStaffing ? 'Yes' : 'No'}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="VIP Clean Up"
                  value={needsCleanup ? 'Yes' : 'No'}
                  width={colWidth}
                  spacing="10px"
                />
                <LabelInfo
                  label="Event Address"
                  value={dropoffAddressString}
                  width="100%"
                />
                <YSpacing height="10px" />
                <AuthorizedInteractable
                  roles={[
                    'master admin',
                    'sales lead',
                    'sales rep',
                    'chef lead',
                  ]}
                >
                  <Dropdown
                    label="Proposal Status"
                    value={proposalStatus}
                    onChange={this.onChangeStatus}
                  >
                    {['created', 'final'].map((o) => (
                      <option key={o} value={o}>
                        {o}
                      </option>
                    ))}
                  </Dropdown>
                </AuthorizedInteractable>
              </div>
            </Panel>
            <Panel
              width="100%"
              maxWidth="450px"
              heading="Proposal Margin Details"
              isEdit={true}
              onEditCallBack={() => this.onEditSection(SECTION3)}
            >
              <FlexContainer
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
              >
                <FlexContainer flexDirection="column" width="47%">
                  <ModalSectionTitle>Client Pricing</ModalSectionTitle>
                  <YSpacing height="10px" />
                  <FlexContainer width="100%" justifyContent="space-between">
                    <div> Subtotal: </div>
                    <div>${subtotal} </div>
                  </FlexContainer>
                  <FlexContainer width="100%" justifyContent="space-between">
                    <div> Discounts: </div>
                    <div>${totalDiscount} </div>
                  </FlexContainer>
                  <FlexContainer width="100%" justifyContent="space-between">
                    <div> Service Fee: </div>
                    <div>${serviceFee} </div>
                  </FlexContainer>
                  <FlexContainer width="100%" justifyContent="space-between">
                    <div> Tax: </div>
                    <div>${tax} </div>
                  </FlexContainer>
                  <FlexContainer width="100%" justifyContent="space-between">
                    <div> Gratuity: </div>
                    <div>${tip} </div>
                  </FlexContainer>
                  {carbonNeutral && (
                    <FlexContainer width="100%" justifyContent="space-between">
                      <div> Carbon Neutral Contribution: </div>
                      <div>${carbonNeutralContribution} </div>
                    </FlexContainer>
                  )}
                  <FlexContainer width="100%" justifyContent="space-between">
                    <div> Total: </div>
                    <div>${total} </div>
                  </FlexContainer>
                  <YSpacing height="10px" />
                </FlexContainer>

                <FlexContainer flexDirection="column" width="47%">
                  {account && (
                    <FlexContainer flexDirection="column">
                      <LabelInfo
                        label="Est. Pre-Tax Price Per Head"
                        value={`$${this.calculateSubtotalPreTaxPricePerHead().toFixed(
                          2,
                        )}`}
                      />
                      <YSpacing height="10px" />
                      <LabelInfo
                        label="Est. Post-Tax Price Per Head"
                        value={`$${this.calculateTotalPostTaxPricePerHead().toFixed(
                          2,
                        )}`}
                      />
                      <YSpacing height="10px" />
                      <LabelInfo
                        label="Price Per Person"
                        value={this.getAvgPricePerPerson()}
                      />
                      <YSpacing height="10px" />
                      <ModalSectionTitle>Estimated Margin</ModalSectionTitle>
                      <b style={{ color: marginColor }}>{margin}%</b>
                    </FlexContainer>
                  )}
                </FlexContainer>
              </FlexContainer>
              <FlexContainer width="47%" justifyContent="space-between">
                <ModalSectionTitle>Discount Added?</ModalSectionTitle>
                <div>{discount ? 'Yes' : 'No'}</div>
              </FlexContainer>
              <YSpacing height="10px" />
              <FlexContainer width="60%" justifyContent="space-between">
                {discount && (
                  <div>
                    <LabelInfo label="Kind" value={discount.kind} />
                    <LabelInfo label="Reason" value={discount.reason} />
                  </div>
                )}
                {discounts.map((d, i) => (
                  <div key={i}>
                    <LabelInfo label="Kind" value={d.kind} />
                    <LabelInfo label="Reason" value={d.reason} />
                  </div>
                ))}
              </FlexContainer>
            </Panel>
          </FlexContainer>
          <FlexContainer width="60%" flexDirection="column">
            {account && (
              <Panel
                width="100%"
                maxWidth="100%"
                heading="Client Point of Contact(s)"
                isEdit={true}
                onEditCallBack={() => this.onEditSection(SECTION1)}
              >
                <FlexContainer
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <LabelInfo
                    label="Order Contact"
                    value={contact ? contact.name : ''}
                    width="31%"
                  />
                  <LabelInfo
                    label="Email"
                    value={contact ? contact.email : ''}
                    width="31%"
                  />
                  <LabelInfo
                    label="Phone"
                    value={contact ? contact.phoneNumber : ''}
                    width="31%"
                  />
                </FlexContainer>
                <YSpacing height="20px" />
                <FlexContainer
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <LabelInfo
                    label="Invoice Contact"
                    value={invoiceContact ? invoiceContact.name : ''}
                    width="31%"
                  />
                  <LabelInfo
                    label="Email"
                    value={invoiceContact ? invoiceContact.email : ''}
                    width="31%"
                  />
                  <LabelInfo
                    label="Phone"
                    value={invoiceContact ? invoiceContact.phoneNumber : ''}
                    width="31%"
                  />
                </FlexContainer>
                <YSpacing height="20px" />
                <FlexContainer
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <LabelInfo
                    label="Receipt Contact"
                    value={receiptContact ? receiptContact.name : ''}
                    width="31%"
                  />
                  <LabelInfo
                    label="Email"
                    value={receiptContact ? receiptContact.email : ''}
                    width="31%"
                  />
                  <LabelInfo
                    label="Phone"
                    value={receiptContact ? receiptContact.phoneNumber : ''}
                    width="31%"
                  />
                </FlexContainer>
              </Panel>
            )}
            <FlexContainer
              flexDirection="row"
              maxWidth="750px"
              justifyContent="space-between"
            >
              <Panel
                width="27%"
                heading="Sales Rep"
                isEdit={true}
                onEditCallBack={() => this.onEditSection(SECTION1)}
              >
                {accountExecutive !== null ? (
                  <Fragment>
                    <div>{`${accountExecutive.firstName} ${accountExecutive.lastName}`}</div>
                    <YSpacing height="20px" />
                    <div>{accountExecutive.email}</div>
                    <YSpacing height="20px" />
                    <div>{accountExecutive.phoneNumber}</div>
                  </Fragment>
                ) : (
                  <div>Not selected</div>
                )}
              </Panel>

              <Panel width="70%" heading="Chef Pickup Addresses & Contact Info">
                <FlexContainer width="100%" flexDirection="column">
                  {chefs.map((chef) => (
                    <>
                      <FlexContainer
                        key={chef.id}
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <ModalSectionTitle>{chef.name}</ModalSectionTitle>
                        <div>{chef.pickupAddress}</div>
                      </FlexContainer>
                      <YSpacing key={`${chef.id}-spacing-1`} height="10px" />
                      <FlexContainer
                        key={`${chef.id}-contact-headings`}
                        justifyContent="space-between"
                      >
                        <FlexContainer width="24%" flexDirection="row">
                          <ModalSectionTitle>EMAIL</ModalSectionTitle>
                        </FlexContainer>
                        <FlexContainer width="20%" flexDirection="column">
                          <ModalSectionTitle>PHONE</ModalSectionTitle>
                        </FlexContainer>
                        <FlexContainer width="31%">
                          <ModalSectionTitle>CHEF NOTES</ModalSectionTitle>
                        </FlexContainer>
                      </FlexContainer>
                      <FlexContainer
                        key={`${chef.id}-contact-info`}
                        justifyContent="space-between"
                      >
                        <ResponsiveTextDiv width="24%">
                          {chef.email}
                        </ResponsiveTextDiv>
                        <ResponsiveTextDiv width="20%">
                          {chef.phoneNumber}
                        </ResponsiveTextDiv>
                        <ResponsiveTextDiv width="31%">
                          {this.getChefOrderInstructions(chef)}
                        </ResponsiveTextDiv>
                      </FlexContainer>
                      <YSpacing key={`${chef.id}-spacing-2`} height="20px" />
                    </>
                  ))}
                </FlexContainer>
              </Panel>
            </FlexContainer>
            <Panel
              width="100%"
              maxWidth="100%"
              heading={'Menu Details & Chef Pricing'}
              isScroll={true}
              isEdit={true}
              onEditCallBack={() => this.onEditSection(SECTION3)}
            >
              <div style={{ maxHeight: '500px', overflow: 'scroll' }}>
                <Table
                  isStickyHeading={true}
                  headings={[
                    'Chef',
                    'Item',
                    'Type',
                    'Packaging',
                    'QTY',
                    'Price',
                    'Total',
                    'Chef Pricing',
                    'Food Margin',
                  ]}
                >
                  {proposal.proposalMenuItems.map((o) => {
                    const price = Number(o.price)
                    const cost = Number(o.cost)
                    const mealType = o.menuItem
                      ? o.menuItem.isSubMenuItem
                        ? o.menuItem.subMenuItemCategory
                        : o.menuItem.mealType
                      : ''
                    const margin =
                      price === 0.0 || o.quantity === 0
                        ? 0
                        : Math.trunc(((price - cost) / price) * 100)

                    menuItemsTotal += Number(o.price) * o.quantity
                    chefPriceTotal += Number(o.cost) * o.quantity

                    let packaging = 'None'
                    if (
                      !!proposal.supplyBreakdown &&
                      !!proposal.supplyBreakdown.orderItems[o.id]
                    ) {
                      packaging = proposal.supplyBreakdown.orderItems[
                        o.id
                      ].packaging
                        .map((p) => {
                          return `${p.count}x ${p.name}`
                        })
                        .join(', ')
                    }

                    return (
                      <tr key={o.id}>
                        <td>{o.chefName}</td>
                        <td>{o.name}</td>
                        <td>{mealType}</td>
                        <td>{packaging}</td>
                        <td>{o.quantity}</td>
                        <td className="right">${price.toFixed(2)}</td>
                        <td className="right">
                          ${(price * o.quantity).toFixed(2)}
                        </td>
                        <td className="right">
                          ${(cost * o.quantity).toFixed(2)}
                        </td>
                        <td className="right">
                          {margin == null ? 'N/A' : `${margin.toFixed(2)}%`}
                        </td>
                      </tr>
                    )
                  })}
                  <tfoot style={{ position: 'sticky', bottom: 0 }}>
                    <tr style={{ background: colors.gray100, color: 'white' }}>
                      <td colSpan={5}></td>
                      <td className="right">TOTAL</td>
                      <td className="right">${menuItemsTotal.toFixed(2)}</td>
                      <td className="right">${chefPriceTotal.toFixed(2)}</td>
                      <td className="right">
                        {chefPriceTotal == 0
                          ? 'N/A'
                          : `${Math.trunc(
                              ((menuItemsTotal - chefPriceTotal) * 100) /
                                menuItemsTotal,
                            ).toFixed(2)}%`}
                      </td>
                    </tr>
                  </tfoot>
                </Table>
                {proposal.proposalServiceItems.length > 0 && (
                  <>
                    <YSpacing height="20px" />
                    <Label>Service Items</Label>
                    <Table
                      isStickyHeading={true}
                      headings={[
                        'Chef',
                        'Service Item',
                        'QTY',
                        'Price',
                        'Total',
                        'Chef Pricing',
                      ]}
                    >
                      {proposal.proposalServiceItems.map((item) => {
                        const price = Number(item.price)
                        const cost = Number(item.cost)
                        serviceItemsTotal += price * item.quantity
                        serviceItemsChefTotal += cost * item.quantity

                        return (
                          <tr key={item.id}>
                            <td>{item.chefName}</td>
                            <td>{item.name}</td>
                            <td>{item.quantity}</td>
                            <td>${price}</td>
                            <td>${(price * item.quantity).toFixed(2)}</td>
                            <td>${(cost * item.quantity).toFixed(2)}</td>
                          </tr>
                        )
                      })}
                      <tfoot style={{ position: 'sticky', bottom: 0 }}>
                        <tr
                          style={{ background: colors.gray100, color: 'white' }}
                        >
                          <td colSpan={3}></td>
                          <td>TOTAL</td>
                          <td>${serviceItemsTotal.toFixed(2)}</td>
                          <td>${serviceItemsChefTotal.toFixed(2)}</td>
                        </tr>
                      </tfoot>
                    </Table>
                  </>
                )}
              </div>
            </Panel>
          </FlexContainer>
        </FlexContainer>
        {sendProposalModal && (
          <InvoiceClient
            proposal={proposal}
            hideModal={() => this.setState({ sendProposalModal: false })}
          />
        )}
      </div>
    )
  }
}

Overview.propTypes = {
  proposal: PropTypes.object,
  userPermissions: PropTypes.array,
  uri: PropTypes.string,

  flashError: PropTypes.func,
  changeProposalStatus: PropTypes.func,
  calculateDiscounts: PropTypes.func,
  calculateGrossMargin: PropTypes.func,
  copyProposal: PropTypes.func,
  createOrder: PropTypes.func,
  deleteProposal: PropTypes.func,
  editProposal: PropTypes.func,
  flashInfoOptions: PropTypes.func,
  flashWarningOptions: PropTypes.func,
  getDeliveryFeePercentAndLimit: PropTypes.func,
  loadDinerProfile: PropTypes.func,
  pResponseEditProposal: PropTypes.func,
}

export default Overview
