import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import Select from 'react-select'
import { statuses } from './constants'
import { AutocompleteInput } from '@containers/common/form'
import styled from '@emotion/styled'
import { deepCopy } from '~/utils'
import { colors } from '../../../constants'

import trash from '@res/images/trash-icon.png'

import { AuthorizedInteractable } from '@containers/common/auth'
import { Checkbox, DateTextInput } from '@components/common/form'
import PopUpCalculator from '@components/popUps/PopUpCalculator'
import Modal from '@components/common/modal/Modal'
import Table from '@components/common/Table'
import Input from '@components/common/form/Input'
import TextArea from '@components/common/form/TextArea'
import Button from '@components/common/form/Button'
import LabelInfo from '@components/common/form/LabelInfo'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import Dropdown from '@components/common/form/Dropdown'
import LinkText from '@components/common/form/LinkText'
import DividerLine from '@components/common/DividerLine'

const DateFormat = 'MM/DD/YYYY h:mm a'

class PopUpModal extends Component {
  state = {
    popUp: undefined,
    accountingPop: undefined,
    selectedMenu: undefined,
    addOnChef: undefined,
    availableServiceItems: [],
    addOns: [],
    loadingAddOnChef: false,
    oldPrepaidAmount: undefined,
    oldPrepaid: false,
    showPayments: false,
    rPopup: undefined,
    delay: false,
    quantityErrors: {},
  }
  disableSave = false
  saving = false
  taxTimer = undefined

  componentWillMount() {
    const { headquarter, newPopUp, popUp, searchPopUpConcepts } = this.props

    const newState = {}
    if (!popUp) {
      newState.popUp = newPopUp({ headquarter })
    } else {
      newState.popUp = popUp
      newState.oldPrepaidAmount =
        popUp.prePaymentAmount && parseFloat(popUp.prePaymentAmount)
    }
    this.setState(newState, this.recalcIfNoBalances)
    if (newState.popUp.client) {
      this.loadClient(newState.popUp.client)
    }
    if (newState.popUp.recurringId) {
      ;(async () => {
        const rPopups = await this.searchRecurringPopUps()
        const rPopup = rPopups.find((r) => r.id === newState.popUp.recurringId)
        if (rPopup) {
          this.setState({ rPopup }, () => this.setState({ delay: true }))
        } else {
          this.setState({ delay: true })
        }
      })()
    } else {
      this.setState({ delay: true })
    }
    if (newState.popUp.menu) {
      ;(async () => {
        const menus = await searchPopUpConcepts()
        const selectedMenu = menus.find((m) => m.id === newState.popUp.menu.id)
        if (selectedMenu) {
          this.setState({ selectedMenu }, () =>
            this.loadServiceItems(selectedMenu.chef.id),
          )
        }
      })()
    }
  }

  searchRecurringPopUps = async () => {
    const { searchRecurringPopUps, headquarter } = this.props
    const rPopUps = await searchRecurringPopUps({
      headquarter,
      statuses: ['Active', 'Inactive'],
    })

    return [
      {
        name: 'None',
        id: '',
      },
    ].concat(rPopUps)
  }

  onSelectRecurringPopup = (rPopup) => {
    const { popUp } = this.state
    this.setState({
      rPopup,
      popUp: {
        ...popUp,
        recurringId: rPopup.id,
      },
    })
  }

  recalcIfNoBalances = () => {
    const { popUp } = this.state
    if (popUp.marketSubtotal === null || popUp.chefSubtotal === null) {
      this.calculateBalances()
    }
  }

  loadClient = async (popUpClient) => {
    const client = await this.props.getPopUpClient(popUpClient.id)
    if (client) {
      const { popUp } = this.state
      const existClient = popUp.client || {}
      this.setState({
        popUp: { ...popUp, client: { ...existClient, ...client } },
      })
    }
  }

  onCopyPopUp = () => {
    this.props.copy(this.state.popUp.id)
  }

  onChangePopUp = (key, value) => {
    const { popUp } = this.state
    popUp[key] = value
    this.setState({ popUp: { ...popUp } })
  }

  checkIsPastEditBalanceCutoff = () => {
    const { popUp } = this.state
    const {
      checkOrderIsEditableNow,
      orderBalancesEditCutoffDate,
      userPermissions,
    } = this.props
    const isBeforeEditCutoff = checkOrderIsEditableNow(popUp.end)
    const isAdmin = userPermissions.some((permission) => permission === 'admin')
    if (!isBeforeEditCutoff && !isAdmin) {
      const editCutoff = orderBalancesEditCutoffDate(popUp.end)
      this.props.displayWarningMessage(
        `The cutoff time for editing balances for this order has passed. The cutoff time was ${editCutoff.format(
          'MMMM Do YYYY, h:mm:ss a',
        )}. Please reach out to support if assistance is needed.`,
      )

      return true
    }

    return false
  }

  ensureIsBeforeBalanceCutoff = (fn) => (fnArgs) => {
    if (this.checkIsPastEditBalanceCutoff()) {
      return
    }

    return fn(fnArgs)
  }

  onChangePrePaymentAmt = async (e) => {
    const { popUp, oldPrepaidAmount } = this.state
    if (!popUp.location) {
      this.props.displayWarningMessage(
        'Must set location before entering a subsidy amount',
      )

      return
    }
    popUp.prePaymentAmount = parseFloat(e.target.value)
    if (Number.isNaN(popUp.prePaymentAmount)) {
      popUp.prePaymentAmount = 0.0
    }
    if (!oldPrepaidAmount || popUp.prePaymentAmount > oldPrepaidAmount) {
      popUp.prePaid = false
    }
    this.setState({ popUp: { ...popUp } })
    const { prePaymentAmount, location } = popUp
    this.calcTaxAfterTimeout(
      prePaymentAmount,
      location.line1,
      location.city,
      location.state,
      location.zip,
    )
  }

  onCalcTax = async (prePaymentAmount, line1, city, state, zip) => {
    const { popUp } = this.state
    const prePaymentTax = await this.props.getPopUpPrePaymentTax(
      popUp.client.id,
      prePaymentAmount,
      line1,
      city,
      state,
      zip,
    )
    if (prePaymentTax != null) {
      this.setState({ popUp: { ...popUp, prePaymentTax } })
    }
    this.disableSave = false
  }

  calcTaxAfterTimeout = (prePaymentAmount, line1, city, state, zip) => {
    this.disableSave = true
    if (this.taxTimer) {
      clearTimeout(this.taxTimer)
    }
    this.taxTimer = undefined
    this.taxTimer = setTimeout(() => {
      this.onCalcTax(prePaymentAmount, line1, city, state, zip)
    }, 1000)
  }

  onSelectClient = (client) => {
    const { popUp } = this.state
    this.setState({
      popUp: {
        ...popUp,
        client,
      },
    })
  }

  onSelectSalesRep = (salesRep) => {
    const { popUp } = this.state
    this.setState({
      popUp: {
        ...popUp,
        salesRep,
      },
    })
  }

  onSelectContact = (id) => {
    const { popUp } = this.state
    const contact = popUp.client.contacts.find((ct) => ct.id === id)
    this.setState({
      popUp: {
        ...popUp,
        client: {
          ...popUp.client,
          contact,
        },
      },
    })
  }

  onChangeLocation = (id) => {
    const { popUp } = this.state
    const location = popUp.client.locations.find((loc) => loc.id === id)
    const start = popUp.start || Moment.tz(this.props.locale)
    this.setState({
      popUp: {
        ...popUp,
        location,
        start,
      },
    })
  }

  onChangeStatus = async (status) => {
    const doChange = await this.props.showConfirmationModal({
      text: `Are you sure you want to change status to ${status}?`,
    })
    if (!doChange) {
      return
    }
    const { popUp } = this.state
    this.setState({
      popUp: {
        ...popUp,
        status,
      },
    })
  }

  onChangeStart = (start) => {
    const { popUp } = this.state
    const date = Moment.tz(start, this.props.locale).format('YYYY-MM-DD')
    const end = popUp.end || start
    this.setState({ popUp: { ...popUp, start, end, date } })
  }

  onDeletePopUp = async () => {
    const { deletePopUp, searchPopUps, hideModal } = this.props
    const { popUp } = this.state
    const { id, name } = popUp
    if (await deletePopUp({ id, name })) {
      hideModal()
      searchPopUps()
    }
  }

  onSavePopUp = async () => {
    if (this.disableSave) {
      this.props.displayWarningMessage(
        'Please wait for subsidy tax to finish calculating...',
      )

      return
    }
    const {
      savePopUp,
      searchPopUps,
      hideModal,
      userPermissions,
      displayFailureMessage,
    } = this.props
    const { popUp } = this.state
    const isPermissible = userPermissions.some(
      (permission) => permission === 'admin' || permission === 'finance',
    )
    const isEditable = this.checkIfEditable(popUp.date)
    if (!isEditable && !isPermissible) {
      displayFailureMessage(
        'Cannot modify orders past the 10th of the month following the order. Please reach out to accounting@tryhungry.com to assist',
      )

      return
    }
    if (this.saving) {
      return
    }
    this.saving = true
    if (this.validateQuantities() && (await savePopUp(popUp))) {
      hideModal()
      searchPopUps()
    }
    this.saving = false
  }

  validateQuantities = () => {
    let message = ''
    const { menu } = this.state.popUp
    const { quantityErrors } = this.state
    const allIts = [...menu.entrees, ...menu.sides, ...menu.desserts]
    allIts.forEach((it) => {
      if (parseFloat(it.quantity) < it.minQty) {
        message += `${it.name} has a minimum quantity of ${it.minQty}. `
        quantityErrors[it.id] = true
      }
      this.setState({ quantityErrors: { ...quantityErrors } })
    })

    if (message.length === 0) {
      return true
    } else {
      this.props.displayFailureMessage(message)

      return false
    }
  }

  onCloseQuantityError = (id) => {
    const { quantityErrors } = this.state
    delete quantityErrors[id]
    this.setState({ quantityErrors: { ...quantityErrors } })
  }

  checkIfEditable = (date) => {
    const finalEditDay = Moment(date).endOf('month').add(10, 'days')
    if (Moment().isSameOrBefore(finalEditDay, 'day')) {
      return true
    }

    return false
  }

  onNotifyChef = async () => {
    const { showConfirmationModal, notifyPopUpChef } = this.props
    const { popUp } = this.state
    const doChange = await showConfirmationModal({
      text: 'Are you sure you want to notify chef?',
    })
    if (!doChange) {
      return
    }
    notifyPopUpChef(popUp.id, popUp.menu.chef.id)
  }

  onTogglePayments = async () => {
    const { showPayments } = this.state
    this.setState({ showPayments: !showPayments })
  }

  // Menu

  onSelectMenu = (selectedMenu) => {
    const { popUp } = this.state
    let { menu } = popUp
    if (!menu) {
      menu = {
        ...deepCopy(selectedMenu),
        entrees: [],
        sides: [],
        drinks: [],
        desserts: [],
        serviceItems: [],
      }
    } else if (menu.id !== selectedMenu.id) {
      menu = {
        ...deepCopy(selectedMenu),
        entrees: menu.entrees.filter((mi) => mi.supplierType === 'Add On'),
        sides: menu.sides.filter((mi) => mi.supplierType === 'Add On'),
        drinks: menu.drinks.filter((mi) => mi.supplierType === 'Add On'),
        desserts: menu.desserts.filter((mi) => mi.supplierType === 'Add On'),
        serviceItems: [],
      }
    }
    this.setState(
      {
        selectedMenu,
        popUp: {
          ...popUp,
          menu,
          name: !popUp.name || popUp.name === '' ? menu.name : popUp.name,
        },
        availableServiceItems: [],
      },
      this.calculateBalances,
    )
    this.loadServiceItems(menu.chef.id)
  }

  loadServiceItems = async (chefId) => {
    const availableServiceItems = await this.props.loadPopUpServiceItems(chefId)
    if (availableServiceItems) {
      this.setState({ availableServiceItems })
    }
  }

  onSelectAddOnChef = async (addOnChef) => {
    this.setState({ addOnChef, loadingAddOnChef: true })
    const addOns = await this.props.loadPopUpMenuItems(addOnChef.id)
    if (addOns) {
      this.setState({ addOns, loadingAddOnChef: false })
    }
  }

  onAddServiceItem = (serviceItem) => {
    const { popUp } = this.state
    const items = popUp.menu.serviceItems
    if (items.find((it) => it.id === serviceItem.id)) {
      return
    }
    popUp.menu.serviceItems = [...items, deepCopy(serviceItem)]
    this.setState({ popUp: { ...popUp } }, this.calculateBalances)
  }

  onChangeServiceItem = ({ idx, key, value }) => {
    const { popUp } = this.state
    popUp.menu.serviceItems[idx][key] = value
    this.setState({ popUp: { ...popUp } }, this.calculateBalances)
  }

  onDeleteServiceItem = (idx) => {
    const { popUp } = this.state
    const items = popUp.menu.serviceItems
    popUp.menu.serviceItems = [
      ...items.slice(0, idx),
      ...items.slice(idx + 1, items.length),
    ]
    this.setState({ popUp: { ...popUp } }, this.calculateBalances)
  }

  onAddMenuItem = ({ menuItem, menuType }) => {
    const { popUp } = this.state
    const currentItems = popUp.menu[menuType]
    const index = currentItems.findIndex((it) => it.id === menuItem.id)
    if (index > -1) {
      const { price, quantity, quantitySold, quantityAdjusted, childItems } =
        currentItems[index]
      popUp.menu[menuType] = [
        ...currentItems.slice(0, index),
        {
          ...deepCopy(menuItem),
          price,
          quantity,
          quantityAdjusted,
          quantitySold,
          childItems: childItems.map(
            (
              { price, quantity, quantitySold, quantityAdjusted },
              childIndex,
            ) => {
              return {
                ...deepCopy(menuItem.childItems[childIndex]),
                price,
                quantity,
                quantitySold,
                quantityAdjusted,
              }
            },
          ),
        },
        ...currentItems.slice(index + 1),
      ]
    } else {
      popUp.menu[menuType] = [...currentItems, deepCopy(menuItem)]
    }
    this.setState({ popUp: { ...popUp } }, this.calculateBalances)
  }

  onChangeMenuItem = ({ idx, key, value, menuType }) => {
    const { popUp } = this.state
    popUp.menu[menuType][idx][key] = value
    this.setState({ popUp: { ...popUp } }, this.calculateBalances)
  }

  onDeleteMenuItem = ({ idx, menuType }) => {
    const { popUp } = this.state
    const items = popUp.menu[menuType]
    popUp.menu[menuType] = [
      ...items.slice(0, idx),
      ...items.slice(idx + 1, items.length),
    ]
    this.setState({ popUp: { ...popUp } }, this.calculateBalances)
  }

  onChangeChildItem = ({ idx, childIdx, key, value, menuType }) => {
    const { popUp } = this.state
    popUp.menu[menuType][idx].childItems[childIdx][key] = value
    this.setState({ popUp: { ...popUp } }, this.calculateBalances)
  }

  onDeleteChildItem = ({ idx, childIdx, menuType }) => {
    const { popUp } = this.state
    const items = popUp.menu[menuType]
    popUp.menu[menuType] = [
      ...items.slice(0, idx),
      {
        ...items[idx],
        childItems: [
          ...items[idx].childItems.slice(0, childIdx),
          ...items[idx].childItems.slice(
            childIdx + 1,
            items[idx].childItems.length,
          ),
        ],
      },
      ...items.slice(idx + 1, items.length),
    ]
    this.setState({ popUp: { ...popUp } }, this.calculateBalances)
  }

  calculateBalances = () => {
    const { popUp } = this.state
    const { entrees, sides, drinks, desserts, serviceItems } = popUp.menu
    const allItems = [
      ...entrees,
      ...sides,
      ...drinks,
      ...desserts,
      ...serviceItems,
    ]
    const marketSubtotal = allItems.reduce(
      (ttl, it) => ttl + parseFloat(it.price) * it.quantity,
      0.0,
    )
    const chefSubtotal = allItems.reduce(
      (ttl, it) => ttl + parseFloat(it.chefPrice) * it.quantity,
      0.0,
    )
    this.setState({ popUp: { ...popUp, marketSubtotal, chefSubtotal } })
  }

  renderMenuItem = (
    { id, name, supplierType, quantity, price, chefPrice, childItems },
    menuType,
    idx,
  ) => {
    const { quantityErrors } = this.state

    return (
      <tbody>
        <tr>
          <td>
            {quantityErrors[id] && (
              <div
                className="menu-card-tooltip cursor-pointer"
                onClick={() => this.onCloseQuantityError(id)}
              >
                <p>This quantity is below the minimum servings.</p>
              </div>
            )}
            <Input
              label="" // QTY
              marginBottom="0"
              width="100px"
              type="number"
              value={quantity}
              onChange={(e) =>
                this.ensureIsBeforeBalanceCutoff(this.onChangeMenuItem)({
                  idx,
                  key: 'quantity',
                  value: parseFloat(e.target.value),
                  menuType,
                })
              }
            />
          </td>
          <td>
            {name} {supplierType === 'Add On' && '(Add-On)'}
          </td>
          <td>
            <AuthorizedInteractable roles={['chef lead']}>
              <Input
                label="" //chefPrice
                marginBottom="0"
                width="100px"
                type="number"
                value={chefPrice}
                onChange={(e) =>
                  this.ensureIsBeforeBalanceCutoff(this.onChangeMenuItem)({
                    idx,
                    key: 'chefPrice',
                    value: parseFloat(e.target.value),
                    menuType,
                  })
                }
              />
            </AuthorizedInteractable>
          </td>
          <td>
            <Input
              label="" // Price
              marginBottom="0"
              width="100px"
              type="number"
              value={price}
              onChange={(e) =>
                this.ensureIsBeforeBalanceCutoff(this.onChangeMenuItem)({
                  idx,
                  key: 'price',
                  value: parseFloat(e.target.value),
                  menuType,
                })
              }
            />
          </td>
          <td>
            <img
              onClick={() =>
                this.ensureIsBeforeBalanceCutoff(this.onDeleteMenuItem)({
                  idx,
                  menuType,
                })
              }
              className="table-trash"
              src={trash}
            />
          </td>
        </tr>
        {(childItems || []).map((child, childIdx) => (
          <tr key={childIdx}>
            <td>
              <FlexContainer>
                <XSpacing width="30px" />
                <Input
                  label="Toppings" // QTY
                  marginBottom="0"
                  width="70px"
                  type="number"
                  value={child.quantity}
                  onChange={(e) =>
                    this.ensureIsBeforeBalanceCutoff(this.onChangeChildItem)({
                      idx,
                      childIdx,
                      key: 'quantity',
                      value: Math.trunc(parseFloat(e.target.value)),
                      menuType,
                    })
                  }
                />
              </FlexContainer>
            </td>
            <td>{child.name}</td>
            <td>${parseFloat(child.chefPrice).toFixed(2)}</td>
            <td>
              <Input
                label="" // price
                marginBottom="0"
                width="100px"
                type="number"
                value={child.price}
                onChange={(e) =>
                  this.ensureIsBeforeBalanceCutoff(this.onChangeChildItem)({
                    idx,
                    childIdx,
                    key: 'price',
                    value: parseFloat(e.target.value),
                    menuType,
                  })
                }
              />
            </td>
            <td>
              <img
                onClick={() =>
                  this.ensureIsBeforeBalanceCutoff(this.onDeleteChildItem)({
                    idx,
                    childIdx,
                    menuType,
                  })
                }
                className="table-trash"
                src={trash}
              />
            </td>
          </tr>
        ))}
      </tbody>
    )
  }

  renderServiceItem = ({ name, quantity, price, chefPrice }, idx) => {
    return (
      <tr>
        <td>
          <Input
            label="" // QTY
            marginBottom="0"
            width="100px"
            type="number"
            value={quantity}
            onChange={(e) =>
              this.ensureIsBeforeBalanceCutoff(this.onChangeServiceItem)({
                idx,
                key: 'quantity',
                value: Math.trunc(parseFloat(e.target.value)),
              })
            }
          />
        </td>
        <td>{name}</td>
        <td>${parseFloat(chefPrice).toFixed(2)}</td>
        <td>
          <Input
            label="" // Price
            marginBottom="0"
            width="100px"
            type="number"
            value={price}
            onChange={(e) =>
              this.ensureIsBeforeBalanceCutoff(this.onChangeServiceItem)({
                idx,
                key: 'price',
                value: parseFloat(e.target.value),
              })
            }
          />
        </td>
        <td>
          <img
            onClick={() =>
              this.ensureIsBeforeBalanceCutoff(this.onDeleteServiceItem)(idx)
            }
            className="table-trash"
            src={trash}
          />
        </td>
      </tr>
    )
  }

  render() {
    const {
      addOns,
      addOnChef,
      availableServiceItems,
      popUp,
      selectedMenu,
      showPayments,
      loadingAddOnChef,
      rPopup,
      delay,
    } = this.state

    if (!popUp) {
      return
    }

    const {
      id,
      chefNotes,
      chefLastSent,
      chefSubtotal,
      client,
      end,
      headCount,
      marketSubtotal,
      menu,
      name,
      number,
      location,
      prePaid,
      prePaidAmount,
      prePaidTax,
      prePaymentAmount,
      prePaymentTax,
      prePaymentTip,
      start,
      servicesNotes,
      salesRep,
      setUpStyle,
      updatedAt,
      isPrivate,
      isHidden,
      viewableOnly,
      hidePrices,
      status,
    } = popUp
    const {
      headquarter,
      hideModal,
      locale,
      searchPopUpAddOnChefs,
      searchPopUpConcepts,
      searchPopUpClients,
      searchSalesReps,
      invoiceUrl,
      popUpUrl,
    } = this.props
    const title = id ? 'Edit Pop Up' : 'New Pop Up'
    const chefChanges =
      !chefLastSent || Moment(chefLastSent).isBefore(updatedAt)

    const paidSubtotal = parseFloat(prePaidAmount) || 0.0
    const paidTax = parseFloat(prePaidTax) || 0.0
    const paidTip = parseFloat(prePaymentTip) || 0.0
    const paidTotal = paidSubtotal + paidTax + paidTip
    const menuTableHeadings = ['QTY', 'Name', 'Chef Price', 'Price', '']

    return (
      <Modal
        title={title}
        hideModal={hideModal}
        color="#001940"
        maxWidth="1000px"
      >
        {id && (
          <PopupTitle>
            <h2>Number {number}</h2>
            {rPopup && rPopup.id && (
              <label>{`Generated From: ${rPopup.name}`}</label>
            )}
          </PopupTitle>
        )}
        <FlexContainer justifyContent="space-between">
          <AutocompleteInput
            label="Sales Rep"
            displayAttribute="firstName"
            width="48%"
            loaderFunction={(search) => searchSalesReps({ ...search })}
            value={salesRep && salesRep.firstName}
            onSelect={this.onSelectSalesRep}
          />
          <AutocompleteInput
            label="Client"
            displayAttribute="name"
            width="48%"
            loaderFunction={(search) =>
              searchPopUpClients({ ...search, headquarter })
            }
            value={client && client.name}
            onSelect={this.onSelectClient}
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <Input
            label="Head Count"
            marginBottom="0"
            width="48%"
            type="text"
            value={headCount}
            onChange={(e) =>
              this.onChangePopUp(
                'headCount',
                Math.trunc(parseFloat(e.target.value)),
              )
            }
          />
          <Dropdown
            label="Set Up Style"
            width="48%"
            value={setUpStyle}
            onChange={(e) => this.onChangePopUp('setUpStyle', e.target.value)}
          >
            <option />
            {['Pop-Up Boxes', 'Pop-Up Bowls'].map((style) => (
              <option key={style} value={style}>
                {style}
              </option>
            ))}
          </Dropdown>
        </FlexContainer>
        <FlexContainer justifyContent="space-between">
          <Dropdown
            label="Status"
            width="48%"
            value={status}
            onChange={(e) => this.onChangeStatus(e.target.value)}
          >
            {(statuses || []).map((ele) => (
              <option key={ele} value={ele}>
                {ele}
              </option>
            ))}
          </Dropdown>
          <Input
            label="Pop Up Name"
            marginBottom="0"
            width="48%"
            type="text"
            value={name}
            onChange={(e) => this.onChangePopUp('name', e.target.value)}
          />
        </FlexContainer>

        {client && (
          <div>
            <FlexContainer justifyContent="space-between">
              {delay && (
                <AutocompleteInput
                  label="Recurring Pop Up"
                  width="48%"
                  displayAttribute="name"
                  loaderFunction={this.searchRecurringPopUps}
                  placeholder={'Search Recurring Popups'}
                  value={rPopup && rPopup.name}
                  onSelect={this.onSelectRecurringPopup}
                />
              )}
              <Dropdown
                label="Location"
                width="48%"
                value={location && location.id}
                onChange={(e) => this.onChangeLocation(e.target.value)}
              >
                <option />
                {(client.locations || []).map((loc) => (
                  <option key={loc.id} value={loc.id}>
                    {loc.name}
                  </option>
                ))}
              </Dropdown>
            </FlexContainer>
            {location && (
              <FlexContainer justifyContent="space-between">
                <DateTextInput
                  label="Start Time"
                  width="48%"
                  date={start}
                  onChange={this.onChangeStart}
                  dateFormat={DateFormat}
                  timeZone={locale}
                />
                <DateTextInput
                  label="End Time"
                  width="48%"
                  date={end}
                  onChange={(end) => this.onChangePopUp('end', end)}
                  dateFormat={DateFormat}
                  timeZone={locale}
                />
              </FlexContainer>
            )}
            {location && <YSpacing height="20px" />}
            <FlexContainer justifyContent="space-between" alignItems="center">
              <Dropdown
                label="Contact"
                width="48%"
                value={client.contact && client.contact.id}
                onChange={(e) => this.onSelectContact(e.target.value)}
              >
                <option />
                {(client.contacts || []).map((ct) => (
                  <option key={ct.id} value={ct.id}>
                    {ct.name}
                  </option>
                ))}
              </Dropdown>
              <FlexContainer width="48%" alignItems="center" flexWrap="wrap">
                <Checkbox
                  label="Private Event?"
                  value={isPrivate}
                  checked={isPrivate}
                  onChange={(e) =>
                    this.onChangePopUp('isPrivate', e.target.checked)
                  }
                />
                <YSpacing height="5px" />
                <Checkbox
                  label="Hide from Customer App?"
                  value={isHidden}
                  checked={isHidden}
                  onChange={(e) =>
                    this.onChangePopUp('isHidden', e.target.checked)
                  }
                />
                <YSpacing height="5px" />
                <Checkbox
                  label="Disable Pre-Ordering?"
                  value={viewableOnly}
                  checked={viewableOnly}
                  onChange={(e) =>
                    this.onChangePopUp('viewableOnly', e.target.checked)
                  }
                />
                <YSpacing height="5px" />
                <Checkbox
                  label="Hide Prices?"
                  value={hidePrices}
                  checked={hidePrices}
                  onChange={(e) =>
                    this.onChangePopUp('hidePrices', e.target.checked)
                  }
                />
                <YSpacing height="5px" />
              </FlexContainer>
            </FlexContainer>
          </div>
        )}

        <FlexContainer justifyContent="space-between">
          <AutocompleteInput
            label="Menu"
            width="48%"
            displayAttribute="autocompleteKey"
            loaderFunction={searchPopUpConcepts}
            placeholder={'Search Concepts'}
            value={menu && `${menu.chef.name} - ${menu.name}`}
            onSelect={this.onSelectMenu}
          />
          {menu && (
            <AutocompleteInput
              label="Add On"
              width="48%"
              displayAttribute="name"
              loaderFunction={searchPopUpAddOnChefs}
              placeholder={'Search Add On Chefs'}
              value={addOnChef && addOnChef.name}
              onSelect={this.onSelectAddOnChef}
            />
          )}
        </FlexContainer>
        {loadingAddOnChef && <p>Loading Add On Chef...</p>}
        {!loadingAddOnChef && addOnChef && addOns.length == 0 && (
          <p>Chef Has No Add Ons</p>
        )}
        {addOns.length > 0 && (
          <div>
            <YSpacing height="20px" />
            <Select
              className="panel-input"
              placeholder="Select Add On (Will appear in table below)"
              options={addOns}
              labelKey="name"
              onChange={(menuItem) =>
                this.ensureIsBeforeBalanceCutoff(this.onAddMenuItem)({
                  menuItem,
                  menuType: menuItem.menuType,
                })
              }
            />
          </div>
        )}
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <TextArea
            label="Services Notes"
            width="48%"
            value={servicesNotes}
            onChange={(e) =>
              this.onChangePopUp('servicesNotes', e.target.value)
            }
          />
          <TextArea
            label="Chef Notes"
            width="48%"
            value={chefNotes}
            onChange={(e) => this.onChangePopUp('chefNotes', e.target.value)}
          />
        </FlexContainer>
        <PopUpCalculator headcount={headCount} />
        <YSpacing height="30px" />
        <MenuTitle>
          Entrees ({(menu && menu.entrees && menu.entrees.length) || 0})
        </MenuTitle>
        <Table headings={menuTableHeadings}>
          {((menu && menu.entrees) || []).map((menuItem, idx) =>
            this.renderMenuItem(menuItem, 'entrees', idx),
          )}
        </Table>
        {selectedMenu && selectedMenu.entrees.length > 0 && (
          <Select
            className="mlb-select"
            placeholder="Add Entree"
            options={selectedMenu.entrees}
            labelKey="name"
            onChange={(menuItem) =>
              this.ensureIsBeforeBalanceCutoff(this.onAddMenuItem)({
                menuItem,
                menuType: 'entrees',
              })
            }
          />
        )}
        <YSpacing height="50px" />
        <MenuTitle>
          Sides ({(menu && menu.sides && menu.sides.length) || 0})
        </MenuTitle>
        <Table headings={menuTableHeadings}>
          {((menu && menu.sides) || []).map((menuItem, idx) =>
            this.renderMenuItem(menuItem, 'sides', idx),
          )}
        </Table>
        {selectedMenu && selectedMenu.sides.length > 0 && (
          <Select
            className="mlb-select"
            placeholder="Add Side"
            options={selectedMenu.sides}
            labelKey="name"
            onChange={(menuItem) =>
              this.ensureIsBeforeBalanceCutoff(this.onAddMenuItem)({
                menuItem,
                menuType: 'sides',
              })
            }
          />
        )}
        <YSpacing height="50px" />
        <MenuTitle>
          Drinks ({(menu && menu.drinks && menu.drinks.length) || 0})
        </MenuTitle>
        <Table headings={menuTableHeadings}>
          {((menu && menu.drinks) || []).map((menuItem, idx) =>
            this.renderMenuItem(menuItem, 'drinks', idx),
          )}
        </Table>
        {selectedMenu && selectedMenu.drinks.length > 0 && (
          <Select
            className="mlb-select"
            placeholder="Add Drink"
            options={selectedMenu.drinks}
            labelKey="name"
            onChange={(menuItem) =>
              this.ensureIsBeforeBalanceCutoff(this.onAddMenuItem)({
                menuItem,
                menuType: 'drinks',
              })
            }
          />
        )}
        <YSpacing height="50px" />
        <MenuTitle>
          Desserts ({(menu && menu.desserts && menu.desserts.length) || 0})
        </MenuTitle>
        <Table headings={menuTableHeadings}>
          {((menu && menu.desserts) || []).map((menuItem, idx) =>
            this.renderMenuItem(menuItem, 'desserts', idx),
          )}
        </Table>
        {selectedMenu && selectedMenu.desserts.length > 0 && (
          <Select
            className="mlb-select"
            placeholder="Add Dessert"
            options={selectedMenu.desserts}
            labelKey="name"
            onChange={(menuItem) =>
              this.ensureIsBeforeBalanceCutoff(this.onAddMenuItem)({
                menuItem,
                menuType: 'desserts',
              })
            }
          />
        )}
        <YSpacing height="50px" />
        <MenuTitle>
          Service Items (
          {(menu && menu.serviceItems && menu.serviceItems.length) || 0})
        </MenuTitle>
        <Table headings={menuTableHeadings}>
          {((menu && menu.serviceItems) || []).map((serviceItem, idx) =>
            this.renderServiceItem(serviceItem, idx),
          )}
        </Table>
        {availableServiceItems.length > 0 && (
          <Select
            className="mlb-select"
            placeholder="Add Service Item"
            options={availableServiceItems}
            labelKey="name"
            onChange={(serviceItem) =>
              this.ensureIsBeforeBalanceCutoff(this.onAddServiceItem)(
                serviceItem,
              )
            }
          />
        )}
        <YSpacing height="50px" />
        <Table
          headings={[
            'Chef Subtotal',
            'Market Subtotal',
            'Subsidy Amount',
            'Subsidy Tax',
            'Paid?',
          ]}
        >
          <tr>
            <td> ${parseFloat(chefSubtotal).toFixed(2)} </td>
            <td> ${parseFloat(marketSubtotal).toFixed(2)} </td>
            <td>
              <FlexContainer alignItems="center">
                $
                <Input
                  label="" // QTY
                  marginBottom="0"
                  width="100px"
                  type="number"
                  value={prePaymentAmount}
                  onChange={this.ensureIsBeforeBalanceCutoff(
                    this.onChangePrePaymentAmt,
                  )}
                />
              </FlexContainer>
            </td>
            <td>
              {' '}
              ${prePaymentTax != null &&
                parseFloat(prePaymentTax).toFixed(2)}{' '}
            </td>
            <td>
              {' '}
              {prePaymentAmount ? (prePaid ? 'Paid' : 'Unpaid') : 'N/A'}{' '}
            </td>
          </tr>
        </Table>

        {id && (
          <LinkText
            label="Show / Hide Payments"
            onClick={this.onTogglePayments}
          />
        )}
        {showPayments && (
          <Table
            headings={['Paid Subtotal', 'Paid Tax', 'Paid Tip', 'Paid Total']}
          >
            <tr>
              <td> ${paidSubtotal.toFixed(2)} </td>
              <td> ${paidTax.toFixed(2)} </td>
              <td> ${paidTip.toFixed(2)} </td>
              <td> ${paidTotal.toFixed(2)} </td>
            </tr>
          </Table>
        )}

        {id && <DividerLine margin="30px 0" />}
        {id && (
          <FlexContainer>
            <div>
              <LinkText
                isLink={true}
                label="Download Proposal PDF"
                link={`${popUpUrl}/${id}/proposal_pdf`}
              />
              <YSpacing height="20px" />
              <LinkText
                isLink={true}
                label="Day of Menu PDF"
                link={`${popUpUrl}/${id}/day_of_menu`}
              />
            </div>
            <XSpacing width="50px" />
            <div>
              <LinkText
                isLink={true}
                label="Food Labels PDF"
                link={`${popUpUrl}/${id}/food_labels`}
              />
              <YSpacing height="20px" />
              {prePaymentAmount ? (
                <LinkText
                  isLink={true}
                  label="Link To Payment Form"
                  link={`${invoiceUrl}/${id}`}
                />
              ) : undefined}
              <YSpacing height="20px" />
            </div>
            <XSpacing width="50px" />
            <div>
              {prePaymentAmount ? (
                <LinkText
                  isLink={true}
                  label="Download Invoice PDF"
                  link={`${popUpUrl}/${id}/invoice_pdf`}
                />
              ) : undefined}
            </div>
          </FlexContainer>
        )}
        {id && <DividerLine margin="30px 0" />}
        {id && (
          <FlexContainer>
            <LinkText label="Notify Chef" onClick={this.onNotifyChef} />
            <XSpacing width="50px" />
            <LabelInfo
              label="Last Sent"
              value={
                chefLastSent ? Moment(chefLastSent).format('LLL') : 'Never'
              }
            />
            <XSpacing width="50px" />
            <LabelInfo
              label="Changes since last sent?"
              value={chefChanges ? 'Yes' : 'No'}
            />
          </FlexContainer>
        )}
        <YSpacing height="40px" />
        <FlexContainer justifyContent="flex-end" alignItems="center">
          {id && <LinkText label="Copy Pop Up" onClick={this.onCopyPopUp} />}
          <XSpacing width="40px" />
          {id && (
            <LinkText
              label="Delete Pop Up"
              onClick={this.onDeletePopUp}
              color="#b30303"
            />
          )}
          <XSpacing width="40px" />
          <Button label="Save Pop Up" onClick={this.onSavePopUp} />
        </FlexContainer>
      </Modal>
    )
  }
}

const PopupTitle = styled.div`
  margin-bottom: 20px;
  h2 {
    color: ${colors.gray400};
    font-family: 'extrabold';
    font-size: 20px;
  }
  label {
    font-family: 'regular';
  }
`

const MenuTitle = styled.h2`
  font-family: 'extrabold';
  text-transform: uppercase;
  color: ${colors.orange};
`

PopUpModal.propTypes = {
  headquarter: PropTypes.number,
  locale: PropTypes.string,
  popUp: PropTypes.object,
  theme: PropTypes.object,
  invoiceUrl: PropTypes.string,
  popUpUrl: PropTypes.string,
  userPermissions: PropTypes.array,

  checkOrderIsEditableNow: PropTypes.func,
  copy: PropTypes.func,
  deletePopUp: PropTypes.func,
  displayFailureMessage: PropTypes.func,
  displayWarningMessage: PropTypes.func,
  getPopUpClient: PropTypes.func,
  getPopUpPrePaymentTax: PropTypes.func,
  hideModal: PropTypes.func,
  loadPopUpMenuItems: PropTypes.func,
  loadPopUpServiceItems: PropTypes.func,
  newPopUp: PropTypes.func,
  newMenu: PropTypes.func,
  notifyPopUpChef: PropTypes.func,
  orderBalancesEditCutoffDate: PropTypes.func,
  savePopUp: PropTypes.func,
  searchPopUpAddOnChefs: PropTypes.func,
  searchPopUps: PropTypes.func,
  searchPopUpConcepts: PropTypes.func,
  searchPopUpClients: PropTypes.func,
  searchSalesReps: PropTypes.func,
  showConfirmationModal: PropTypes.func,
  searchRecurringPopUps: PropTypes.func,
}

export default PopUpModal
