import React, { useState } from 'react'
import { config } from 'hungry-core2'
import Panel from '@components/common/Panel'
import { AutocompleteInput, DateInput } from '@components/common/form'
import Input from '@components/common/form/Input'
import LinkText from '@components/common/form/LinkText'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'

interface ConsumptionReportsPageProps {
  headquarterId: number
  loadChefs: (val: { search: string }) => void
  loadAccounts: (val: { search: string }) => void
}

const ConsumptionReportsPage = ({
  headquarterId,
  loadChefs,
  loadAccounts,
}: ConsumptionReportsPageProps) => {
  const [vendorId, setVendorId] = useState('')
  const [clientId, setClientId] = useState('')
  const [fromDate, setFromDate] = useState<Date>()
  const [search, setSearch] = useState('')
  const [toDate, setToDate] = useState<Date>()

  const buildCSVPath = (): string => {
    const baseUrl = `${config.API_HOST}/consumption/catering/orders/csv`
    const queryParams = new URLSearchParams()
    queryParams.append('headquarterId', headquarterId.toString())

    if (vendorId) {
      queryParams.append('vendorId', vendorId)
    }
    if (clientId) {
      queryParams.append('clientId', clientId)
    }
    if (fromDate) {
      queryParams.append('orderDateStart', fromDate.toISOString().split('T')[0])
    }
    if (toDate) {
      queryParams.append('orderDateEnd', toDate.toISOString().split('T')[0])
    }
    if (search) {
      queryParams.append('orderNumberSearch', search)
    }

    return `${baseUrl}?${queryParams.toString()}`
  }

  return (
    <Panel
      width="100%"
      maxHeight="500px"
      maxWidth="1600px"
      heading="Consumption Reports"
    >
      <FlexContainer alignItems="center" justifyContent="center">
        <DateInput
          width="200px"
          label="Date"
          date={fromDate}
          dateFormat="default"
          isStatic={false}
          onChange={(fromDate: any) => setFromDate(fromDate)}
          clearDate={() => setFromDate(undefined)}
        />
        <XSpacing width="20px" />
        <DateInput
          width="200px"
          label="Date"
          date={toDate}
          dateFormat="default"
          isStatic={false}
          onChange={(toDate: any) => setToDate(toDate)}
          clearDate={() => setToDate(undefined)}
        />
        <XSpacing width="20px" />
        <AutocompleteInput
          label="Chef Name"
          width="200px"
          onClear={vendorId ? () => setVendorId('') : undefined}
          loaderFunction={(search: { search: string }) =>
            loadChefs({ ...search })
          }
          onSelect={(value: { id: string }) => setVendorId(value.id)}
        />
        <XSpacing width="20px" />
        <AutocompleteInput
          label="Client"
          width="200px"
          onClear={clientId ? () => setClientId('') : undefined}
          loaderFunction={(search: { search: string }) =>
            loadAccounts({ ...search })
          }
          onSelect={(value: { id: string }) => setClientId(value.id)}
        />
        <XSpacing width="20px" />
        <Input
          label="Search Order Number"
          marginBottom="0"
          width="200px"
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </FlexContainer>
      <YSpacing height="50px" />
      <FlexContainer width="100%" alignItems="center" justifyContent="center">
        <LinkText label="Download CSV" isLink={true} link={buildCSVPath()} />
      </FlexContainer>
    </Panel>
  )
}

export default ConsumptionReportsPage
