import Moment from 'moment-timezone'
import { checkLogInHqUsers, convertTzToIANAFormat } from '../../utils'
import { initHolidaysInTZ } from '../../utils/holidays'

export const FakeSignIn =
  ({ FakeService, SessionService, UIService }) =>
  ({ email, password }) => {
    const user = FakeService.fakeUser({
      firstName: 'Goku',
      lastName: 'Son',
      email,
      password,
    })
    SessionService.setUserSession(user)
    UIService.LoginForm.toggleVisible(false)
  }

export const FakeLoginError =
  ({ FakeService, HandleError }) =>
  () => {
    const error = FakeService.fakeErrors({
      firstName: 'Please enter your first name',
      lastName: 'Please enter your last name',
      email: 'Please enter a valid email',
      password: 'Please enter a password',
      passwordConfirm: 'Passwords do not match!',
    })
    HandleError({ error, namespace: 'loginForm' })
  }

export const ChangeHeadquarter =
  ({
    CoreService,
    HeadquarterService,
    RestService,
    RouterService,
    SessionService,
    UIService,
    pResponseHeadquarters,
    pResponseUser,
    HandleError,
  }) =>
  async ({ headquarterId, userId }) => {
    // Release new change hq behavior to select users first
    const { user } = SessionService.getState()
    if (checkLogInHqUsers.includes(user.email)) {
      ChangeHeadquarterV2({
        CoreService,
        RestService,
        RouterService,
        SessionService,
        HeadquarterService,
        pResponseHeadquarters,
        UIService,
        HandleError,
      })(headquarterId)

      return
    }

    // Legacy change headquarter
    try {
      const refreshToken = window.localStorage.getItem('refreshToken')
      let user = await RestService.post('/v2/users/admin/token', {
        uid: userId,
        token: refreshToken,
        hqId: parseInt(headquarterId),
      })

      user = pResponseUser(user)
      // eslint-disable-next-line no-console
      console.log('ChangeHeadquarter setting locale', user)
      Moment.tz.setDefault(user.locale)
      initHolidaysInTZ(user.locale)
      SessionService.setUserSession(user)
      window.localStorage.setItem('lastLoggedInHq', user.lastLoggedInHq)
      window.localStorage.setItem('currentLocale', user.locale)
      window.location.reload()
    } catch (error) {
      CoreService.clearStore()
      RouterService.push('/signin')
      setTimeout(() => HandleError({ error }), 500)
    }
  }

export const ChangeHeadquarterV2 =
  ({
    CoreService,
    RestService,
    RouterService,
    SessionService,
    HeadquarterService,
    pResponseHeadquarters,
    UIService,
    HandleError,
  }) =>
  async (headquarterId) => {
    try {
      const { user, headquarter } = SessionService.getState()
      let { headquarters } = headquarter

      // need to make sure hqs are loaded first before we can check session
      if (!headquarters.length) {
        headquarters = await LoadHeadquarters({
          HeadquarterService,
          RestService,
          pResponseHeadquarters,
          HandleError,
        })()
        if (!headquarters.length) {
          throw 'Unable to load headquarters'
        }
      }

      const nextHq = headquarters.find((hq) => String(hq.id) === headquarterId)
      if (!nextHq) {
        UIService.FlashMessage.displaySuccessMessage(
          `Selected headquarter not found. Please sign out and back in.`,
        )

        return
      }

      const hqLocale = convertTzToIANAFormat(nextHq.locale)
      const nextSession = {
        ...user,
        locale: hqLocale,
        lastLoggedInHq: headquarterId,
      }

      // eslint-disable-next-line no-console
      console.log('ChangeHeadquarterV2 setting locale', nextSession)
      Moment.tz.setDefault(nextSession.locale)
      initHolidaysInTZ(nextSession.locale)
      SessionService.setUserSession(nextSession)
      window.localStorage.setItem('lastLoggedInHq', nextSession.lastLoggedInHq)
      window.localStorage.setItem('currentLocale', nextSession.locale)
      window.localStorage.setItem('currentHq', nextSession.lastLoggedInHq)
      window.location.reload()
    } catch (error) {
      CoreService.clearStore()
      RouterService.push('/signin')
      setTimeout(() => HandleError({ error }), 500)
    }
  }

export const SetSessionLocation =
  ({ SessionService }) =>
  ({ locale, lastLoggedInHq }) => {
    const { user } = SessionService.getState()
    let nextUser = { ...user }
    if (locale) {
      // eslint-disable-next-line no-console
      console.log('SetSessionLocation setting locale', locale)
      nextUser = { ...nextUser, locale }
    }
    if (lastLoggedInHq) {
      nextUser = { ...nextUser, lastLoggedInHq }
    }
    SessionService.setUserSession(nextUser)
  }

export const SignIn =
  ({
    RestService,
    RouterService,
    SessionService,
    pResponseUser,
    HandleError,
  }) =>
  async ({ email, password, headquarter }) => {
    try {
      let user = await RestService.post('/v2/users/admin/login', {
        email,
        password,
        headquarterId: headquarter.id,
        headquarter_id: headquarter.id,
        application: 'Admin Panel',
      })
      user = pResponseUser(user)
      Moment.tz.setDefault(user.locale)
      initHolidaysInTZ(user.locale)
      SessionService.setUserSession(user)
      window.localStorage.setItem('lastLoggedInHq', user.lastLoggedInHq)
      window.localStorage.setItem('currentLocale', user.locale)
      if (checkLogInHqUsers.includes(user.email)) {
        window.localStorage.setItem('currentHq', user.lastLoggedInHq)
      } else {
        localStorage.removeItem('currentHq')
      }
      RouterService.replace('/')
    } catch (error) {
      HandleError({ error })
    }
  }

export const SignOut =
  ({ CoreService, RestService, RouterService, HandleError }) =>
  async () => {
    try {
      await RestService.get('/api/v1/users/sign_out')
      CoreService.clearStore()
      RouterService.push('/signin')
    } catch (error) {
      HandleError({ error })
    }
  }

export const SendResetPassEmail =
  ({ RestService, UIService, HandleError }) =>
  async ({ email }) => {
    try {
      await RestService.post('v2/users/admin/forgotpassword', {
        email,
        app: 'admin',
      })
      UIService.FlashMessage.displaySuccessMessage(
        `If "${email}" is associated with an account, you will receive an email from us to reset your password`,
      )
    } catch (error) {
      HandleError({ error })
    }
  }

export const ResetPass =
  ({ CoreService, RestService, UIService, RouterService, HandleError }) =>
  async ({ password, token }) => {
    try {
      //Handle cornercase with user logged in, make sure we don't pass a regular auth token in the header
      window.localStorage.removeItem('accessToken')
      CoreService.clearStore()

      await RestService.put(
        `v2/users/admin/resetpassword?accessToken=${token}`,
        { password },
      )
      RouterService.replace('/signin')
      UIService.FlashMessage.displaySuccessMessage(
        'Password changed, you can now sign in with your new password',
      )
    } catch (error) {
      HandleError({ error })
    }
  }

export const LoadHeadquarters =
  ({ HeadquarterService, RestService, pResponseHeadquarters, HandleError }) =>
  async () => {
    try {
      let headquarters = await RestService.get('/api/admin/headquarters')
      headquarters = pResponseHeadquarters(headquarters)
      HeadquarterService.setHeadquarters(headquarters)

      return headquarters
    } catch (error) {
      HandleError({ error })
    }
  }

export const LoadUserHeadquarters =
  ({ HeadquarterService, RestService, pResponseHeadquarters, HandleError }) =>
  async (userId) => {
    try {
      const permissions = await RestService.get(
        `/v2/users/admin/${userId}/permissions`,
      )
      const headquarters = pResponseHeadquarters(
        permissions.map((p) => p.headquarter),
      )
      HeadquarterService.setUserHeadquarters(headquarters)
    } catch (error) {
      HandleError({ error })
    }
  }
