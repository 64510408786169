import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import { config } from 'hungry-core2'
import phone from '@res/images/phone.svg'
import email from '@res/images/email.svg'
import loadGif from '@res/images/loading.gif'
import { DateFilter } from '.'
import { AuthorizedDisplay } from '@containers/common/auth'
import { Button, Dropdown, Input, TextArea } from '@components/common/form'
import FlexContainer from '@components/common/FlexContainer'
import { MultiSelectOption } from '@res/styledComponents/index'
import { ExportCSVButton } from '@components/dashboard'
import { NetPaymentDays, AutoBatchPeriods } from '../../../constants'
import { ARColumns } from './constants'

const tableHeaders = [
  'Order Number',
  'Batch Invoice',
  'Order Date',
  'Name',
  'Client Success Manager',
  'Account Executive',
  'Invoice Contact',
  'Payment Method',
  'Payment Terms',
  'Invoicing Cadence',
  'Effective Date',
  'Due Date',
  'Days Past Due',
  'Total',
  'Unpaid Amount',
  'Internal Notes',
]

const ManualPaymentTypes = ['ACH', 'Check']

const filterOptions = [
  { value: ['account', 'alpha'], label: 'Company - by Name' },
  { value: ['account', 'total'], label: 'Company - by Most Owed' },
  { value: ['account', 'oldest'], label: 'Company - by Longest Overdue' },
  { value: ['sales_rep', 'alpha'], label: 'Sales Rep - by Name' },
  { value: ['sales_rep', 'total'], label: 'Sales Rep - by Most Owed' },
  { value: ['sales_rep', 'oldest'], label: 'Sales Rep - by Longest Overdue' },
]

const getCsvFileName = (fromDateStr, toDateStr, selectedHeadquarters) => {
  const params = []
  if (fromDateStr) {
    params.push(`from_${fromDateStr}`)
  }
  if (toDateStr) {
    params.push(`to_${toDateStr}`)
  }
  if (selectedHeadquarters && Object.values(selectedHeadquarters).length > 0) {
    params.push('hqs')
    Object.values(selectedHeadquarters).forEach((hq) => params.push(hq.prefix))
  }

  let paramsStr = params.join('_')
  if (paramsStr.length > 0) {
    paramsStr = `_${paramsStr}_`
  }

  return `AR_Dashboard${paramsStr}.csv`
}

class AccountsReceivable extends Component {
  state = {
    fromDate: '',
    toDate: '',
    type: 'account',
    sort: 'alpha',
    name: '',
    selectedHeadquarters: {},
    unsavedNotes: {},
    isMounted: false,
    isSearching: false,
    isFilteredAndNotSubmitted: false,
    manualPmtTypeMap: {},
  }

  componentDidMount() {
    const { accountName, filteredHqs, headquarters, from, to } = this.props
    if (headquarters && headquarters.length > 0) {
      this.setInitialState(accountName, filteredHqs, headquarters, from, to)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isMounted } = this.state
    const { accountName, filteredHqs, headquarters, from, to } = nextProps
    if (!isMounted && headquarters && headquarters.length > 0) {
      this.setInitialState(accountName, filteredHqs, headquarters, from, to)
    }
  }

  setInitialState = (accountName, filteredHqs, headquarters, from, to) => {
    const newState = { isMounted: true }

    if (from) {
      newState.fromDate = from === 'none' ? '' : from
    } else {
      newState.fromDate = Moment().add(-1, 'month').toISOString()
    }

    if (to) {
      newState.toDate = from === 'none' ? '' : from
    } else {
      newState.toDate = Moment().toISOString()
    }

    if (accountName) {
      newState.name = accountName
    }

    if (filteredHqs && filteredHqs.length > 0) {
      const selectedHeadquarters = {}
      filteredHqs.forEach((id) => {
        const hqId = parseInt(id)
        const hq = headquarters.find((hq) => hq.id === hqId)
        if (hq) {
          selectedHeadquarters[hqId] = hq
        }
      })
      newState.selectedHeadquarters = selectedHeadquarters
    }

    this.setState(newState)
  }

  changeAccountName = (name) => {
    this.setState({
      name,
      lastNameInput: Date.now(),
      isFilteredAndNotSubmitted: true,
    })
  }

  onChangeAccountName = (e) => {
    this.changeAccountName(e.target.value)
  }

  setDates = ({ fromDate = '', toDate = '' }) => {
    const { fromDate: oldFrom, toDate: oldTo } = this.state
    if (oldFrom != fromDate || oldTo != toDate) {
      this.setState({ fromDate, toDate, isFilteredAndNotSubmitted: true })
    }
  }

  onSelectHeadquarter = (hqId) => {
    const hqIdInt = parseInt(hqId)
    const { selectedHeadquarters } = this.state
    const { headquarters } = this.props
    const hq = headquarters.find((h) => h.id === hqIdInt)
    if (hq) {
      this.setState({
        selectedHeadquarters: {
          ...selectedHeadquarters,
          [hqIdInt]: hq,
        },
        isFilteredAndNotSubmitted: true,
      })
    }
  }

  onRemoveHeadquarter = (hqId) => {
    const { selectedHeadquarters } = this.state
    const nextSelectedHqs = { ...selectedHeadquarters }
    if (nextSelectedHqs[hqId]) {
      delete nextSelectedHqs[hqId]
    }
    this.setState({
      selectedHeadquarters: nextSelectedHqs,
      isFilteredAndNotSubmitted: true,
    })
  }

  onMarkPaid = async (id, orderNumber, row) => {
    const { manualPmtTypeMap } = this.state
    const manualPmtType = manualPmtTypeMap[id] || ManualPaymentTypes[0]
    const { markOrderPaid, email: alias, updateARAfterMarkingPaid } = this.props
    const markedPaid = await markOrderPaid({
      id,
      orderNumber,
      alias,
      paymentType: manualPmtType,
    })
    if (markedPaid) {
      const recordId = row.Id.id
      updateARAfterMarkingPaid(this.props.accountsReceivable, recordId, id)
    }
  }

  onChangePmtType = (id, value) => {
    const { manualPmtTypeMap } = this.state
    manualPmtTypeMap[id] = value
    this.setState({ manualPmtTypeMap })
  }

  submit = async ({
    type,
    name,
    toDate,
    fromDate,
    sort,
    selectedHeadquarters,
  }) => {
    const { headquarters, UIService } = this.props
    if (
      !selectedHeadquarters ||
      Object.keys(selectedHeadquarters).length <= 0
    ) {
      selectedHeadquarters = headquarters.reduce((hqs, hq) => {
        return { ...hqs, [hq.id]: hq }
      }, {})
    }

    if (!!name && name.length < 3) {
      UIService.FlashMessage.displayWarningMessage(
        'Cannot use search term less than 3 characters',
      )

      return
    }

    this.setState({ isSearching: true })
    const result = await this.props.getUnpaidAccounts({
      type,
      name: name.replace('.', '\\.'),
      toDate,
      fromDate,
      sort,
      requestTime: Date.now(),
      selectedHeadquarters,
    })
    if (result) {
      this.setState({ isFilteredAndNotSubmitted: false })
    }
    this.setState({ isSearching: false })
  }

  loadOrder = (orderId) => () => {
    this.props.loadOrder(orderId)
  }

  filterAR = (e) => {
    const { value } = e.target
    const [type, sort] = value.split(',', 2)

    this.setState({ type, sort, isFilteredAndNotSubmitted: true })
  }

  onChangeOrderNotes = (orderId, ar) => (e) => {
    const { value } = e.target
    const { unsavedNotes } = this.state
    unsavedNotes[`${ar.Id.id}-${orderId}`] = value

    this.setState({ unsavedNotes })
  }

  updateOrderNotes = async (orderId, ar) => {
    const { unsavedNotes } = this.state
    const { upsertAccountingOrder } = this.props
    const notes = unsavedNotes[`${ar.Id.id}-${orderId}`]

    const order = await upsertAccountingOrder({ id: orderId, notes })
    if (order) {
      this.submit(this.state)
    }
  }

  renderRow = (r) => {
    const { manualPmtTypeMap, type, unsavedNotes } = this.state
    const { pFormatCommaSeparatedCurrency } = this.props
    const { orders, largestDaysPastDue, unpaidBalance } = r
    const key = (r.Id.id || '') + (r.Id.name || '')
    const link = `../invoices?ar_client_id=${r.Id.id}`

    if (orders && orders.length > 0) {
      return (
        <div
          key={r.Id.id}
          className={`account-receivable-table-container ${
            this.state[key] ? 'open' : 'closed'
          }`}
        >
          <div
            className="accouont-receivable-table-headers"
            onClick={() => this.setState({ [key]: !this.state[key] })}
          >
            <p id="name">{r.Id.name}</p>
            <div className="account-receivable-amounts">
              <p> # Unpaid: {orders.length}</p>
              <p>
                {' '}
                Oldest Past Due: {largestDaysPastDue.daysPastDue} (
                {largestDaysPastDue.orderNumber}){' '}
              </p>
              <p id="total-unpaid">
                {' '}
                Total Unpaid: ${pFormatCommaSeparatedCurrency(unpaidBalance)}
              </p>
              <span className="accounts-toggle">▶</span>
            </div>
          </div>
          {type !== 'sales_rep' && (
            <a
              className="order-link"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Create new batch invoice with all oustanding orders
            </a>
          )}
          <table className="account-receivable-table">
            <tr>
              {tableHeaders.map((h) => (
                <th key={h}>{h}</th>
              ))}
            </tr>
            {this.state[key] &&
              orders.map((u) => {
                const notes = unsavedNotes[`${r.Id.id}-${u.id}`] || u.notes
                const isGrpOrder = u.orderType === 'Group Order'
                const isCatOrder = u.orderType === 'Catering' && u.id !== ''
                const isPopUp = u.orderType === 'Pop-Up'
                const refLink = isGrpOrder
                  ? `../group-orders/${u.accountId}/${u.date}`
                  : isPopUp
                    ? `../pop-ups/${u.id}`
                    : `../sales/${u.id}`

                return (
                  <tr key={u.id}>
                    <td className="blue">
                      {isCatOrder || isGrpOrder || isPopUp ? (
                        <a
                          className="order-link"
                          href={refLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {u.orderNumber}
                        </a>
                      ) : (
                        <div> {u.orderNumber} </div>
                      )}

                      <AuthorizedDisplay roles={['finance', 'master admin']}>
                        <Button
                          label="Mark Paid"
                          padding="10px"
                          onClick={() =>
                            this.onMarkPaid(u.id, u.orderNumber, r)
                          }
                        />

                        <select
                          value={
                            manualPmtTypeMap[u.id] || ManualPaymentTypes[0]
                          }
                          onChange={(e) =>
                            this.onChangePmtType(u.id, e.target.value)
                          }
                        >
                          {ManualPaymentTypes.map((pmtType) => (
                            <option key={pmtType} value={pmtType}>
                              {' '}
                              {pmtType}{' '}
                            </option>
                          ))}
                        </select>
                      </AuthorizedDisplay>
                    </td>
                    <td>
                      {u.invoiceNumber ? (
                        u.invoiceNumber.slice(0, 4) === 'MINV' ? (
                          <a
                            className="order-link"
                            href={`${config.api_host}/accounting/invoice/${u.invoiceId}/batch_invoice_pdf`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {u.invoiceNumber}
                          </a>
                        ) : (
                          u.invoiceNumber
                        )
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td id="order-date">
                      {Moment(u.date).format('MM-DD-YYYY')}
                    </td>
                    <td>{type === 'sales_rep' ? u.account : u.salesRep}</td>
                    <td>{u.csmName}</td>
                    <td>{u.hunterName}</td>
                    <td>
                      <div className="account-receivable-contact">
                        <p>{u.contact && `${u.contact.name}`}</p>
                        <div className="contact-hover-container">
                          <img className="table-icon" src={phone} alt="phone" />
                          <div className="contact-hover">
                            <p>{u.contact && `${u.contact.phoneNumber}`}</p>
                          </div>
                        </div>
                        <div className="contact-hover-container">
                          <a
                            className="email-link"
                            href={u.contact && `mailto:${u.contact.email}`}
                          >
                            <img
                              className="table-icon"
                              src={email}
                              alt="email"
                            />
                          </a>
                          <div className="contact-hover email">
                            <p>{u.contact && `${u.contact.email}`}</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{u.paymentMethod}</td>
                    <td>{NetPaymentDays[u.netPaymentDays]}</td>
                    <td>{AutoBatchPeriods[u.autoBatchPeriod]}</td>
                    <td>{Moment(u.effectiveDate).format('MM-DD-YYYY')}</td>
                    <td id="due-date">
                      {Moment(u.dueDate).format('MM-DD-YYYY')}
                    </td>
                    <td id="days-past-due">{u.daysPastDue}</td>
                    <td id="total">
                      ${pFormatCommaSeparatedCurrency(u.total)}
                    </td>
                    <td id="unpaid-amount">
                      ${pFormatCommaSeparatedCurrency(u.unpaidBalance)}
                    </td>
                    <td id="notes">
                      <TextArea
                        value={notes}
                        onChange={this.onChangeOrderNotes(u.id, r)}
                      />
                      <Button
                        onClick={() => this.updateOrderNotes(u.id, r)}
                        label={'Save Notes'}
                      />
                    </td>
                  </tr>
                )
              })}
          </table>
        </div>
      )
    }

    return null
  }

  render() {
    const {
      isMounted,
      isSearching,
      isFilteredAndNotSubmitted,
      fromDate,
      toDate,
      type,
      name,
      sort,
      selectedHeadquarters,
    } = this.state
    const { accountsReceivable, isLoading, headquarters } = this.props

    const arType = type == 'sales_rep' ? 'Sales Reps' : 'Accounts'

    const fromDateStr = fromDate ? Moment(fromDate).format('YYYY-MM-DD') : ''
    const toDateStr = toDate ? Moment(toDate).format('YYYY-MM-DD') : ''

    return (
      <div className="account-receivable-dashboard">
        <FlexContainer alignItems="center">
          {isMounted && (
            <DateFilter
              isDisabled={isLoading}
              clearable={true}
              defaultFromDate={fromDate && Moment(fromDate)}
              defaultToDate={toDate && Moment(toDate)}
              reloadData={({ fromDate, toDate }) =>
                this.setDates({
                  fromDate: fromDate
                    ? Moment.tz(fromDate, 'America/New_York')
                        .startOf('day')
                        .format()
                    : fromDate,
                  toDate: toDate
                    ? Moment.tz(toDate, 'America/New_York')
                        .endOf('day')
                        .format()
                    : toDate,
                })
              }
            />
          )}
          <Dropdown
            width="250px"
            value={[type, sort]}
            label="Sort By"
            defaultValue=""
            marginBottom="0"
            onChange={this.filterAR}
          >
            {filterOptions.map((o) => (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            ))}
          </Dropdown>
          <div style={{ minWidth: '20px' }} />
          <Input
            width="200px"
            label="Name Search"
            marginBottom="0"
            value={name}
            onChange={this.onChangeAccountName}
          />
          <div style={{ minWidth: '20px' }} />
          <Dropdown
            width="200px"
            value=""
            label="Headquarters"
            defaultValue=""
            marginBottom="0"
            onChange={(e) => this.onSelectHeadquarter(e.target.value)}
          >
            <option key={-1} value="" disabled hidden>
              - Select -
            </option>
            {headquarters.map((hq) => (
              <option key={hq.id} value={hq.id}>
                {hq.name}
              </option>
            ))}
          </Dropdown>
          <div style={{ minWidth: '20px' }} />
          <FlexContainer flexWrap="wrap">
            {Object.keys(selectedHeadquarters).map((hqId) => (
              <MultiSelectOption
                key={hqId}
                onClick={() => this.onRemoveHeadquarter(hqId)}
              >
                {selectedHeadquarters[hqId].name}
              </MultiSelectOption>
            ))}
          </FlexContainer>

          {!isFilteredAndNotSubmitted && (
            <ExportCSVButton
              text="Export CSV"
              filename={getCsvFileName(
                fromDateStr,
                toDateStr,
                selectedHeadquarters,
              )}
              pCSVItems={(ar) => ar}
              data={this.props.pAccountsReceivableCSV(accountsReceivable)}
              columns={ARColumns}
            />
          )}

          {!isSearching && (
            <Button
              label="Search"
              padding="10px"
              margin="5px"
              onClick={() => this.submit(this.state)}
            />
          )}
        </FlexContainer>
        <div style={{ height: '20px' }} />
        {isLoading && (
          <div className="loading-gif">
            <img src={loadGif} alt="loading image" />
          </div>
        )}
        {!isLoading &&
          !isFilteredAndNotSubmitted &&
          accountsReceivable.length == 0 && (
            <p>
              {' '}
              Unable to find {arType} with unpaid orders within those dates{' '}
            </p>
          )}
        {!isLoading &&
          !isFilteredAndNotSubmitted &&
          accountsReceivable.map((u) => this.renderRow(u))}
      </div>
    )
  }
}

AccountsReceivable.propTypes = {
  isLoading: PropTypes.bool,
  accountsReceivable: PropTypes.array,
  accountName: PropTypes.string,
  email: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  filteredHqs: PropTypes.array,
  headquarters: PropTypes.array,
  UIService: PropTypes.object,

  getUnpaidAccounts: PropTypes.func,
  loadOrder: PropTypes.func,
  markOrderPaid: PropTypes.func,
  pFormatCommaSeparatedCurrency: PropTypes.func,
  setSortAccounts: PropTypes.func,
  setSortSalesReps: PropTypes.func,
  updateARAfterMarkingPaid: PropTypes.func,
  upsertAccountingOrder: PropTypes.func,
  pAccountsReceivableCSV: PropTypes.func,
}

export default AccountsReceivable
