import { connect } from 'react-redux'

import { GroupOrdersCalendar } from '@components/groupOrders'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'
import { config } from 'hungry-core2'

const mapStateToProps = (state: any) => {
  const { user, router, groupOrder } = state
  const { lastLoggedInHq, locale } = user
  const { dateMenus, changedDateMenuMap, autoStagedMenus } = groupOrder
  const port = router.location.port === 3000 ? 3001 : 3000
  const uri =
    config.marketplace_url +
    (config.marketplace_url === 'http://localhost' ? `:${port}` : '')
  const invoiceUrl = `${uri}/group-order-invoices`
  const groupOrderUrl = `${config.api_host}/group-orders`

  return {
    groupOrderUrl,
    headquarter: lastLoggedInHq,
    invoiceUrl,
    locale,
    dateMenus,
    changedDateMenuMap,
    autoStagedMenus,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService, GroupOrdersService } = services
  const {
    DeleteDateMenus,
    SearchClientSettings,
    GetGroupOrderBySettingsDate,
    LoadAccountExecutives,
    SaveDateMenu,
    SearchDateMenus,
    MarkGroupOrderPaid,
    RefundOrderInGroupOrder,
    SendPreEventEmails,
  } = coordinators
  const {
    pResponseAccountExecutives,
    pGroupOrderOrdersCSV,
    pGroupOrderItemsCSV,
    pRequestSaveDateMenu,
    pResponseGroupOrderClientSettings,
  } = presenters.Api

  const {
    newContact,
    setDateMenus,
    updateDateMenus,
    setChangedDateMenuMap,
    clearChangedDateMenuMap,
    setAutoStagedMenus,
  } = GroupOrdersService
  const { displayFailureMessage } = UIService.FlashMessage
  const deleteDateMenus = DeleteDateMenus({
    RestService,
    UIService,
    HandleError,
  })
  const searchClientSettings = SearchClientSettings({
    RestService,
    HandleError,
    pResponseGroupOrderClientSettings,
  })
  const getGroupOrderBySettingsDate = GetGroupOrderBySettingsDate({
    RestService,
  })
  const refundOrderInGroupOrder = RefundOrderInGroupOrder({
    RestService,
    UIService,
    HandleError,
  })
  const saveDateMenu = SaveDateMenu({
    RestService,
    UIService,
    HandleError,
    pRequestSaveDateMenu,
  })
  const searchSalesReps = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })(false) // is not vcx
  const searchDateMenus = SearchDateMenus({ RestService, HandleError })
  const markGroupOrderPaid = MarkGroupOrderPaid({
    RestService,
    UIService,
    HandleError,
  })
  const notifyChefs = SendPreEventEmails({
    RestService,
    UIService,
    HandleError,
  })

  return {
    deleteDateMenus,
    displayFailureMessage,
    searchClientSettings,
    getGroupOrderBySettingsDate,
    newContact,
    saveDateMenu,
    searchSalesReps,
    searchDateMenus,
    markGroupOrderPaid,
    pGroupOrderOrdersCSV,
    pGroupOrderItemsCSV,
    refundOrderInGroupOrder,
    notifyChefs,
    setDateMenus,
    updateDateMenus,
    setChangedDateMenuMap,
    clearChangedDateMenuMap,
    setAutoStagedMenus,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupOrdersCalendar)
