import Holidays from 'date-holidays'
import Moment from 'moment-timezone'

export const holidays = new Holidays('US')

export const initHolidaysInTZ = (timezone: string) => {
  holidays.init('US', {
    timezone,
    languages: ['en'],
    types: ['public'],
  })
}

// custom hungry holidays only support fixed dates
const NonHungryHolidays = ['1/1']
const CustomHungryHolidays = ['1/2']

export const checkIsHoliday = (
  date: Moment.Moment,
  createdAt?: string,
): boolean => {
  if (checkShouldUse2024Holidays(createdAt)) {
    return !!HolidayDates2024[date.format('M/D')]
  }

  // Handling for custom hungry holidays
  if (NonHungryHolidays.includes(date.format('M/D'))) {
    return false
  } else if (CustomHungryHolidays.includes(date.format('M/D'))) {
    return true
  }

  const results = holidays.isHoliday(date.toDate())
  if (results === false) {
    return false
  }

  return results.some((result) => result.type === 'public')
}

const checkShouldUse2024Holidays = (createdAt?: string) => {
  const cutoff = Moment.tz('2025-02-05 12:55', 'America/New_York')

  return createdAt && Moment(createdAt).isBefore(cutoff)
}

const HolidayDates2024: Record<string, boolean> = {
  '1/2': true, // new years
  '1/15': true, // mlk
  '2/19': true, // presidents day
  '5/27': true, // memorial day
  '6/19': true, // juneteenth
  '7/4': true, // july4th
  '9/2': true, // labor day
  '10/14': true, // columbus day
  '11/11': true, // veterans day
  '11/28': true, // thanksgiving day
  '12/25': true, // christmas day
}
