import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  ChangeEvent,
} from 'react'
import moment from 'moment-timezone'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

import { FlexContainer, Table, YSpacing } from '@components/common'
import { Checkbox, Dropdown, LabelInfo } from '@components/common/form'
import { getTimeSlotTimes } from '../../../utils'
import {
  ScheduleUpdateType,
  ScheduleUpdateTypes,
  ScheduleUpdateStatus,
  ChefScheduleUpdate as ChefScheduleUpdateType,
  ChefTimeSlotReq,
  TimeSlotReq,
  RemoveDayInRecurReq,
} from '@types'

const Days: { [key: number]: string } = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thurday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
}

interface ChefScheduleUpdatesProps {
  headquarter: number
  loadChefScheduleUpdates: (req: {
    type?: string
    statuses?: number[]
    limit: number
    offset: number
  }) => Promise<ChefScheduleUpdateType[]>
  processChefScheduleUpdateRequest: (
    id: string,
    status: number,
    chefId: string,
    fromDate: string,
    toDate: string | undefined,
  ) => Promise<boolean | undefined>
}

const AllStatuses = [
  { key: 'Pending', value: ScheduleUpdateStatus.Pending },
  { key: 'Rejected', value: ScheduleUpdateStatus.Rejected },
]

const AllUpdateTypes = [
  { key: 'Create New Schedule', value: 'create' },
  { key: 'Update Existing Schedule', value: 'update' },
  { key: 'Delete Schedule', value: 'delete' },
  { key: 'Delete Day In Recurring Schedule', value: 'deleteDayInRecur' },
]

const ChefScheduleUpdates: React.FC<ChefScheduleUpdatesProps> = ({
  headquarter,
  loadChefScheduleUpdates,
  processChefScheduleUpdateRequest,
}) => {
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [type, setType] = useState<ScheduleUpdateType>('update')
  const [statuses, setStatuses] = useState([ScheduleUpdateStatus.Pending])

  const [updateReqs, setUpdateReqs] = useState<ChefScheduleUpdateType[]>([])

  const loadUpdates = useCallback(async () => {
    const req: {
      type?: string
      statuses?: number[]
      limit: number
      offset: number
      hqId: number
    } = {
      type,
      limit,
      offset: page * limit,
      hqId: headquarter,
    }
    if (statuses.length > 0) {
      req.statuses = statuses
    }
    const scheduleUpdates = await loadChefScheduleUpdates(req)
    if (scheduleUpdates) {
      setUpdateReqs(scheduleUpdates)
    } else {
      setUpdateReqs([])
    }
  }, [type, statuses, limit, page, headquarter, loadChefScheduleUpdates])

  useEffect(() => {
    loadUpdates()
  }, [loadUpdates])

  const onChangePage = (dir: number) => {
    if (dir === -1 && page > 0) {
      setPage(page - 1)
    } else if (dir === 1 && updateReqs.length === limit) {
      setPage(page + 1)
    }
  }

  const generateDateRangeFromTimeslot = (
    data: ChefTimeSlotReq,
  ): { fromDate: string; toDate: string | undefined } => {
    let fromDate: string
    let toDate: string | undefined
    if (data.week === 'recurring') {
      const targetDate = moment().add(data.day, 'days')
      fromDate = targetDate
        .clone()
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ssZ')
    } else {
      const weekStart = moment(data.week, 'MM/DD/YYYY')
      const targetDate = weekStart.clone().add(data.day - 1, 'days')
      ;(fromDate = targetDate
        .clone()
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ssZ')),
        (toDate = targetDate
          .clone()
          .endOf('day')
          .format('YYYY-MM-DDTHH:mm:ssZ'))
    }

    return { fromDate, toDate }
  }

  const onSelectStatus = async (
    updateReq: ChefScheduleUpdateType,
    status: number,
  ) => {
    const { fromDate, toDate } = generateDateRangeFromTimeslot(
      updateReq.chefTimeSlotReq,
    )
    const ok = await processChefScheduleUpdateRequest(
      updateReq.id,
      status,
      updateReq.chefId,
      fromDate,
      toDate,
    )
    if (ok) {
      loadUpdates()
    }
  }

  return (
    <div>
      <FlexContainer alignItems="center">
        <FlexContainer flexDirection="column" width="20%">
          <LabelInfo label="Approval Status" />
          {AllStatuses.map((status, i) => (
            <Fragment key={`${status.key}-${i}`}>
              <Checkbox
                key={status.key}
                checked={statuses.includes(status.value)}
                label={status.key}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.checked) {
                    setStatuses([...statuses, status.value])
                  } else {
                    setStatuses(statuses.filter((s) => s !== status.value))
                  }
                }}
              />
              <YSpacing height="5px" />
            </Fragment>
          ))}
        </FlexContainer>
        <FlexContainer flexDirection="column" width="20%">
          <Dropdown
            label="Update Type"
            value={type}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              setType(e.target.value as ScheduleUpdateType)
            }}
          >
            {AllUpdateTypes.map((updateType) => (
              <option key={updateType.key} value={updateType.value}>
                {updateType.key}
              </option>
            ))}
          </Dropdown>
        </FlexContainer>
        <FlexContainer
          flexDirection="row"
          width="60%"
          alignItems="center"
          justifyContent="center"
        >
          <Dropdown
            label="Results Per Page"
            value={limit}
            width="33%"
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              setLimit(parseInt(e.target.value))
            }}
          >
            <option value={15}>15</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Dropdown>
          <FlexContainer
            flexDirection="row"
            width="33%"
            justifyContent="center"
            alignItems="center"
          >
            <IoIosArrowBack size={30} onClick={() => onChangePage(-1)} />
            <div className="mx-4">Page {page + 1}</div>
            <IoIosArrowForward size={30} onClick={() => onChangePage(1)} />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <Table>
        <tr>
          <th className="w-1/4">Chef</th>
          <th className="w-1/4">Update</th>
          <th className="w-1/4">Date Submitted</th>
          <th className="w-1/4">Status</th>
        </tr>
        {updateReqs.map((update, idx) => (
          <tr key={idx}>
            <td>{update.chefName}</td>
            <td>
              <ChefScheduleUpdate updateReq={update} />
            </td>
            <td>{moment(update.createdAt).format('MM/DD/YYYY')}</td>
            <td>
              <ScheduleStatus
                updateReq={update}
                onSelectStatus={onSelectStatus}
              />
            </td>
          </tr>
        ))}
      </Table>
    </div>
  )
}

interface ChefScheduleUpdateProps {
  updateReq: ChefScheduleUpdateType
}

const ChefScheduleUpdate: React.FC<ChefScheduleUpdateProps> = ({
  updateReq,
}) => {
  if (updateReq.type.includes('delete')) {
    if (updateReq.type === ScheduleUpdateTypes.Delete) {
      return (
        <div>
          Remove <strong>entire</strong> recurring schedule
        </div>
      )
    } else {
      const { week, day } = updateReq.chefTimeSlotReq as RemoveDayInRecurReq
      const date = moment(week)
        .add(day - 1, 'days')
        .format('MM/DD')

      return (
        <div>
          Removing {Days[day]} <strong>({date})</strong> from schedule
        </div>
      )
    }
  } else {
    const update = updateReq.chefTimeSlotReq as TimeSlotReq
    const {
      recurring,
      recurringEnds,
      endDate,
      recurringDaysOfWeek,
      week,
      day,
      start,
      end,
    } = update
    const date = moment(week)
      .add(day - 1, 'days')
      .format('MM/DD')

    return (
      <div>
        <p>
          {updateReq.slotId === '' ? 'Creating new ' : 'Updating '}
          {recurring ? 'recurring' : ''} schedule.
        </p>
        {recurring ? (
          <div>
            <ul>
              {recurringDaysOfWeek
                .sort((a, b) => a - b)
                .map((day, idx) => (
                  <li key={idx}>
                    {Days[day]}: {getTimeSlotTimes(start, end)}
                  </li>
                ))}
            </ul>
            {recurringEnds && (
              <p className="font-semibold">
                Recurring Ends: {moment(endDate).format('MM/DD/YYYY')}
              </p>
            )}
          </div>
        ) : (
          <div>
            <p>
              {Days[day]} - <span className="font-semibold">({date})</span>
            </p>
            <p>{getTimeSlotTimes(start, end)}</p>
          </div>
        )}
      </div>
    )
  }
}

interface ScheduleStatusProps {
  updateReq: ChefScheduleUpdateType
  onSelectStatus: (updateReq: ChefScheduleUpdateType, status: number) => void
}

const ScheduleStatus: React.FC<ScheduleStatusProps> = ({
  updateReq,
  onSelectStatus,
}) => {
  const { status } = updateReq
  if (status === ScheduleUpdateStatus.Approved) {
    return <p className="text-green-600">Approved</p>
  } else if (status === ScheduleUpdateStatus.Rejected) {
    return <p className="text-red-600">Rejected</p>
  } else {
    return (
      <div className="flex flex-col items-center">
        <p className="text-orange-500 mb-3">Pending</p>
        <div className="flex flex-row w-full justify-center">
          <button
            className="px-2 py-2 bg-green-600 text-white mr-1 rounded-lg w-1/4"
            onClick={() =>
              onSelectStatus(updateReq, ScheduleUpdateStatus.Approved)
            }
          >
            Approve
          </button>
          <button
            className="px-2 py-2 bg-red-600 text-white ml-1 rounded-lg w-1/4"
            onClick={() =>
              onSelectStatus(updateReq, ScheduleUpdateStatus.Rejected)
            }
          >
            Reject
          </button>
        </div>
      </div>
    )
  }
}

export default ChefScheduleUpdates
