import Moment from 'moment-timezone'
import { assignParams } from '../../../utils'
import { diffObjects } from '@utils'

export const SaveInvoice =
  ({ RestService, UIService, pRequestUpdateInvoice, HandleError }) =>
  async (data, generate = false, showMssg = true) => {
    const { invoice, callbackFn } = data
    const req = pRequestUpdateInvoice(data)
    let result
    try {
      if (invoice.id) {
        result = await RestService.put(`/accounting/invoice/${invoice.id}`, req)
      } else if (generate) {
        result = await RestService.post('/accounting/invoice/generate', req)
      } else {
        result = await RestService.post('/accounting/invoice', req)
      }
    } catch (error) {
      HandleError({ error })

      return false
    }

    if (callbackFn) {
      UIService.FlashMessage.displaySuccessMssgOptions(
        `Invoice ${result.invoiceNumber} successfully ${
          invoice.id ? 'updated' : 'created'
        }.  `,
        {
          buttonTitle: `View ${result.invoiceNumber}`,
          buttonAction: () => callbackFn(result),
          isLink: true,
        },
      )
    } else if (showMssg) {
      UIService.FlashMessage.displaySuccessMessage(
        `Invoice ${result.invoiceNumber} successfully ${
          invoice.id ? 'updated' : 'created'
        }`,
      )
    }

    return result
  }

export const GetInvoice =
  ({ RestService, pResponseInvoice, HandleError }) =>
  async (id) => {
    try {
      const response = await RestService.get(`/accounting/invoice/${id}`)

      return pResponseInvoice(response)
    } catch (error) {
      HandleError({ error })
    }
  }

export const SearchInvoices =
  ({ RestService, pResponseInvoices, HandleError }) =>
  async ({
    from,
    to,
    isPaid,
    isResolved,
    clientId,
    search,
    orderSearch,
    offset = 0,
    limit = 20,
  }) => {
    try {
      const params = { offset, limit, isUniversal: true }

      if (from) {
        params.from = Moment(from).format()
      }

      if (to) {
        params.to = Moment(to).format()
      }

      if (clientId) {
        params.clientId = clientId
      }

      if (search) {
        params.search = search
      }

      if (orderSearch) {
        params.orderSearch = orderSearch
      }

      if (typeof isPaid === 'boolean') {
        params.isPaid = isPaid
      }

      if (typeof isResolved === 'boolean') {
        params.isResolved = isResolved
      }

      const invoices = await RestService.get('/accounting/invoices', params)

      return pResponseInvoices(invoices)
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const SearchAccountingOrders =
  ({ RestService, HandleError }) =>
  async ({
    search,
    orderIds,
    to,
    from,
    orderTypes,
    cateringTypes,
    clientId,
    isPaid,
    page,
    limit,
    excludedIds,
  }) => {
    try {
      const params = {
        search,
        from: from ? Moment(from).format('YYYY-MM-DDTHH:mm:ssZ') : '',
        to: to ? Moment(to).format('YYYY-MM-DDTHH:mm:ssZ') : '',
        clientId,
        offset: page * limit,
        limit,
        isPaid,
      }

      assignParams(params, 'ids', orderIds)
      assignParams(params, 'orderTypes', orderTypes)
      assignParams(params, 'cateringTypes', cateringTypes)
      assignParams(params, 'excludedIds', excludedIds)

      Object.keys(params).forEach((key) => {
        if (params[key] === undefined || params[key] === '') {
          delete params[key]
        }
      })

      return await RestService.get('/accounting/orders/search', params)
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const SearchAccountingOrderPageCount =
  ({ RestService, HandleError }) =>
  async ({
    search,
    orderIds,
    to,
    from,
    orderTypes,
    cateringTypes,
    clientId,
    isPaid,
    page,
    limit,
    excludedIds,
  }) => {
    try {
      const params = {
        search,
        from: from ? Moment(from).format('YYYY-MM-DDTHH:mm:ssZ') : '',
        to: to ? Moment(to).format('YYYY-MM-DDTHH:mm:ssZ') : '',
        clientId,
        offset: page * limit,
        limit,
        isPaid,
      }

      assignParams(params, 'ids', orderIds)
      assignParams(params, 'orderTypes', orderTypes)
      assignParams(params, 'cateringTypes', cateringTypes)
      assignParams(params, 'excludedIds', excludedIds)

      Object.keys(params).forEach((key) => {
        if (params[key] === undefined || params[key] === '') {
          delete params[key]
        }
      })

      return await RestService.get(
        '/accounting/orders/search-page-count',
        params,
      )
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const PayInvoice =
  ({ RestService, UIService, HandleError }) =>
  async ({
    invoiceId,
    cardholderName,
    tip,
    nonce,
    billingAddress,
    paymentMethod,
    customPayAmount,
  }) => {
    try {
      const params = {
        invoiceId,
        cardholderName,
        tip,
        nonce,
        billingAddress,
        paymentMethod,
      }

      const inv = await RestService.post(
        `/accounting/invoice/pay/${invoiceId}`,
        params,
        { timeout: 20000 },
      )
      if (inv) {
        let message = `${inv.invoiceNumber} has been paid!`
        if (customPayAmount) {
          message = `${
            inv.invoiceNumber
          } has been partially paid for $${customPayAmount.toFixed(2)}`
        }
        UIService.FlashMessage.displaySuccessMessage(message)

        return inv
      }
    } catch (error) {
      HandleError({ error })
    }
  }

export const RefundOrderOnInvoice =
  ({ RestService, UIService, HandleError }) =>
  async ({
    order,
    invoiceId,
    refundAmount,
    paymentId,
    userName,
    transaction,
  }) => {
    if (refundAmount > transaction.amount) {
      UIService.FlashMessage.displayFailureMessage(
        'Refund amount cannot be greater than the amount paid',
      )

      return
    }
    try {
      const params = {
        invoiceId,
        orderId: order.id,
        refundAmount,
        paymentId,
        userName,
        transactionId: transaction.id,
      }

      const inv = await RestService.put(
        `/accounting/invoice/refund/single-order`,
        params,
      )
      if (inv) {
        UIService.FlashMessage.displaySuccessMessage(
          `Order ${order.orderNumber} has been refunded $${refundAmount.toFixed(
            2,
          )}`,
        )

        return inv
      }
    } catch (error) {
      if (error.toString() === 'Error: Request failed with status code 409') {
        UIService.FlashMessage.displayFailureMessage(
          'Payment Status is still being processed and cannot refund until fully proccessed. Please either void invoice payment & ensure correct amount for new invoice payments or try to refund in a little while.',
        )

        return
      }
      HandleError({ error })
    }
  }

export const VoidInvoicePayment =
  ({ RestService, UIService, HandleError }) =>
  async ({ invoice, voidedBy, txn }) => {
    try {
      const params = {
        invoiceId: invoice.id,
        transactionId: txn.id,
        voidedBy,
      }

      const confirmedVoid = await UIService.ConfirmationModal.show({
        text: 'Are you sure you wish to proceed with voiding the payment for this invoice?',
      })
      if (confirmedVoid) {
        const inv = await RestService.post(
          '/accounting/invoice/void/invoice-payment',
          params,
        )
        if (inv) {
          UIService.FlashMessage.displaySuccessMessage(
            `Inv ${inv.invoiceNumber} has had payment ending in ${
              txn.paymentAlias
            } voided for $${txn.amount.toFixed(2)}`,
          )

          return inv
        }
      }
    } catch (error) {
      HandleError({ error })
    }
  }

export const SearchDetailInvoiceAccounts =
  ({ RestService, pResponseInvoiceAccounts }) =>
  async (settings) => {
    const accounts = await RestService.get('/api/admin/accounts', {
      ...settings,
      account_serializer: 'for_invoice',
    })

    return pResponseInvoiceAccounts(accounts)
  }

export const LoadInvoiceAccount =
  ({ RestService, pResponseInvoiceAccount }) =>
  async (accountId) => {
    const account = await RestService.get(`/api/admin/accounts/${accountId}`, {
      for_invoice: true,
    })

    return pResponseInvoiceAccount(account)
  }

export const CheckInvoiceTxnStatuses =
  ({ RestService, HandleError }) =>
  async (txnIds) => {
    try {
      const statusMap = await RestService.get(
        `/accounting/invoice/transaction-status/${txnIds.join(',')}`,
      )

      if (statusMap) {
        return statusMap
      }
    } catch (error) {
      HandleError({ error })
    }
  }

export const GetInvoiceHistory =
  ({ RestService, HandleError, UIService }) =>
  async (invoiceId, invoiceNumber) => {
    try {
      const auditHistory = []
      let fin = false
      const limit = 10
      let offset = 0
      while (!fin) {
        const auditLogsPage = await RestService.get(
          `/accounting/invoice/audit-logs`,
          { invoiceId, offset, limit },
        )
        if (auditLogsPage.length !== 10) {
          fin = true
        }
        offset += limit
        auditHistory.push(...auditLogsPage)
      }

      // audits are ordered latest change first
      auditHistory.forEach((audit, i) => {
        const lastInvoice = auditHistory[i + 1]
          ? auditHistory[i + 1].invoice
          : {}
        audit.diffs = diffObjects(lastInvoice, audit.invoice)
        audit.snapshot = audit.invoice
      })

      UIService.AuditHistory.show(
        `${invoiceNumber} Invoice History`,
        auditHistory,
      )
    } catch (error) {
      HandleError({ error })
    }
  }
