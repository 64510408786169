import { connect } from 'react-redux'

import { GroupOrdersPage } from '@components/groupOrders'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'
import { config } from 'hungry-core2'
import { checkOrderIsEditableNow, orderBalancesEditCutoffDate } from '@utils'

const mapStateToProps = (state: any) => {
  const {
    user,
    router,
    groupOrderPage,
    groupOrder,
    headquarter: stateHeadquarter,
  } = state
  const { dateMenus } = groupOrder
  const { locale, email } = user
  const port = router.location.port === 3000 ? 3001 : 3000
  const uri =
    config.marketplace_url +
    (config.marketplace_url === 'http://localhost' ? `:${port}` : '')
  const invoiceUrl = `${uri}/group-order-invoices`
  const groupOrderUrl = `${config.api_host}/group-orders`
  const headquarter = user.lastLoggedInHq
  const { headquarters } = stateHeadquarter || {}
  const currentHq = headquarters.find((hq: any) => hq.id === headquarter)

  return {
    email,
    groupOrderUrl,
    headquarter,
    currentHq,
    invoiceUrl,
    locale,
    groupOrderPage,
    dateMenus,
    user,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService, GroupOrdersService } = services
  const {
    AddRefundToSubOrder,
    CheckBatchInvoicedBalances,
    DeleteDateMenus,
    SearchClientSettings,
    GetAccountingOrder,
    GetClientGlobalSettings,
    GetGroupOrderAuditLogs,
    GetGroupOrderBySettingsDate,
    LoadAccountExecutives,
    LoadAccount,
    SaveClientGlobalSettings,
    SaveStaffingFeeSettings,
    SaveDateMenu,
    SearchDateMenus,
    MarkGroupOrderPaid,
    RefundOrderInGroupOrder,
    UpdateGroupOrderPONumber,
    UpdateGroupOrderFees,
    SearchAccountingOrders,
    PrepareGroupOrderInvoice,
    CalculateCurrentDeliveryFees,
    SearchGroupOrders,
  } = coordinators
  const {
    pResponseAccountExecutives,
    pResponseAccount,
    pResponseGroupOrderAuditLogs,
    pGroupOrderOrdersCSV,
    pGroupOrderItemsCSV,
    pResponseGroupOrderClientSettings,
    pRequestSaveDateMenu,
  } = presenters.Api
  const { isAuthorized } = presenters

  const {
    newClientSettings,
    newContact,
    clearGroupOrderPage,
    newSubsidySetting,
    setDateMenus,
  } = GroupOrdersService
  const {
    displayFailureMessage,
    displayInfoMessage,
    displayInfoMessageOptions,
  } = UIService.FlashMessage
  const addRefundToSubOrder = AddRefundToSubOrder({ RestService, HandleError })
  const deleteDateMenus = DeleteDateMenus({
    RestService,
    UIService,
    HandleError,
  })
  const searchClientSettings = SearchClientSettings({
    RestService,
    HandleError,
    pResponseGroupOrderClientSettings,
  })
  const getAccountingOrder = GetAccountingOrder({ RestService, HandleError })
  const getClientGlobalSettings = GetClientGlobalSettings({
    RestService,
    HandleError,
  })
  const getGroupOrderAuditLogs = GetGroupOrderAuditLogs({
    RestService,
    HandleError,
    pResponseGroupOrderAuditLogs,
  })
  const getGroupOrderBySettingsDate = GetGroupOrderBySettingsDate({
    RestService,
  })
  const saveClientGlobalSettings = SaveClientGlobalSettings({
    RestService,
    UIService,
    HandleError,
  })
  const saveStaffingFeeSettings = SaveStaffingFeeSettings({
    RestService,
    UIService,
    HandleError,
  })
  const refundOrderInGroupOrder = RefundOrderInGroupOrder({
    RestService,
    UIService,
    HandleError,
  })
  const saveDateMenu = SaveDateMenu({
    RestService,
    UIService,
    HandleError,
    pRequestSaveDateMenu,
  })
  const searchSalesReps = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })(false) // is not vcx
  const searchDateMenus = SearchDateMenus({ RestService, HandleError })
  const markGroupOrderPaid = MarkGroupOrderPaid({
    RestService,
    UIService,
    HandleError,
  })
  const loadAccount = LoadAccount({ RestService, pResponseAccount })
  const updateGroupOrderFees = UpdateGroupOrderFees({
    RestService,
    UIService,
    HandleError,
    CheckBatchInvoicedBalances,
  })
  const updateGroupOrderPONumber = UpdateGroupOrderPONumber({
    RestService,
    UIService,
    HandleError,
  })
  const searchAccountingOrders = SearchAccountingOrders({
    RestService,
    HandleError,
  })
  const prepareGroupOrderInvoice = PrepareGroupOrderInvoice({
    RestService,
    HandleError,
  })
  const calculateCurrentDeliveryFees = CalculateCurrentDeliveryFees({
    UIService,
    RestService,
    HandleError,
    CheckBatchInvoicedBalances,
  })
  const searchGroupOrders = SearchGroupOrders({ RestService, HandleError })

  return {
    addRefundToSubOrder,
    checkOrderIsEditableNow,
    clearGroupOrderPage,
    deleteDateMenus,
    displayFailureMessage,
    displayInfoMessage,
    displayInfoMessageOptions,
    searchClientSettings,
    getAccountingOrder,
    getClientGlobalSettings,
    getGroupOrderAuditLogs,
    getGroupOrderBySettingsDate,
    isAuthorized,
    newClientSettings,
    newContact,
    saveClientGlobalSettings,
    saveStaffingFeeSettings,
    saveDateMenu,
    searchSalesReps,
    searchDateMenus,
    markGroupOrderPaid,
    loadAccount,
    orderBalancesEditCutoffDate,
    pGroupOrderOrdersCSV,
    pGroupOrderItemsCSV,
    refundOrderInGroupOrder,
    newSubsidySetting,
    setDateMenus,
    updateGroupOrderPONumber,
    updateGroupOrderFees,
    searchAccountingOrders,
    prepareGroupOrderInvoice,
    calculateCurrentDeliveryFees,
    searchGroupOrders,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupOrdersPage)
