import { connect } from 'react-redux'

import { OrderableHistory } from '@components/account/accountSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { account, clientReportingPrograms, dinerProfiles } = state

  return {
    account,
    accountId: account && account.id,
    clientReportingPrograms,
    dinerProfiles,
  }
}

const mapDispatchToProps = () => {
  const { ChefService, UIService, RestService } = services
  const { pBuildGetRequest, pResponseChefs } = presenters.Api
  const { LoadChefs } = coordinators

  const buildGetRequest = pBuildGetRequest
  const loadChefs = LoadChefs({ ChefService, RestService, pResponseChefs })
  const { Timer } = UIService
  const { callAfterTimeout } = Timer

  return {
    buildGetRequest,
    callAfterTimeout,
    loadChefs,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderableHistory)
