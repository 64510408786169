import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

import { AutocompleteInput } from '@containers/common/form'

const InitialState = {
  chef: null,
}

class CopyMenuItemModal extends Component {
  state = InitialState

  selectChef = async (chef) => {
    this.setState({ chef })
  }

  render() {
    const { onHide, show, copyMenuItem, menuItemId, currentChefId } = this.props
    const { chef } = this.state

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            Copy Menu Item
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="columns-container">
            <div className="columns-container__single">
              <div className="form-field-container">
                <div className="form-field-container__columns">
                  <div className="dropdown">
                    Copy menu item to chef
                    <AutocompleteInput
                      displayAttribute="name"
                      loaderFunction={this.props.loadChefs}
                      placeholder={'Find a Chef'}
                      onSelect={this.selectChef}
                    />
                  </div>
                </div>
                {chef && (
                  <button
                    onClick={async () => {
                      const success = await copyMenuItem(
                        menuItemId,
                        chef.id,
                        currentChefId,
                      )
                      if (success) {
                        this.props.loadMenuItems && this.props.loadMenuItems()
                      }
                    }}
                  >
                    Copy
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

CopyMenuItemModal.propTypes = {
  menuItemId: PropTypes.string,
  currentChefId: PropTypes.string,
  chef: PropTypes.object,
  show: PropTypes.bool,

  copyMenuItem: PropTypes.func,
  loadChefs: PropTypes.func,
  loadMenuItems: PropTypes.func,
  onHide: PropTypes.func,
}

export default CopyMenuItemModal
