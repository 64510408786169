import React, { useEffect, useState } from 'react'
import { config } from 'hungry-core2'
import { FaArrowCircleDown, FaArrowCircleUp, FaFilter } from 'react-icons/fa'
import Loader from '@components/common/Loader'
import XSpacing from '@components/common/XSpacing'
import YSpacing from '@components/common/YSpacing'
import {
  Checkbox,
  DateInput,
  Dropdown,
  TextInput,
} from '@components/common/form'
import { LinkDinerProfileToPastOrdersModal } from '@containers/account/accountSections'
import { AutocompleteInput } from '@containers/common/form'
import {
  Account,
  Address,
  Chef,
  Contact,
  Orderable,
  TableHeading,
  User,
  isUser,
  isAddress,
  isContact,
  OrderSettings,
} from '@types'
import moment, { Moment } from 'moment-timezone'
import { DraggableTableHeader } from '@components/common'

interface Filters {
  contactId: string | undefined
  chefId: string | undefined
  chefName: string | undefined
  dinerProfileId: string | undefined
  clientReportingProgramId: string | undefined
  dropoffAddressId: string | undefined
  page: number
  resultsPerPage: number
  search: string | undefined
  accountId: string | undefined
  serializer: string
  status: string
  reorder: string
  fromDate: undefined | Moment
  toDate: undefined | Moment
}

const NOT_CANCELED = 'not_canceled'

interface OrderableHistoryProps {
  account: Account
  buildGetRequest: (val: Filters) => string
  callAfterTimeout: (fn: () => void) => void
  dinerProfiles: { id: string; name: string }[]
  clientReportingPrograms: {
    id: string
    name: string
    dinerProfileIds: string[]
  }[]
  getOrderableCount: (filters: Filters) => Promise<number>
  getOrderableTotal: (filters: Filters) => Promise<number>
  loadChefs: (val: { search: string }) => Chef[]
  orderable: string // Proposal or Order - used for display
  orderableType: string // proposals or sales - used for redirect
  searchOrderables: (filters: Filters) => Promise<Orderable[]>
}

const initialTableHeadings: TableHeading[] = [
  {
    id: 'orderNumber',
    label: 'Number',
    key: 'orderNumber',
  },
  {
    id: 'dateMoment1',
    label: 'Event Date',
    key: 'dateMoment',
    format: 'MMM Do YYYY',
  },
  {
    id: 'dateMoment2',
    label: 'Time',
    key: 'dateMoment',
    format: 'h:mm a',
  },
  {
    id: 'headCount',
    label: 'Head Count',
    key: 'headCount',
  },
  {
    id: 'chefName',
    label: 'Chef',
    key: 'chefName',
  },
  {
    id: 'total',
    label: 'Total',
    key: 'total',
  },
  {
    id: 'dropoffAddress',
    label: 'Delivery Address',
    key: 'dropoffAddress',
  },
  {
    id: 'dinerProfile',
    label: 'Diner Profile',
    key: 'dinerProfileId',
  },
  {
    id: 'clientReportingProgram',
    label: 'Reporting Program',
    key: 'clientReportingProgramId',
  },
  {
    id: 'contactName',
    label: 'Contact Name',
    key: 'contact',
    subKey: 'name',
  },
  {
    id: 'contactEmail',
    label: 'Contact Email',
    key: 'contact',
    subKey: 'email',
  },
  {
    id: 'accountExecutive',
    label: 'Sales Rep',
    key: 'accountExecutive',
  },
]

const OrderableHistory = ({
  account,
  buildGetRequest,
  callAfterTimeout,
  clientReportingPrograms,
  dinerProfiles,
  getOrderableCount,
  getOrderableTotal,
  loadChefs,
  orderable,
  orderableType,
  searchOrderables,
}: OrderableHistoryProps) => {
  const [dinerProfileMap, setDinerProfileMap] = useState<
    Record<string, string>
  >({})
  const [reportingProgramMap, setReportingProgramMap] = useState<
    Record<string, string>
  >({})
  const [count, setCount] = useState(0)
  const [orderableTotal, setOrderableTotal] = useState(0)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [tableHeadings, setTableHeadings] =
    useState<TableHeading[]>(initialTableHeadings)
  const [filters, setFilters] = useState<Filters>({
    contactId: undefined,
    chefId: undefined,
    chefName: undefined,
    dinerProfileId: undefined,
    clientReportingProgramId: undefined,
    dropoffAddressId: undefined,
    page: 1,
    resultsPerPage: 20,
    search: undefined,
    accountId: account.id,
    serializer: 'for_account',
    reorder: 'client_set_up_time-desc',
    fromDate: undefined,
    toDate: undefined,
    status: NOT_CANCELED,
  })
  const [orderables, setOrderables] = useState<Orderable[]>([])
  const [showFilter, setShowFilter] = useState(true)
  const [showLinkDinerProfileModal, setShowLinkDinerProfileModal] =
    useState(false)

  const Colspan = tableHeadings.length

  useEffect(() => {
    const dpMap: Record<string, string> = {}
    const programMap: Record<string, string> = {}
    dinerProfiles.forEach((dp) => {
      dpMap[dp.id] = dp.name
    })
    clientReportingPrograms.forEach((p) => {
      programMap[p.id] = p.name
    })
    setDinerProfileMap(dpMap)
    setReportingProgramMap(programMap)
    searchOrdersWithCount(filters, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.id, dinerProfiles, clientReportingPrograms])

  const searchOrdersWithCount = (filters: Filters, getCounts: boolean) => {
    setOrderables([])
    loadOrders(filters)
    if (getCounts) {
      getOrderCountFromApi(filters)
      getOrderableTotalFromApi(filters)
    }
  }

  const getOrderCountFromApi = async (filters: Filters) => {
    const count = await getOrderableCount(filters)
    setCount(count)
  }

  const getOrderableTotalFromApi = async (filters: Filters) => {
    const total = await getOrderableTotal(filters)
    setOrderableTotal(total)
  }

  const loadOrders = async (filters: Filters) => {
    setIsLoading(true)
    const orderables = await searchOrderables(filters)
    setIsLoading(false)
    setOrderables(orderables)
  }

  const delayedSearchOrders = (filters: Filters, getCounts: boolean) => {
    callAfterTimeout(() => searchOrdersWithCount(filters, getCounts))
  }

  const updateFilters = (
    newFilterValues: Partial<Filters>,
    getCounts = true,
  ) => {
    const newFilters = { ...filters, ...newFilterValues }
    setFilters(newFilters)
    delayedSearchOrders(newFilters, getCounts)
  }

  const formatValue = (
    value:
      | string
      | number
      | Moment
      | User
      | Contact
      | Address
      | OrderSettings
      | null
      | undefined,
    key: keyof Orderable,
    subkey?: keyof Contact,
    format?: string,
  ) => {
    if (value === undefined || value === null) {
      return 'N/A'
    }

    if (typeof value === 'string') {
      if (key === 'dinerProfileId') {
        return dinerProfileMap[value] || 'N/A'
      } else if (key === 'clientReportingProgramId') {
        return reportingProgramMap[value] || 'N/A'
      } else {
        return value.toUpperCase()
      }
    }

    if (typeof value === 'number') {
      return value.toLocaleString()
    }

    if (moment.isMoment(value)) {
      if (format) {
        return value.format(format)
      } else {
        return value.format('MMMM Do YYYY, h:mm:ss a')
      }
    }

    if (isUser(value)) {
      return `${value.firstName} ${value.lastName}`
    }

    if (isContact(value)) {
      if (subkey && subkey in value) {
        return value[subkey]
      }

      return value.email
    }

    if (isAddress(value)) {
      return `${value.line1}, ${value.city}, ${value.state}, ${value.zip}`
    }

    return ''
  }

  const renderDataCell = (orderable: Orderable, heading: TableHeading) => {
    const val = formatValue(
      orderable[heading.key],
      heading.key,
      heading.subKey,
      heading.format,
    )
    if (heading.key === 'orderNumber') {
      const link = `../${orderableType}/${orderable.id}`

      return (
        <a
          className="order-link"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {val}
        </a>
      )
    }

    return (
      <div>
        <p>{val}</p>
      </div>
    )
  }

  const onChangeTableHeading = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const headingToAdd = initialTableHeadings.find(
      (heading) => heading.id === value,
    )
    if (
      headingToAdd &&
      !tableHeadings.some((heading) => heading.id === value)
    ) {
      const newHeadings = [...tableHeadings, headingToAdd]
      setTableHeadings(newHeadings)
    }
  }

  const renderHeadingsDropdown = () => {
    const tableIds = tableHeadings.map((heading) => heading.id)
    const notSelected = initialTableHeadings.filter(
      (heading) => !tableIds.includes(heading.id),
    )
    if (notSelected.length === 0) {
      return <></>
    }

    return (
      <div className="ml-6">
        <Dropdown
          label=""
          width="200px"
          marginBottom="0"
          value=""
          onChange={onChangeTableHeading}
        >
          <option value="" disabled hidden>
            - Add Column -
          </option>
          <option />
          {notSelected.map((value) => (
            <option key={value.id} value={value.id}>
              {value.label}
            </option>
          ))}
        </Dropdown>
        <YSpacing height="15px" />
      </div>
    )
  }

  const renderFilters = () => {
    if (isCollapsed) {
      return <></>
    }

    const sortedContacts = (account.contacts || []).sort((a, b) => {
      return (
        (a.firstName || '').localeCompare(b.firstName || '') ||
        (a.lastName || '').localeCompare(b.lastName || '')
      )
    })

    return (
      <div
        className="flex flex-col border-r-2 bg-white px-4 shadow-md rounded-lg"
        style={{
          maxHeight: '675px',
          minHeight: '600px',
          minWidth: '300px',
          maxWidth: '350px',
        }}
      >
        <div className="flex items-center justify-between pt-5">
          <h1 className="font-bold underline text-blue-500 text-2xl">
            Filters
          </h1>
          {!isCollapsed && (
            <button
              onClick={() => setShowFilter(!showFilter)}
              className="flex items-center text-blue-500"
            >
              <FaFilter />
              {showFilter ? 'Hide Filters' : 'Show Filters'}
            </button>
          )}
        </div>
        <YSpacing height="20px" />
        <div className="flex justify-between">
          <TextInput
            placeholder={`Search ${orderable} Number`}
            value={filters.search}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateFilters({ search: e.target.value, page: 1 })
            }
            width="100%"
          />
        </div>
        <YSpacing height="20px" />
        <div>
          <DateInput
            date={filters.fromDate}
            label="From"
            onChange={(value: Moment) =>
              updateFilters({ fromDate: value, page: 1 })
            }
            clearDate={() => updateFilters({ fromDate: undefined, page: 1 })}
          />
        </div>
        <YSpacing height="5px" />
        <div>
          <DateInput
            date={filters.toDate}
            label="To"
            onChange={(value: Moment) =>
              updateFilters({ toDate: value, page: 1 })
            }
            clearDate={() => updateFilters({ toDate: undefined, page: 1 })}
          />
        </div>
        <YSpacing height="10px" />
        <Dropdown
          label="Contact"
          value={filters.contactId}
          onChange={(e) =>
            updateFilters({ contactId: e.target.value, page: 1 })
          }
        >
          <option />
          {sortedContacts.map((c) => (
            <option key={c.id} value={c.id}>
              {c.name}
            </option>
          ))}
        </Dropdown>
        <YSpacing height="10px" />
        <Dropdown
          label="Dropoff Address"
          value={filters.dropoffAddressId}
          onChange={(e) =>
            updateFilters({ dropoffAddressId: e.target.value, page: 1 })
          }
        >
          <option />
          {account.addresses.map((a) => (
            <option key={a.id} value={a.id}>
              {a.line1 + ' ' + a.city + ' ' + a.state + ' ' + a.zip}
            </option>
          ))}
        </Dropdown>
        <YSpacing height="10px" />
        <Dropdown
          label="Diner Profile"
          value={filters.dinerProfileId}
          onChange={(e) =>
            updateFilters({ dinerProfileId: e.target.value, page: 1 })
          }
        >
          <option />
          {Object.keys(dinerProfileMap || {}).map((dpId) => (
            <option key={dpId} value={dpId}>
              {dinerProfileMap[dpId]}
            </option>
          ))}
        </Dropdown>
        <YSpacing height="10px" />
        <Dropdown
          label="Reporting Program"
          value={filters.clientReportingProgramId}
          onChange={(e) =>
            updateFilters({ clientReportingProgramId: e.target.value, page: 1 })
          }
        >
          <option />
          {clientReportingPrograms.map((p) => (
            <option key={p.id} value={p.id}>
              {p.name}
            </option>
          ))}
        </Dropdown>
        <YSpacing height="10px" />
        <AutocompleteInput
          label="Chef"
          value={filters.chefName ? filters.chefName : ''}
          loaderFunction={(search) => loadChefs({ ...search })}
          onSelect={(value) =>
            updateFilters({ chefId: value.id, chefName: value.name })
          }
          onClear={
            filters.chefId
              ? () => updateFilters({ chefId: undefined, chefName: undefined })
              : undefined
          }
        />
        {orderableType === 'sales' && (
          <>
            <YSpacing height="10px" />
            <Checkbox
              label={`${
                filters.status === NOT_CANCELED ? 'Show' : 'Hide'
              } Canceled Orders`}
              value={filters.status !== NOT_CANCELED}
              onChange={() =>
                updateFilters({
                  status: filters.status !== NOT_CANCELED ? NOT_CANCELED : '',
                })
              }
            />
          </>
        )}
      </div>
    )
  }
  const { resultsPerPage, page } = filters
  let shown = `${(page - 1) * resultsPerPage + 1} - ${
    (page - 1) * resultsPerPage + ((orderables && orderables.length) || 0)
  }`
  if (orderables && orderables.length === 0) {
    shown = '0'
  }
  const csvUrl = buildGetRequest(filters)

  return (
    <div className="relative">
      <div className="flex flex-start" style={{ backgroundColor: '#eff1f6' }}>
        {showLinkDinerProfileModal && orderable === 'Order' && (
          <LinkDinerProfileToPastOrdersModal
            dinerProfileMap={dinerProfileMap}
            clientReportingProgramMap={reportingProgramMap}
            hideModal={() => setShowLinkDinerProfileModal(false)}
          />
        )}
        {showFilter && renderFilters()}
        <XSpacing width="25px" />
        <div className="bg-white rounded-lg w-full">
          <div className="flex justify-between pl-6">
            <div className="flex w-1/3">
              <h1 className="text-xl font-bold">{orderable} History</h1>
              <XSpacing width="20px" />
              {isCollapsed ? (
                <button
                  onClick={() => setIsCollapsed(false)}
                  className="flex items-center"
                >
                  <strong>Show</strong>
                  <FaArrowCircleDown className="ml-2" />
                </button>
              ) : (
                <button
                  onClick={() => setIsCollapsed(true)}
                  className="flex items-center"
                >
                  <strong>Hide</strong>
                  <FaArrowCircleUp className="ml-2" />
                </button>
              )}
              <XSpacing width="20px" />
              {!isCollapsed && !showFilter && (
                <button
                  onClick={() => setShowFilter(!showFilter)}
                  className="flex items-center text-blue-500"
                >
                  <FaFilter />
                  {showFilter ? 'Hide Filters' : 'Show Filters'}
                </button>
              )}
            </div>
            {orderables && !isCollapsed && (
              <strong className="w-1/3 text-red-violet text-xl font-bold">
                Total: ${orderableTotal.toFixed(2)}
              </strong>
            )}
            {Object.keys(dinerProfileMap).length > 0 &&
            !isCollapsed &&
            orderable === 'Order' ? (
              <div className="w-1/3 flex flex-col items-center justify-center">
                <button
                  className="text-black font-bold underline cursor-pointer"
                  onClick={() => setShowLinkDinerProfileModal(true)}
                >
                  Add Past {orderable}s to Diner Profile and/or Reporting
                  Program
                </button>
              </div>
            ) : (
              <div className="w-1/3"></div>
            )}
          </div>
          <YSpacing height="15px" />
          <div>
            <div className="flex justify-between">
              {orderables && !isCollapsed && (
                <div className="flex w-100 flex-align-center justify-between pl-6">
                  <div className="flex w-1/3">
                    <div className="flex">
                      {orderables && (
                        <button
                          className="page-arrow"
                          style={{ paddingTop: '5px' }}
                          onClick={() =>
                            updateFilters({ page: filters.page - 1 })
                          }
                          disabled={filters.page <= 1}
                        >
                          ◄
                        </button>
                      )}
                      <XSpacing width="5px" />
                      <p className="nowrap page-number">Page {filters.page}</p>
                      <XSpacing width="5px" />
                      {orderables &&
                        orderables.length > 0 &&
                        orderables.length === filters.resultsPerPage && (
                          <button
                            className="page-arrow"
                            onClick={() =>
                              updateFilters({ page: filters.page + 1 })
                            }
                            style={{ paddingTop: '5px' }}
                          >
                            ►
                          </button>
                        )}
                    </div>
                    <XSpacing width="10px" />
                    <div className="flex flex-align-center ">
                      <p>Show </p>
                      <select
                        className="page-select"
                        value={filters.resultsPerPage}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          updateFilters({
                            resultsPerPage: parseInt(e.target.value || '20'),
                            page: 1,
                          })
                        }
                      >
                        {[20, 50, 100].map((o) => (
                          <option key={o} value={o}>
                            {o}
                          </option>
                        ))}
                      </select>
                      <p>Per Page</p>
                    </div>
                  </div>
                  {isLoading ? (
                    <p className="w-1/3">Loading...</p>
                  ) : (
                    <p className="w-1/3">
                      Showing {orderables ? orderables.length : 0} {orderable}s{' '}
                      {shown} out of {count == undefined ? '...' : count}
                    </p>
                  )}
                  <div className="w-1/3 flex flex-col items-center justify-center">
                    {orderables && orderables.length > 0 && !isLoading && (
                      <a
                        className="button-primary"
                        style={{ minWidth: '80px' }}
                        href={`${
                          config.api_host
                        }/api/admin/${orderable.toLowerCase()}s/?csv=true${csvUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Export To CSV
                      </a>
                    )}
                  </div>
                </div>
              )}
            </div>
            {!isCollapsed && (
              <>
                {renderHeadingsDropdown()}
                <table className="table page bg-white mx-5 w-auto">
                  <thead>
                    <DraggableTableHeader
                      headings={tableHeadings}
                      updateHeadings={(headings: TableHeading[]) =>
                        setTableHeadings(headings)
                      }
                    />
                  </thead>
                  <tbody>
                    {(orderables == undefined || isLoading) && (
                      <tr>
                        <Loader />
                      </tr>
                    )}
                    {orderables && orderables.length === 0 && !isLoading && (
                      <tr>
                        <td colSpan={Colspan}>
                          <div className="no-promocodes">No results</div>
                        </td>
                      </tr>
                    )}
                    {orderables &&
                      orderables.map((orderable, i) => (
                        <tr key={i}>
                          {tableHeadings.map((h, index) => (
                            <td key={index}>{renderDataCell(orderable, h)}</td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderableHistory
