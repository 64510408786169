import { connect } from 'react-redux'

import { TotalSection } from '@components/order/edit/sections'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'

const mapStateToProps = (state) => {
  const errors = state.errors.editOrderModal || {}
  const { mode } = state.editOrderModal
  const orderable = mode === 'edit' ? state.editOrder : state.newOrder
  const { lastLoggedInHq, permissions } = state.user || {}
  const { headquarters } = state.headquarter || {}
  const headquarterId = orderable.headquarterId || lastLoggedInHq
  const userPermissions = permissions[headquarterId]
  const orderHeadquarter = headquarters.find((hq) => hq.id === headquarterId)

  return {
    errors,
    orderHeadquarter,
    userPermissions,
  }
}

const mapDispatchToProps = () => {
  const {
    OrderService,
    RestService,
    RouterService,
    SessionService,
    SettingsService,
    UIService,
  } = services
  const { displayFailureMessage } = UIService.FlashMessage
  const {
    AsyncLoadTaxRates,
    RetryOnTimeout,
    HandleError: HandleError_,
  } = coordinators
  const { pResponseGeneric } = presenters.Api
  const { pResponseOrderGetTaxRate } = presenters.Errors

  const {
    calculateChefAmount,
    calculateDeliveryFee,
    calculateDiscounts,
    calculateGrossMargin,
    calculatePreTaxTotal,
    calculateServiceFee,
    calculateStaffingFee,
    calculateSubtotal,
    calculateTax,
    calculateTip,
    calculateTotal,
    calcOrderSettings,
  } = OrderService
  const HandleError = HandleError_({
    RouterService,
    SessionService,
    UIService,
    pResponseError: pResponseOrderGetTaxRate,
  })
  const getTaxRates = AsyncLoadTaxRates({
    RestService,
    SettingsService,
    pResponseGeneric,
    RetryOnTimeout,
    HandleError,
  })

  return {
    calculateChefAmount,
    calculateDeliveryFee,
    calculateDiscounts,
    calculateGrossMargin,
    calculatePreTaxTotal,
    calculateServiceFee,
    calculateStaffingFee,
    calculateSubtotal,
    calculateTax,
    calculateTip,
    calculateTotal,
    calcOrderSettings,
    displayFailureMessage,
    getTaxRates,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TotalSection)
