import { useReducer, useEffect, useCallback } from 'react'
import Moment from 'moment-timezone'

export enum UnpaidOrderStatus {
  NeedsBatch = 'Needs Batching',
  UnpaidBatch = 'Unpaid Batch',
  OverInvoiced = 'Over Batched',
}

export const AllUnpaidOrderStatuses = [
  UnpaidOrderStatus.NeedsBatch,
  UnpaidOrderStatus.UnpaidBatch,
  UnpaidOrderStatus.OverInvoiced,
]

export interface Order {
  id: string
  date: string
  effectiveDate: string
  daysPastDue: number
  dueDate: string
  orderNumber: string
  orderType: string
  totalDue: number
  totalPaid: number
  totalUnpaid: number
  totalUnpaidBatched: number
  batchInvoices: string[]
  status: UnpaidOrderStatus
  'dgraph.type': string[]
  orderPayments?: OrderPayment[]
  numBatchInvoices: number
}

export interface OrderPayment {
  id: string
  amount: number
  isPaid: boolean
  paymentInvoice?: {
    invoiceNumber: string
    isAutoInv: boolean
  }
}

export interface GetUnpaidOrdersParams {
  fromDate?: string
  toDate?: string
  name?: string
  selectedHeadquarters?: string[]
  clientId?: string
  orderIds?: string[]
  offset: number
  limit: number
}

export interface UseUnpaidOrdersProps {
  getUnpaidOrders: (params: GetUnpaidOrdersParams) => Promise<Order[]>
  clientId?: string
  ordersPerPage: number
}

export interface UseUnpaidOrdersResult {
  pageOrders: Order[]
  search: string
  searchFrom: Moment.Moment | undefined
  searchTo: Moment.Moment | undefined
  statuses: UnpaidOrderStatus[]
  sortBy: [string, boolean]
  pageNum: number
  totalFilteredOrders: number
  isLoading: boolean
  setSearch: (search: string) => void
  setSearchFrom: (date: Moment.Moment | undefined) => void
  setSearchTo: (date: Moment.Moment | undefined) => void
  setStatuses: (statuses: UnpaidOrderStatus[]) => void
  setSortBy: (sortBy: [string, boolean]) => void
  executeFilterOrders: () => void
  resetSearch: () => void
  sortOrders: (attribute: keyof Order, transform?: (a: any) => any) => void
  sortByIcon: (attribute: string) => string
  setPageNum: (pageNum: number) => void
  loadUnpaidOrders: (orderIds?: string[]) => void
}

// Define state interface
interface UnpaidOrdersState {
  orders: Order[]
  filteredOrders: Order[]
  pageOrders: Order[]
  sortBy: [string, boolean]
  pageNum: number
  isLoading: boolean
  statuses: UnpaidOrderStatus[]
  search: string
  searchFrom: Moment.Moment | undefined
  searchTo: Moment.Moment | undefined
}

// Define action types
type UnpaidOrdersAction =
  | { type: 'SET_ORDERS'; payload: Order[] }
  | { type: 'REPLACE_ORDERS'; payload: { orders: Order[]; orderIds: string[] } }
  | { type: 'SET_FILTERED_ORDERS'; payload: Order[] }
  | { type: 'SET_PAGE_ORDERS'; payload: Order[] }
  | { type: 'SET_SORT_BY'; payload: [string, boolean] }
  | { type: 'SET_PAGE_NUM'; payload: number }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_STATUSES'; payload: UnpaidOrderStatus[] }
  | { type: 'SET_SEARCH'; payload: string }
  | { type: 'SET_SEARCH_FROM'; payload: Moment.Moment | undefined }
  | { type: 'SET_SEARCH_TO'; payload: Moment.Moment | undefined }
  | { type: 'RESET_SEARCH' }
  | { type: 'SORT_ORDERS'; payload: { orders: Order[] } }

const limit = 50

// Initial state
const initialState: UnpaidOrdersState = {
  orders: [],
  filteredOrders: [],
  pageOrders: [],
  sortBy: ['date', false],
  pageNum: 1,
  isLoading: false,
  statuses: AllUnpaidOrderStatuses,
  search: '',
  searchFrom: undefined,
  searchTo: Moment().startOf('day'),
}

// Reducer function
const unpaidOrdersReducer = (
  state: UnpaidOrdersState,
  action: UnpaidOrdersAction,
): UnpaidOrdersState => {
  switch (action.type) {
    case 'SET_ORDERS':
      return { ...state, orders: action.payload }
    case 'REPLACE_ORDERS': {
      // remove existing orders that are being replaced or have been removed
      const prevOrders = state.orders.filter(
        (order) => !action.payload.orderIds.includes(order.id),
      )
      const nextOrders = [...prevOrders, ...action.payload.orders].sort(
        (a, b) => a.date.localeCompare(b.date),
      )

      return { ...state, orders: nextOrders }
    }
    case 'SET_FILTERED_ORDERS':
      return { ...state, filteredOrders: action.payload }
    case 'SET_PAGE_ORDERS':
      return { ...state, pageOrders: action.payload }
    case 'SET_SORT_BY':
      return { ...state, sortBy: action.payload }
    case 'SET_PAGE_NUM':
      return { ...state, pageNum: action.payload }
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload }
    case 'SET_STATUSES':
      return { ...state, statuses: action.payload }
    case 'SET_SEARCH':
      return { ...state, search: action.payload }
    case 'SET_SEARCH_FROM':
      return { ...state, searchFrom: action.payload }
    case 'SET_SEARCH_TO':
      return { ...state, searchTo: action.payload }
    case 'RESET_SEARCH':
      return {
        ...state,
        orders: [],
        filteredOrders: [],
        pageOrders: [],
        search: '',
        searchFrom: undefined,
        searchTo: Moment().startOf('day'),
        sortBy: ['date', false],
        pageNum: 1,
      }
    case 'SORT_ORDERS':
      return { ...state, orders: action.payload.orders }
    default:
      return state
  }
}

export const useUnpaidOrders = ({
  getUnpaidOrders,
  clientId,
  ordersPerPage,
}: UseUnpaidOrdersProps): UseUnpaidOrdersResult => {
  const [state, dispatch] = useReducer(unpaidOrdersReducer, initialState)

  // Filters
  const filterOrders = useCallback(() => {
    const nextFilteredOrders = state.orders.filter((order) => {
      if (state.searchFrom && Moment(order.date).isBefore(state.searchFrom)) {
        return false
      }

      if (state.searchTo && Moment(order.date).isAfter(state.searchTo)) {
        return false
      }

      if (!state.statuses.includes(order.status)) {
        return false
      }

      if (state.search) {
        return order.orderNumber
          .toLowerCase()
          .includes(state.search.toLowerCase())
      }

      return true
    })

    dispatch({ type: 'SET_FILTERED_ORDERS', payload: nextFilteredOrders })

    const nextPageOrders = nextFilteredOrders.slice(
      (state.pageNum - 1) * ordersPerPage,
      state.pageNum * ordersPerPage,
    )

    dispatch({ type: 'SET_PAGE_ORDERS', payload: nextPageOrders })
  }, [
    state.search,
    state.searchFrom,
    state.searchTo,
    state.pageNum,
    ordersPerPage,
    state.orders,
    state.statuses,
  ])

  // loads all unpaid orders for a client
  const loadUnpaidOrders = useCallback(
    async (orderIds?: string[]) => {
      dispatch({ type: 'SET_LOADING', payload: true })

      const params: GetUnpaidOrdersParams = {
        toDate: Moment().startOf('day').format(),
        clientId,
        offset: 0,
        limit,
      }

      if (orderIds) {
        params.orderIds = orderIds
      }

      let nextOrders = [] as Order[]
      let fin = false

      while (!fin) {
        const ordersResp = await getUnpaidOrders(params)
        nextOrders.push(...ordersResp)
        params.offset = (params.offset || 0) + limit

        if (ordersResp.length !== limit) {
          fin = true
        }
      }

      // if we are fetching specific orders replace the existing orders with the new ones
      if (orderIds) {
        dispatch({
          type: 'REPLACE_ORDERS',
          payload: { orders: nextOrders, orderIds },
        })
      } else {
        nextOrders = nextOrders.sort((a, b) => a.date.localeCompare(b.date))
        dispatch({ type: 'SET_ORDERS', payload: nextOrders })
      }

      dispatch({ type: 'SET_LOADING', payload: false })
    },
    [getUnpaidOrders, clientId],
  )

  const resetSearch = useCallback(() => {
    dispatch({ type: 'RESET_SEARCH' })
  }, [])

  const sortOrders = useCallback(
    (attribute: keyof Order, transform = (a: any) => a) => {
      const asc = attribute !== state.sortBy[0] || !state.sortBy[1]
      dispatch({ type: 'SET_SORT_BY', payload: [attribute, asc] })

      const sortedOrders = [...state.orders].sort((a, b) => {
        if (transform(a[attribute]) > transform(b[attribute])) {
          return asc ? 1 : -1
        }
        if (transform(a[attribute]) < transform(b[attribute])) {
          return asc ? -1 : 1
        }

        return 0
      })

      dispatch({
        type: 'SORT_ORDERS',
        payload: { orders: sortedOrders },
      })
    },
    [state.sortBy, state.orders],
  )

  const sortByIcon = useCallback(
    (attribute: string) => {
      const [sortAttribute, isAsc] = state.sortBy
      if (sortAttribute === attribute) {
        return isAsc ? '▲' : '▼'
      }

      return ''
    },
    [state.sortBy],
  )

  // Action dispatchers (simplified setters)
  const setSearch = useCallback((search: string) => {
    dispatch({ type: 'SET_SEARCH', payload: search })
  }, [])

  const setSearchFrom = useCallback((date: Moment.Moment | undefined) => {
    dispatch({ type: 'SET_SEARCH_FROM', payload: date })
  }, [])

  const setSearchTo = useCallback((date: Moment.Moment | undefined) => {
    dispatch({ type: 'SET_SEARCH_TO', payload: date })
  }, [])

  const setStatuses = useCallback((statuses: UnpaidOrderStatus[]) => {
    dispatch({ type: 'SET_STATUSES', payload: statuses })
  }, [])

  const setSortBy = useCallback((sortBy: [string, boolean]) => {
    dispatch({ type: 'SET_SORT_BY', payload: sortBy })
  }, [])

  const setPageNum = useCallback((pageNum: number) => {
    dispatch({ type: 'SET_PAGE_NUM', payload: pageNum })
  }, [])

  // Effects
  useEffect(() => {
    if (clientId) {
      loadUnpaidOrders()
    }
  }, [clientId, loadUnpaidOrders])

  // Runs whenever a filter param changes due to filterOrders being updated from useCallback deps
  useEffect(() => {
    if (clientId) {
      filterOrders()
    }
  }, [clientId, filterOrders])

  return {
    pageOrders: state.pageOrders,
    search: state.search,
    searchFrom: state.searchFrom,
    searchTo: state.searchTo,
    statuses: state.statuses,
    sortBy: state.sortBy,
    pageNum: state.pageNum,
    totalFilteredOrders: state.filteredOrders.length,
    isLoading: state.isLoading,
    setSearch,
    setSearchFrom,
    setSearchTo,
    setStatuses,
    setSortBy,
    resetSearch,
    sortOrders,
    sortByIcon,
    executeFilterOrders: filterOrders,
    setPageNum,
    loadUnpaidOrders,
  }
}
